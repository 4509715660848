import PropTypes from "prop-types";

import Wrapper from "../helpers/Wrapper";

function Badge({ hoverDetails }) {
  return (
    <Wrapper>
      <div className="badge">i</div>
      <div className="badge_hover">
        {/* MAX LENGTH 100 */}
        {hoverDetails}
      </div>
    </Wrapper>
  );
}

Badge.propTypes = {
  hoverDetails: PropTypes.string,
};

export default Badge;
