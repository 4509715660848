import Wrapper from "../helpers/Wrapper"

const InsightsTitle = ({ title }) => {
    return (
        <Wrapper>
            <div className="titleWrapper">
                <h2>
                    {title}
                </h2>
            </div>
        </Wrapper>
    )
}
export default InsightsTitle;