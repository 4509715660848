const IconFilter = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <g id="Group_110" data-name="Group 110" transform="translate(-880.539 -466.585)">
                <path id="Path_123" data-name="Path 123" d="M485.117,92.086H453.865l12.5,14.782v10.22l6.25,3.125V106.868Z" transform="translate(427.424 375.249)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>
    )
}
export default IconFilter;
