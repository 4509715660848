import React, { Component } from 'react';
import Col from "react-bootstrap/esm/Col";
import '../card.scss';

export class StoredDtailsCard extends Component {
    render() {
        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-ristorecard">
                    <div className="icz-ristorecardheader">
                        <div className="icz-ristorenumberwrapper">
                            <div className="icz-ristorenumber">{this.props.CardIcon}</div>
                        </div>
                        <div className="icz-ristoretitlewrapper">
                            <h3 className="icz-ristoretitle">{this.props.CardTitle}</h3>
                        </div>
                    </div>
                    <div className="icz-ristorecardbody">
                   <div className="icz-ristorerowtitle">{this.props.CardRowTitle}</div>
                   <div className="icz-ristorerowsubtitle">{this.props.CardRowSubTitle}</div>
                   <div>
                            <Col lg={12} className="icz-ristorescoredetails">
                                <Col lg={6} className="icz-ristorescoredata">
                                    <h4 className="icz-ristorescorecurrent">{this.props.CardScoreCurrent}</h4>
                                    <p className="icz-ristorescoretext">{this.props.CardScoreText}</p>
                                </Col>
                                <Col lg={6} className="icz-ristorescoredata">
                                <h4 className="icz-ristorescoreprevious">{this.props.CardScorePrevious}</h4>
                                    <p className="icz-ristorescoretext">{this.props.CardScoreText2}</p>
                                </Col>
                            </Col>
                   </div>
                    </div>
                </div>
            </div>
        );
    }
}
