import React, { Component } from 'react';
import Col from "react-bootstrap/esm/Col";

export default class GaugechartLegends extends Component {
    render() {
        return (
            <Col lg={12} className="icz-ridatabullet">
                <ul className="icz-ridatalist-inline">
                  <li><div className="icz-ridatalist-smbullet1" /> Format</li>
                  <li><div className="icz-ridatalist-smbullet2" /> Zone</li>
                  <li><div className="icz-ridatalist-smbullet3" /> City</li>
                  <li><div className="icz-ridatalist-smbullet4" /> Store</li>
                </ul>
              </Col>
        );
    }
}