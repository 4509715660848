const IconGoogle = (props) => {
  return (
    <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.8 27.378C42.8 36.541 36.525 43.061 27.259 43.061C22.9998 43.061 18.9152 41.3691 15.9035 38.3574C12.8919 35.3458 11.2 31.2611 11.2 27.002C11.2 22.7429 12.8919 18.6582 15.9035 15.6466C18.9152 12.6349 22.9998 10.943 27.259 10.943C31.257 10.8961 35.1173 12.4017 38.028 15.143L33.657 19.343C27.939 13.826 17.307 17.97 17.307 26.997C17.2851 29.6607 18.3207 32.2244 20.1866 34.1255C22.0525 36.0266 24.5963 37.11 27.26 37.138C33.619 37.138 36.002 32.579 36.377 30.216H27.259V24.694H42.548C42.7176 25.5786 42.802 26.4773 42.8 27.378V27.378Z" stroke="#486F88" strokeWidth="1.5"/>
    </svg>
  )
}

export default IconGoogle;

