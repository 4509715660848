import React, { Component } from 'react';
import Col from "react-bootstrap/esm/Col";
import '../card.scss';

export class SalesDetailsCard extends Component {
    render() {
        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-risalescard">
                    <div className="icz-risalescardheader">
                        <div className="icz-risalescardtitlewrapper">
                            <h3 className="icz-risalescardtitle">{this.props.CardTitle}</h3>
                        </div>
                    </div>
                    <div className="icz-risalescardbody">
                    <Col lg={12} className="icz-risalesdata">
                           <div className="icz-risalesdatadetails"><h3 className="icz-risaleshighlight">{this.props.CardHighlightText}</h3> 
                            <span className="icz-risalessmalltext">{this.props.CardSmallText}</span></div>
                           <p className="icz-risalestext">{this.props.CardSubTitle} </p>
                       </Col>
                       <Col lg={12} className="icz-risalesdata">
                       <div className="icz-risalesdatadetails"><h3 className="icz-risaleshighlight">{this.props.CardHighlightText2}</h3> 
                           <span className="icz-risalessmalltext">{this.props.CardSmallText2}</span></div>
                           <p className="icz-risalestext">{this.props.CardSubTitle2} </p>
                       </Col>
                       <Col lg={12} className="icz-risalesdata">
                       <div className="icz-risalesdatadetails"><h3 className="icz-risaleshighlight">{this.props.CardHighlightText3}</h3> 
                            <span className="icz-risalessmalltext">{this.props.CardSmallText3}</span></div>
                           <p className="icz-risalestext">{this.props.CardSubTitle3} </p>
                       </Col>
                    </div>
                </div>
            </div>
        );
    }
}
