import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { HeatMapBlueColorSet } from "../../../constants/constants";

am5.addLicense("AM5C258896422");

const HeatMap = ({
  chartId,
  chartClass,
  chartXaxisLabels,
  chartYaxisLabels,
  chartData,
  varient,
  chartColorSet
}) => {

  var circleTemplate;

  useLayoutEffect(() => {

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    let colorSet;

    if (chartColorSet === undefined) {
      colorSet = HeatMapBlueColorSet
    } else {
      colorSet = chartColorSet
    }

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      // wheelX: "none",
      // wheelY: "none",
      layout: root.verticalLayout,
      paddingRight: 0,
      paddingTop: 5,
      paddingBottom: 0,
      paddingLeft: 0,
      maxTooltipDistance: -1,
    }));


    // Create axes and their renderers
    var yRenderer = am5xy.AxisRendererY.new(root, {
      visible: false,
      minGridDistance: 20,
      inversed: true
    });

    yRenderer.grid.template.set("visible", false);

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      renderer: yRenderer,
      categoryField: "yaxis_label"
    }));

    yAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400",
    });

    var xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 30,
      opposite: true
    });

    xRenderer.grid.template.set("visible", false);

    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      renderer: xRenderer,
      categoryField: "xaxis_label"
    }));

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400",
    });


    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
      calculateAggregates: true,
      stroke: am5.color(0xffffff),
      clustered: false,
      xAxis: xAxis,
      yAxis: yAxis,
      categoryXField: "xaxis_label",
      categoryYField: "yaxis_label",
      valueField: "value"
    }));

    {
      varient === "Bubble" ?
        series.columns.template.setAll({
          forceHidden: true
        })
        :
        series.columns.template.setAll({
          tooltipText: "[bold]{yaxis_label}[/] | [bold]{xaxis_label}[/]\n[bold]{value}[/]",
          strokeOpacity: 1,
          strokeWidth: 2,
          width: am5.percent(100),
          height: am5.percent(100)
        })
    }

    series.columns.template.events.on("pointerover", function (event) {
      var di = event.target.dataItem;
      if (di) {
        heatLegend.showValue(di.get("value", 0));
      }
    });

    { varient === "Bubble" ? circleTemplate = am5.Template.new({ radius: 5 }) : circleTemplate = '' }

    series.events.on("datavalidated", function () {
      heatLegend.set("startValue", series.getPrivate("valueHigh"));
      heatLegend.set("endValue", series.getPrivate("valueLow"));
    });

    {
      varient === "Bubble" &&
        // Add circle bullet
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
        series.bullets.push(function () {
          var graphics = am5.Circle.new(
            root, {
            stroke: am5.color(colorSet[1]),
            fill: am5.color(colorSet[1])
          }, circleTemplate
          );
          return am5.Bullet.new(root, {
            sprite: graphics
          });
        });
    }

    var tooltip = series.set("tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      maxWidth: 200,
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    tooltip.label.setAll({
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    {
      varient === "Bubble" ?
        // Set up heat rules
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series.set("heatRules", [{
          target: circleTemplate,
          min: 5,
          max: 25,
          dataField: "value",
          key: "radius"
        }])
        :

        // Set up heat rules
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series.set("heatRules", [{
          target: series.columns.template,
          min: am5.color(colorSet[0]),
          max: am5.color(colorSet[1]),
          dataField: "value",
          key: "fill"
        }])
    }

    // Add heat legend
    // https://www.amcharts.com/docs/v5/concepts/legend/heat-legend/
    var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
      forceHidden: varient === "Bubble" ? true : false,
      orientation: "horizontal",
      endColor: am5.color(colorSet[0]),
      startColor: am5.color(colorSet[1]),
    }));

    heatLegend.startLabel.setAll({
      fontSize: 12,
      fill: "#213345",
    });

    heatLegend.endLabel.setAll({
      fontSize: 12,
      fill: "#213345",
    });



    // Set data
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Setting_data
    var data = chartData

    series.data.setAll(data);

    yAxis.data.setAll(chartYaxisLabels);

    xAxis.data.setAll(chartXaxisLabels);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData, chartXaxisLabels, chartYaxisLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

HeatMap.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.array,
  chartXaxisLabels: PropTypes.array,
  chartYaxisLabels: PropTypes.array,
  varient: PropTypes.string,
  chartColorSet: PropTypes.array
};

export default HeatMap;
