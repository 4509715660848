const IconTwitter = (props) => {
  return (
    <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486f88" xmlns="http://www.w3.org/2000/svg">
      <g id="Group_522" data-name="Group 522" transform="translate(-501 -606)">

        <path id="Icon_feather-twitter" data-name="Icon feather-twitter" d="M32.56,4.5a15.389,15.389,0,0,1-4.433,2.16,6.325,6.325,0,0,0-11.1,4.235v1.412a15.05,15.05,0,0,1-12.706-6.4s-5.647,12.706,7.059,18.354A16.434,16.434,0,0,1,1.5,27.088c12.706,7.059,28.236,0,28.236-16.236a6.353,6.353,0,0,0-.113-1.172A10.9,10.9,0,0,0,32.56,4.5Z" transform="translate(510.97 615.849)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  )
}

export default IconTwitter;