import React, { Component } from 'react'
import './ricouponcard.scss'
import { NumberFormatter } from "../../../utilities/numberformatter";


export class RiCouponCard extends Component {
    render() {
        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-ricouponcard">
                <div className="icz-ricouponcardheader">
                        <div className="icz-titlewrapper">
                            <h3 className="icz-rititle"> 
                            <NumberFormatter Number={this.props.RowOneCurrentValue} />
                            </h3>
                            <p className="icz-risubtitle">{this.props.CardSubTitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
