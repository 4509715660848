import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Message from "./message";
import { IcogzBrandLogo } from "../../constants/constants";
import useApi from "../../hooks/useApi";
import NewChat from "../../assets/icons/NewChatIcon";
import SkeletonLoader from "./skeletonLoader";
import Wrapper from "../helpers/Wrapper";
import Alert from "../ui/alerts/alerts";
import { useSelector } from "react-redux";
import "./chat.scss"
import AnimatedInput from "../input/animateInput";
import Button from "../ui/Button";

const Chat = ({ state }) => {
    const ARYABOT_BASE_URL = process.env.REACT_APP_ARYABOT_API_URL;

    const location = useLocation();
    const navigate = useNavigate();

    const selectedDates = useSelector((state) => state.date);

    // const messageEndRef = useRef(null); // TO SCROLL TO THE NEW MESSAGE ON EVERY NEW MESSAGE

    // API CALLS
    const suggestionsAPI = useApi();
    const chatAPI = useApi();

    const [input, setInput] = useState(null)
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        // SHOW THE DATA COMING FROM THE TOP PERFORMING INSIGHTS DIRECTLY WITHOUT CALLING THE API
        if (state !== undefined && state !== null) {
            if (state.data !== undefined && state.data !== null) {
                setMessages([
                    ...messages,
                    { id: Math.random().toString(), message: state.query, type: "user" },
                    { id: Math.random().toString(), message: [state.data], type: "bot" }
                ]);
            } else {
                if (state.query !== undefined && state.query !== null) {
                    handleSubmit(state.query)
                }
            }
        }
    }, [])

    // UPDATE THE MESSAGE WHEN USER CLICK ENTER IN THE CHAT INPUT TO SUBMIT THE INPUT 
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(input)
        }
    };

    const handleSubmit = (inputQuery) => {
        clearState() // clear the state when user type in the input
        if (inputQuery.trim()) {
            setMessages([
                ...messages,
                { id: Math.random().toString(), message: inputQuery, type: "user" },
            ]);
            setInput("");

            let skeletonId;
            // Add skeleton loaders
            skeletonId = Math.random().toString();
            setMessages((prevMessages) => [
                ...prevMessages,
                { id: skeletonId, message: <SkeletonLoader />, type: "skeleton" },
            ]);

            getChatAPIData(inputQuery);
        }
    }

    const getChatAPIData = (inputQuery) => {
        let apiData = {
            start_date: selectedDates.startDate,
            end_date: selectedDates.endDate,
            previous_start_date: selectedDates.prevStartDate,
            previous_end_date: selectedDates.prevEndDate,
            question: inputQuery
        }

        chatAPI.fetchData(ARYABOT_BASE_URL, "aryabot/get-insight/", apiData);
    }

    useEffect(() => {
        if (chatAPI.apiStatus.isLoaded && !chatAPI.apiStatus.isLoading) {
            if (chatAPI.apiStatus.error !== null) {
                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.type === "skeleton"
                            ? { id: msg.id, message: chatAPI.apiStatus.error, type: "bot_error" }
                            : msg
                    )
                );
            } else {
                if (chatAPI.data) {
                    setMessages((prevMessages) =>
                        prevMessages.map((msg) =>
                            msg.type === "skeleton"
                                ? { id: msg.id, message: chatAPI.data.data, type: "bot" }
                                : msg
                        )
                    );
                }
            }
        }
    }, [chatAPI.apiStatus])

    const onSuggestedQuestionClickHandler = (question) => {
        if (Object.values(question).length !== 0) {
            handleSubmit(question)
        }
    }

    // // UPDATE THE MESSAGE END REF WHEN USER SUBMIT THE NEW MESSAGE
    // useEffect(() => {
    //     messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    // }, [messages]);

    // ALERT FOR CONFIRMATION OF CLEARING CHAT
    const [showAlert, setShowAlert] = useState(false);

    const handleConfirm = () => {
        setShowAlert(false);
        setMessages([])
        clearState()
    };

    const handleCancel = () => {
        setShowAlert(false);
    };

    // CLEAR THE STATE OF THE 
    const clearState = () => {
        navigate(location.pathname, { replace: true, state: { from: state?.from, query: null, data: null } });
    };

    // ON CLICK ON THE CLOSE ICON REDIRECT IT TO THE ROUTE FROM WHERE THE USER LAND ON THIS PAGE
    const onCloseChatButtonClickHandler = () => {
        navigate(state?.from);
    }

    // SUGGESTIONS
    useEffect(() => {

        let params = {
            start_date: selectedDates.startDate,
            end_date: selectedDates.endDate,
        }

        suggestionsAPI.fetchData(ARYABOT_BASE_URL, "aryabot/question/", params);

    }, [selectedDates.startDate, selectedDates.endDate])

    const [suggestedQuestionsList, setSuggestedQuestionsList] = useState([])

    useEffect(() => {
        if (suggestionsAPI.apiStatus.isLoaded && !suggestionsAPI.apiStatus.isLoading) {
            if (suggestionsAPI.data) {
                setSuggestedQuestionsList(suggestionsAPI?.data?.data)
            }
        }
    }, [suggestionsAPI.apiStatus])

    const onSubmitButtonClickHandler = (querry) => {
        handleSubmit(querry)
    }

    return (
        <Wrapper>
            <div className="chat">
                <div className="header">
                    <div className="left">
                        <Button className="secondary_btn" handleClick={() => setShowAlert(true)}>New Chat</Button>
                    </div>
                    <div className="center"></div>
                    <div className="right">
                        <button type="button" className="close-btn" onClick={onCloseChatButtonClickHandler}>&times;</button>
                    </div>
                </div>
                <div className="body">
                    <div className="chat-messages">
                        {messages.map((msg) => (
                            <Message key={msg.id} message={msg.message} type={msg.type} onSuggestedQuestionClickHandler={onSuggestedQuestionClickHandler} />
                        ))}
                        {/* <div ref={messageEndRef} /> */}
                    </div>
                </div>
                <div className="footer">
                    <div className="input-deck">
                        {/* <div className="icon-wrapper" onClick={() => setShowAlert(true)}>
                            <NewChat className="icon" />
                        </div>
                        <form className="chat-input-form">
                            <input
                                type="text"
                                placeholder="Ask anything..."
                                onChange={(e) => setInput(e.target.value)}
                                value={input}
                                onKeyDown={handleKeyDown}
                                disabled={!chatAPI.apiStatus.isLoaded && chatAPI.apiStatus.isLoading}
                            />
                        </form> */}
                        <AnimatedInput
                            placeholders={suggestedQuestionsList}
                            typingSpeed={10}
                            delayBetweenPlaceholders={2000}
                            onClickHandler={onSubmitButtonClickHandler}
                        />
                    </div>
                </div>
            </div>
            {showAlert && (
                <Alert
                    message="Are you sure you want to clear the insights?"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                />
            )}
        </Wrapper>
    )
};

export default Chat;
