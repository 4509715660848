import React, { Component } from 'react';
import Col from "react-bootstrap/esm/Col";
import Wrapper from '../../../../helpers/wrapper';
import '../card.scss';

export class ScoreCard extends Component {
    render() {
        return (
            <Wrapper>
                <div key={this.props.index} className={this.props.CardClass}>
                    <div className="icz-riscorecard">
                        <div className="icz-riscorecardheader">
                            <div className="icz-riscoretitlewrapper">
                                <h3 className="icz-riscoretitle">{this.props.CardTitle}</h3>
                            </div>
                        </div>
                        <div className="icz-riscorecardbody">
                            <Col lg={12} className="icz-riscoredetailscontainer">
                                <Col lg={6} className="icz-riscorecarddetails">
                                    <div className="icz-riscore">{this.props.CardScoreTitle}</div>
                                    <div className="icz-risubtitle">{this.props.CardScoreSubTitle}</div>
                                    <div className="icz-riweektitle">{this.props.CardWeekTitle}</div>
                                </Col>
                                <Col lg={6} className="icz-riscorecarddetails">
                                    <div className="icz-riscore">{this.props.CardScoreTitle2}</div>
                                    <div className="icz-risubtitle">{this.props.CardScoreSubTitle2}</div>
                                    <div className="icz-riweektitle">{this.props.CardWeekTitle2}</div>
                                </Col>
                            </Col>
                            <Col lg={12}> {this.props.chart}</Col>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}
