import React from 'react';
import Wrapper from '../helpers/Wrapper';
import "./suggestedQuestions.scss"

const SuggestedQuestionsList = ({ questions, onClickHandler }) => {
    const data = questions
    
    return (
        <Wrapper>
            <div className="questions-section">
                <div className="title">
                    Related topics:
                </div>
                <div className="list-questions-wrapper">
                    {Object.entries(data).map(([key, value]) => (
                        <Wrapper key={key}>
                            <div className="question" onClick={() => onClickHandler(value)}>
                                {value}
                            </div>
                        </Wrapper>
                    ))}
                </div>
            </div>
        </Wrapper>
    );
};

export default SuggestedQuestionsList;
