import Wrapper from "../helpers/Wrapper"

const SkeletonLoader = () => {
    return (
        <Wrapper>
            <div className="skeleton-loader">
                <div className="skeleton-bar"></div>
                <div className="skeleton-bar"></div>
                <div className="skeleton-bar"></div>
                <div className="skeleton-bar"></div>
                <div className="skeleton-bar"></div>
            </div>

        </Wrapper>
    )
}
export default SkeletonLoader;

export const ErrorLoader = () => {
    return (
        <Wrapper>
            <div className="error-loader-wrapper">
                <div className="error-loader">
                    <div className="error-bar"></div>
                    <div className="error-bar"></div>
                    <div className="error-bar"></div>
                    <div className="error-bar"></div>
                    <div className="error-bar"></div>
                </div>
                <div className="error-text">
                    Something went wrong
                </div>
            </div>

        </Wrapper>
    )
}