import PropTypes from "prop-types";

import Wrapper from "../helpers/Wrapper";

import DefaultPostImg from "../../assets/images/default/postImg.png";
import Button from "./Button";
import { DEMO_UAE_USER_ID, DEMO_UK_USER_ID, DEMO_US_USER_ID, DefaultPostImage, defaultWhiteSocialIconsUrls } from "../../constants/constants";

import NumberFormatter from "../../utils/NumberFormatter";
import ErrorBoundary from "../../utils/ErrorBoundary";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
const ComparePostCard = ({
  module,
  postData,
  checkboxEventHandler,
  showImagesOnly,
  viewMoreOnclickHandler,
  viewBrandInfo,
  isMarketSummary,
  platform,
  tvHaleon
}) => {

  // USER DATA
  const userData = useSelector((state) => state.userData.user);

  let postUrl = DefaultPostImage;

  const [showGRPs, setShowGRPs] = useState(true);

  useEffect(() => {
    if (userData.country_code === "US" || userData.country_code === "UK" || userData.country_code === "SG") {
      setShowGRPs(false)
    } else {
      if (userData.email === "fmcg@icogz.com") {
        setShowGRPs(false)
      } else {
        setShowGRPs(true)
      }
    }
  }, [userData])

  if (
    !postData.image ||
    postData.image === "" ||
    postData.image === undefined ||
    postData.image === null
    // postData.image.match("https://scontent.fbom23-1.fna.fbcdn.net/")
  ) {
    postUrl = DefaultPostImage;
  } else {
    postUrl = postData.image;
  }

  // SHOW WHITE SOCIAL ICONS ON VIDEO THUMBNAILS
  let videoPlatformIcon;
  defaultWhiteSocialIconsUrls.find((element) => {
    if (element.title === postData.platform) {
      return (videoPlatformIcon = element.iconUrl);
    }
  });

  return (
    <Wrapper>
      {showImagesOnly === false ? (
        <Wrapper>
          <div className="card postCard">
            <div className="card_header">
              <div className="time_wrapper">{postData.time}</div>
              <div className="checkbox_wrapper">
                <input
                  type="checkbox"
                  name="checkbox"
                  className="checkbox_input"
                  disabled={postData.isDisabled === true ? true : false}
                  onClick={(event) => checkboxEventHandler(postData, event)}
                  checked={postData.isChecked === true ? true : false}
                  readOnly
                />
              </div>
            </div>
            <div className="card_body">
              <div className="left_Section">
                <div className="post_img_section">
                  <div className="img_wrapper">
                    <img
                      src={postUrl}
                      alt="img"
                      onError={(e) => (e.target.src = DefaultPostImage)}
                    />
                    {postData.platform !== undefined &&
                      <div className="overlay_wrapper">
                        <div className="platform_thumbnail_wrapper">
                          <div className="iconWrapper">
                            <img className="icon" src={videoPlatformIcon} />
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="caption_wrapper">
                  {postData.post_link === "0" || postData.post_link === 0 || typeof postData.post_link === "number" || postData.post_link === "" ?
                    <a className="theme_button disabled_btn">
                      {platform === "Twitter" || platform === "twitter" ? "View Tweet" : "View Post"}
                    </a>
                    :
                    <a target="_blank" href={postData.post_link}>
                      {platform === "Twitter" || platform === "twitter" ? "View Tweet" : "View Post"}
                    </a>
                  }
                  {/* <a target={"_blank"} href={postData.post_link}>
                    {platform === "Twitter" || platform === "twitter" ? "View Tweet" : "View Post"}
                  </a> */}
                </div>
              </div>
              <div className="right_Section view_all_cards">
                <div className="post_details_section ">
                  <div className="section_card_table_wrapper">
                    <div className="table_wrapper">
                      <table className="table table_bordered ">
                        <tbody>
                          {module === "video" &&
                            postData.metrics_data.map(
                              (details, postImpressionIndex) => {
                                if (platform.toLowerCase() === "overall") {
                                  return (
                                    <Wrapper key={postImpressionIndex}>
                                      {details.label.toLowerCase() === "views" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter title={details.label} number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {details.label.toLowerCase() === "engagement rate" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    </Wrapper>
                                  )
                                }
                                else if (platform.toLowerCase() === "facebook") {
                                  return (
                                    <Wrapper key={postImpressionIndex}>
                                      {details.label.toLowerCase() === "total video views" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {details.label.toLowerCase() === "avg watch time" || details.label.toLowerCase() === "avg watch time" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    </Wrapper>
                                  )
                                }
                                else if (platform.toLowerCase() === "instagram") {
                                  return (
                                    <Wrapper key={postImpressionIndex}>
                                      {details.label.toLowerCase() === "video views" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {details.label.toLowerCase() === "engagement" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    </Wrapper>
                                  )
                                }
                                else if (platform.toLowerCase() === "twitter") {
                                  return (
                                    <Wrapper key={postImpressionIndex}>
                                      {details.label.toLowerCase() === "total views" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {details.label.toLowerCase() === "view rate" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    </Wrapper>
                                  )
                                }
                                else if (platform.toLowerCase() === "linkedin") {
                                  return (
                                    <Wrapper key={postImpressionIndex}>
                                      {details.label.toLowerCase() === "views" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {details.label.toLowerCase() === "view rate" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    </Wrapper>
                                  )
                                }
                                else if (platform.toLowerCase() === "youtube") {
                                  return (
                                    <Wrapper key={postImpressionIndex}>
                                      {details.label.toLowerCase() === "views" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {details.label.toLowerCase() === "average view duration" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    </Wrapper>
                                  )
                                }
                                else if (postImpressionIndex && postImpressionIndex < 2) {
                                  return (
                                    // <tr key={postImpressionIndex}>
                                    //   <td>{details.label}</td>
                                    //   <td className="bold">{details.value}</td>
                                    // </tr>
                                    <Wrapper key={postImpressionIndex}>
                                      <tr>
                                        <td>
                                          <h3 className="bold gray_title">
                                            {typeof details.value === "string" ?
                                              <span>{details.value}</span>
                                              :
                                              <NumberFormatter number={details.value} />
                                            }
                                          </h3>
                                          <div className="gray_subTitle">
                                            {details.label}
                                          </div>
                                        </td>
                                      </tr>
                                    </Wrapper>
                                  );
                                }
                              }
                            )
                          }
                          {module === "social" &&
                            postData.metrics_data.map(
                              (details, postImpressionIndex) => {
                                if (platform.toLowerCase() === "instagram" || platform.toLowerCase() === "linkedin") {
                                  return (
                                    <Wrapper key={postImpressionIndex}>
                                      {details.label.toLowerCase() === "impressions" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {platform.toLowerCase() === "linkedin" && details.label.toLowerCase() === "reach" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {details.label.toLowerCase() === "post reaction" &&
                                        <tr>
                                          <td>
                                            <h3 className="bold gray_title">
                                              {typeof details.value === "string" ?
                                                <span>{details.value}</span>
                                                :
                                                <NumberFormatter number={details.value} />
                                              }
                                            </h3>
                                            <div className="gray_subTitle">
                                              {details.label}
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    </Wrapper>
                                  )
                                } else if (postImpressionIndex < 2) {
                                  return (
                                    // <tr key={postImpressionIndex}>
                                    //   <td>{details.label}</td>
                                    //   <td className="bold">{details.value}</td>
                                    // </tr>
                                    <Wrapper key={postImpressionIndex}>
                                      <tr>
                                        <td>
                                          <h3 className="bold gray_title">
                                            {typeof details.value === "string" ?
                                              <span>{details.value}</span>
                                              :
                                              <NumberFormatter number={details.value} />
                                            }
                                          </h3>
                                          <div className="gray_subTitle">
                                            {details.label}
                                          </div>
                                        </td>
                                      </tr>
                                    </Wrapper>
                                  );
                                }
                              }
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="button_wrapper">
                  <Button
                    handleClick={() => viewMoreOnclickHandler(postData)}
                    className="secondary_btn"
                    type="button"
                  >
                    View More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      ) : viewBrandInfo === true ? (
        <Wrapper>
          <div className="card postCard brandCompareWrapper">
            <div className="card_header">
              <div className="postBrandHeader">
                <div className="card_header_title">
                  {postData.brand_name}
                </div>
                <div className="checkbox_wrapper">
                  <input
                    type="checkbox"
                    name="checkbox"
                    className="checkbox_input"
                    disabled={postData.isDisabled === true ? true : false}
                    onClick={(event) => checkboxEventHandler(postData, event)}
                    checked={postData.isChecked === true ? true : false}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="card_body">
              <div className="left_Section">
                <div className="post_img_section">
                  <div className="img_wrapper">
                    <img
                      src={postData.brand_logo}
                      alt="img"
                      onError={(e) => (e.target.src = DefaultPostImage)}
                    />
                  </div>
                </div>
                <div className="caption_wrapper">
                  <div className="brand_information">
                    {!showGRPs && postData.metrics_data && postData.metrics_data.map((metric, metricInfo) => (
                      metric.label === "Spends" &&
                      <Wrapper key={metricInfo}>
                        <div className="grp_item">
                          <div className="brand_data">
                            <ErrorBoundary>
                              <NumberFormatter number={metric.value} />
                            </ErrorBoundary>
                            <span className="brand_legend">{metric.label}</span>
                          </div>
                        </div>
                      </Wrapper>
                    ))

                    }
                    {showGRPs && postData.metrics_data && postData.metrics_data.map((metric, metricInfo) => (
                      metric.label === "GRPs" &&
                      <Wrapper key={metricInfo}>
                        <div className="grp_item">
                          <div className="brand_data">
                            <ErrorBoundary>
                              <NumberFormatter number={metric.value} />
                            </ErrorBoundary>
                            <span className="brand_legend">{metric.label}</span>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                    {postData.metrics_data.map((metric, metricInfo) => (
                      metric.label === "Impressions" &&
                      <Wrapper key={metricInfo}>
                        <div className="grp_item">
                          <div className="brand_data">
                            <ErrorBoundary>
                              <NumberFormatter number={metric.value} />
                            </ErrorBoundary>
                            <span className="brand_legend">{metric.label}</span>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      )
        : tvHaleon === true ? (
          <Wrapper>
            <div className="card postCard brandCompareWrapper">
              <div className="card_header">
                <div className="postBrandHeader">
                  <div className="card_header_title">
                    {postData.brand_name}
                  </div>
                  <div className="checkbox_wrapper">
                    <input
                      type="checkbox"
                      name="checkbox"
                      className="checkbox_input"
                      disabled={postData.isDisabled === true ? true : false}
                      onClick={(event) => checkboxEventHandler(postData, event)}
                      checked={postData.isChecked === true ? true : false}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="card_body">
                <div className="left_Section">
                  <div className="post_img_section">
                    <div className="img_wrapper">
                      <img
                        src={postData.brand_logo}
                        alt="img"
                        onError={(e) => (e.target.src = DefaultPostImage)}
                      />
                    </div>
                  </div>
                  <div className="caption_wrapper">
                    <div className="brand_information">
                      {postData.metrics_data && postData.metrics_data.map((metric, metricInfo) => (
                        metric.label === "GRPs" &&
                        <Wrapper key={metricInfo}>
                          <div className="grp_item">
                            <div className="brand_data">
                              <ErrorBoundary>
                                <NumberFormatter number={metric.value} />
                              </ErrorBoundary>
                              <span className="brand_legend">{metric.label}</span>
                            </div>
                          </div>
                        </Wrapper>
                      ))}
                      {postData.metrics_data.map((metric, metricInfo) => (
                        metric.label === "Spends" &&
                        <Wrapper key={metricInfo}>
                          <div className="grp_item">
                            <div className="brand_data">
                              <ErrorBoundary>
                                <NumberFormatter number={metric.value} title={metric.label} />
                              </ErrorBoundary>
                              <span className="brand_legend">{metric.label}</span>
                            </div>
                          </div>
                        </Wrapper>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        )

          : isMarketSummary === true ? (
            <Wrapper>
              <div className="card postCard brandCompareWrapper">
                <div className="card_header">
                  <div className="postBrandHeader">
                    <div className="card_header_title">
                      {postData.brand_name}
                    </div>
                    <div className="checkbox_wrapper">
                      <input
                        type="checkbox"
                        name="checkbox"
                        className="checkbox_input"
                        disabled={postData.isDisabled === true ? true : false}
                        onClick={(event) => checkboxEventHandler(postData, event)}
                        checked={postData.isChecked === true ? true : false}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="card_body">
                  <div className="left_Section">
                    <div className="post_img_section">
                      <div className="img_wrapper">
                        <img
                          src={postData.brand_logo}
                          alt="img"
                          onError={(e) => (e.target.src = DefaultPostImage)}
                        />
                      </div>
                    </div>
                    <div className="caption_wrapper">
                      <div className="brand_information">
                        {postData.metrics_data && postData.metrics_data.map((metric, metricInfo) => (
                          metric.label === "Monthly Sales Vol." &&
                          <Wrapper key={metricInfo}>
                            <div className="grp_item">
                              <div className="brand_data">
                                <ErrorBoundary>
                                  <NumberFormatter number={metric.value} title={metric.label} />
                                </ErrorBoundary>
                                <span className="brand_legend">{metric.label}</span>
                              </div>
                            </div>
                          </Wrapper>
                        ))}
                        {postData.metrics_data.map((metric, metricInfo) => (
                          metric.label === "TV Spends" &&
                          <Wrapper key={metricInfo}>
                            <div className="grp_item">
                              <div className="brand_data">
                                <ErrorBoundary>
                                  <NumberFormatter number={metric.value} title={metric.label} />
                                </ErrorBoundary>
                                <span className="brand_legend">{metric.label}</span>
                              </div>
                            </div>
                          </Wrapper>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Wrapper>
          )

            : (
              <Wrapper>
                <div className="card_wrapper grid grid_cols_1">
                  <div className="card postCard single p_20 border">
                    <div className="card_header">
                      <div className="checkbox_wrapper">
                        <input
                          type="checkbox"
                          name="checkbox"
                          className="checkbox_input"
                          disabled={postData.isDisabled === true ? true : false}
                          onClick={(event) => checkboxEventHandler(postData, event)}
                          checked={postData.isChecked === true ? true : false}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="card_body">
                      <div className="left_Section">
                        <div className="post_img_section">
                          <div className="img_wrapper">
                            <img
                              src={postUrl}
                              alt="img"
                              onError={(e) => (e.target.src = DefaultPostImage)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card_footer">
                      <div className="button_wrapper">
                        <Button
                          handleClick={() => viewMoreOnclickHandler(postData)}
                          className="secondary_btn"
                          type="button"
                        >
                          View More
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Wrapper>
            )}
    </Wrapper>
  );
};

ComparePostCard.propTypes = {
  postData: PropTypes.object,
  checkboxEventHandler: PropTypes.func,
  showImagesOnly: PropTypes.bool,
  viewMoreOnclickHandler: PropTypes.func,
  viewBrandInfo: PropTypes.bool,
  isMarketSummary: PropTypes.bool,
  platform: PropTypes.string,
  tvHaleon: PropTypes.bool
};

export default ComparePostCard;
