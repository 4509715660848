import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import {
  DonutChartColorSet,
  PieOfPieSeriesColorSet,
} from "../../../constants/constants";

am5.addLicense("AM5C258896422");

const DonutPieChart = ({
  chartId,
  chartClass,
  varient,
  chartData,
  onSliceClick,
  defaultValue,
  chartColorSet,
}) => {
  let valueLabel = null

  if ("name" in chartData[0]) {
    valueLabel = "name"
  } else {
    valueLabel = "label"
  }

  let seriesParams = {};

  if ("childLabel" in chartData[0]) {
    if ("name" in chartData[0]) {
      let hoverLabel = chartData[0].label;
      seriesParams =
        `[bold width:100px]{name}[/]\n[bold]${hoverLabel}[/]: {value}\n[bold]{childLabel}[/]: {childValue}`;

    } else {
      seriesParams =
        "[bold  width:100px]{valuePercentTotal.formatNumber('0.00')}%[/]\n[bold]{category}[/]: {value}\n[bold]{childLabel}[/]: {childValue}";
    }
  } else {
    seriesParams =
      "[bold  width:100px]{valuePercentTotal.formatNumber('0.00')}%[/]\n[bold]{category}[/]: {value}";
  }

  useLayoutEffect(() => {
    let root = am5.Root.new(chartId);

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    if (chartColorSet === undefined) {
      var colorSet = DonutChartColorSet;
    } else {
      var colorSet = chartColorSet;
    }

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: varient ? 180 : -90,
        endAngle: varient ? 360 : 270,
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
      })
    );

    // The data
    var data = chartData;

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        startAngle: varient ? 180 : -90,
        endAngle: varient ? 360 : 270,
        name: "name",
        valueField: "value",
        categoryField: valueLabel,
        childLabel: "childLabel",
        childValue: "childValue",
        alignLabels: true
      })
    );


    if (onSliceClick) {
      series.slices.template._settings.cursorOverStyle = "pointer"
    }

    series.slices.template.states.create("active", {
      shiftRadius: 20,
      stroke: am5.color(0xffffff),
      strokeWidth: 0,
    });

    {
      !onSliceClick && series.slices.template.set("toggleKey", "none");
    }
    var tempArr = [];
    var oneSlicePulled = [];
    var foobar = [];
    let id = "";

    {
      onSliceClick &&
        series.slices.template.events.on("click", function (ev) {

          if (tempArr.includes(ev.target.dataItem.dataContext)) {
            tempArr = [];
            onSliceClick(defaultValue !== undefined ? ev.target.dataItem.dataContext : {});
          } else {
            tempArr = [];
            onSliceClick(ev.target.dataItem.dataContext);
            tempArr.push(ev.target.dataItem.dataContext);
          }


          series.slices.each(function (slice) {
            if (defaultValue) {
              setTimeout(() => {
                id = ev.target.uid;
                if (slice.uid === id) {
                  slice.set("active", true);
                  // slice.set("interactive", false);
                } else {
                  if (slice != ev.target) {
                    slice.set("active", false);
                    slice.set("interactive", true);
                  }
                }
              }, 50);
            } else {
              if (slice != ev.target) {
                slice.set("active", false);
              }
            }
          });
        });
    }

    {
      defaultValue &&
        series.slices.template.events.on("dataitemchanged", function (ev) {
          series.slices.each(function (slice) {
            if (slice.dataItem.dataContext.label == defaultValue) {
              setTimeout(() => {
                slice.set("active", true);
                // slice.set("interactive", false);
              }, 500);
            }
          });
        });
    }

    series.states.create("hidden", {
      startAngle: 180,
      endAngle: 180,
    });

    series.ticks.template.setAll({
      forceHidden: true,
    });
    series.labels.template.set("forceHidden", true);

    // Disabling labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    series.get("colors").set("colors", colorSet);

    // series.get("colors").set("colors", [
    //   am5.color(0x11a1fd),
    //   am5.color(0x5a75f9),
    //   am5.color(0x07c180),
    //   am5.color(0xff9931),
    //   am5.color(0x4cc3fd),
    //   am5.color(0x8298fb),
    //   am5.color(0x3cd795),
    //   am5.color(0xffb866),
    // ]);

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    // series.data.setAll([
    //     { value: 10, label: "One" },
    //     { value: 9, label: "Two" },
    //     { value: 6, label: "Three" },
    //     { value: 5, label: "Four" },
    //     { value: 4, label: "Five" },
    //     { value: 3, label: "Six" },
    //     { value: 1, label: "Seven" },
    // ]);

    series.data.setAll(data);

    var tooltip = series.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      // maxWidth: 200,
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    series.slices.template.set("tooltipText", seriesParams);

    tooltip.label.setAll({
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    // Add legend
    // var legend = chart.children.push(
    //     am5.Legend.new(root, {
    //         // background: am5.Rectangle.new(root, {
    //         //     fill: am5.color(0xff5599),
    //         //     fillOpacity: 0.2
    //         // }), // to add the backgrounD to the legends
    //         position: "relative", // options: "absolute" / "relative"
    //         // width: am5.percent(100),
    //         // height: am5.percent(20),
    //         x: am5.percent(50),
    //         centerX: am5.percent(50),
    //         layout: root.horizontalLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
    //         layout: am5.GridLayout.new(root, {
    //             // maxColumns: 4,
    //             fixedWidthGrid: true,
    //         }),
    //         verticalScrollbar: am5.Scrollbar.new(root, {
    //             orientation: "vertical",
    //         }),
    //     })
    // );

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        position: "relative", // options: "absolute" / "relative"
        centerX: am5.percent(50),
        x: am5.percent(50),
        height: chartData.length >= 6 ? am5.percent(30) : null,
        marginTop: 15,
        // marginBottom: 15,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          maxColumns: 10,
          fixedWidthGrid: true,
        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.markers.template.setAll({
      width: 13,
      height: 13,
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });

    // TO HIDE LABEL VALUES FROM LEGEND
    legend.valueLabels.template.set("forceHidden", true);

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <ErrorBoundary>
        <div id={chartId} className={chartClass}></div>
      </ErrorBoundary>
    </Wrapper>
  );
};

DonutPieChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  varient: PropTypes.string,
  chartData: PropTypes.array,
  onSliceClick: PropTypes.func,
  defaultValue: PropTypes.object,
  chartColorSet: PropTypes.array,
};

export default DonutPieChart;
