import React, { Component } from 'react'
import { Carousel } from '../../../shared/components/carousel/carousel';
import { ProductCard } from '../../../shared/components/cards/riproductcard/riproductcard';

export default class RiCustomerBrand extends Component {
  render() {
    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    }

    return (
      <div className="icz-carouselcontainer">
        <Carousel
          Settings={CarouselSettings}
          class={"icz-cardcontainer"}
          SliderCards={[
            <ProductCard
              index="1"
              CardClass="icz-ristorecardwrapper"
              CardIcon="01"
              CardTitle="Denims"
              CardScoreCurrent="20K"
              CardScoreText="Bills"
              CardScorePrevious="20%"
              CardScoreText2="Mix"
            />,
            <ProductCard
              index="2"
              CardClass="icz-ristorecardwrapper"
              CardIcon="02"
              CardTitle="Shirts"
              CardScoreCurrent="15K"
              CardScoreText="Bills"
              CardScorePrevious="15%"
              CardScoreText2="Mix"
            />,
            <ProductCard
              index="3"
              CardClass="icz-ristorecardwrapper"
              CardIcon="03"
              CardTitle="Shoes"
              CardScoreCurrent="12K"
              CardScoreText="Bills"
              CardScorePrevious="12%"
              CardScoreText2="Mix"
            />,
            <ProductCard
              index="4"
              CardClass="icz-ristorecardwrapper"
              CardIcon="04"
              CardTitle="Shorts"
              CardScoreCurrent="10K"
              CardScoreText="Bills"
              CardScorePrevious="10%"
              CardScoreText2="Mix"
            />,
            <ProductCard
              index="5"
              CardClass="icz-ristorecardwrapper"
              CardIcon="05"
              CardTitle="Luggage"
              CardScoreCurrent="2K"
              CardScoreText="Bills"
              CardScorePrevious="2%"
              CardScoreText2="Mix"
            />
        ]}>
        </Carousel>
      </div>
    )
  }
}
