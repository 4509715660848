import Wrapper from "../../helpers/Wrapper";
import { Link, NavLink, } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Submenu from "./subMenu";
import AuthContext from "../../../store/authContext";
import LogoutIcon from "../../../assets/icons/LogoutIcon";
import { useSelector } from "react-redux";
import { IAEvent_Module_Visit } from "../../../utils/IAEvents";
import { DefaultProfileImage } from "../../../constants/constants";
import HomeIcon from "../../../assets/icons/HomeIcon";
import PerformanceIcon from "../../../assets/icons/PerformanceIcon";
import UsersGroupIcon from "../../../assets/icons/UsersGroupIcon";
import VideoIcon from "../../../assets/icons/VideoIcon";
import HashtagIcon from "../../../assets/icons/HashtagIcon";
import CompareIcon from "../../../assets/icons/CompareIcon";
import WebsiteIcon from "../../../assets/icons/WebsiteIcon";
import InfluencersIcon from "../../../assets/icons/InfluencersIcon";
import TVIcon from "../../../assets/icons/TVIcon";
import SocialListeningIcon from "../../../assets/icons/SocialListeningIcon";
import RetailIntelligenceBaseIcon from "../../../assets/icons/RiIconBase";


export const Sidebar = () => {
  const authCtx = useContext(AuthContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let userCountryCode = userData?.country_code ?? "IN";

  const sidebarData = useSelector((state) => state.sidebar.sidebarData);


  let companyLogoUrl = userData?.company?.logo ?? DefaultProfileImage;

  useEffect(() => {
    localStorage.setItem('static-country-code', userCountryCode)
  })


  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showSidebarCloseButton, setShowSidebarCloseButton] = useState(true);
  const [activeModule, setActiveModule] = useState("");

  let listOfSidebar = [];
  sidebarData.length !== 0 &&
    sidebarData.map((data, dataIndex) => {
      // HOME
      if (data.code.toLowerCase() === "home" && data.has_permission === true) {
        listOfSidebar.push({
          moduleName: data.name,
          moduleLink: "/",
          moduleIcon: <HomeIcon className={"nav_link_icon"} />,
          subModules: [],
        });
      }

      // MARKETING INTELLIGENCE
      if (data.code.toLowerCase() === "mi" && data.has_permission === true) {
        listOfSidebar.push({
          moduleName: data.name,
          moduleLink: "/mi",
          moduleIcon: <PerformanceIcon className={"nav_link_icon"} />,
          subModules: [],
        });
        data.sub_module.map((subData, subDataIndex) => {
          if (subData.code.toLowerCase() === "campaigns" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/campaigns",
              subModuleIcon: <PerformanceIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "social" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/social",
              subModuleIcon: <UsersGroupIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "video" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/video",
              subModuleIcon: <VideoIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "hashtag" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/hashtag",
              subModuleIcon: <HashtagIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "compare" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/compare",
              subModuleIcon: <CompareIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "website" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/website",
              subModuleIcon: <WebsiteIcon className={"nav_link_icon"} />,
            });
          }
          if (
            subData.code.toLowerCase() === "influencers" &&
            subData.has_permission === true
          ) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/influencers",
              subModuleIcon: <InfluencersIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "social listening" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/social-listening",
              subModuleIcon: (
                <SocialListeningIcon className={"nav_link_icon"} />
              ),
            });
          }
          if (subData.code.toLowerCase() === "tv" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.push({
              subModuleName: subData.name,
              subModuleLink: "/mi/tv",
              subModuleIcon: <TVIcon className={"nav_link_icon"} />,
            });
          }
        });
      }

      // RETAIL INTELLIGENCE
      if (data.code.toLowerCase() === "ri" && data.has_permission === true) {
        listOfSidebar.push({
          moduleName: data.name,
          moduleLink: "/ri",
          moduleIcon: <RetailIntelligenceBaseIcon className={"nav_link_icon"} />,
          subModules: [],
        });
        data.sub_module.map((subData) => {
          if (subData.code.toLowerCase() === "sales" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.splice(0, 0, {
              subModuleName: subData.name,
              subModuleLink: "/ri/sales",
              subModuleIcon: <TVIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "tv" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.splice(1, 0, {
              subModuleName: subData.name,
              subModuleLink: "/ri/tv",
              subModuleIcon: <TVIcon className={"nav_link_icon"} />,
            });
          }
          if (subData.code.toLowerCase() === "market" && subData.has_permission === true) {
            listOfSidebar[dataIndex].subModules.splice(1, 0, {
              subModuleName: subData.name,
              subModuleLink: "/ri/market",
              subModuleIcon: <TVIcon className={"nav_link_icon"} />,
            });
          }
        });
      }

      // // DYNAMIC ECOM
      // if (data.code.toLowerCase() === "ecom" && data.has_permission === true) {
      //   listOfSidebar.push({
      //     moduleName: data.name,
      //     moduleLink: "/ecom",
      //     moduleIcon: <PerformanceIcon className={"nav_link_icon"} />,
      //     subModules: [],
      //   });
      //   data.sub_module.map((subData) => {
      //     if (subData.code.toLowerCase() === "summary" && subData.has_permission === true) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/summary",
      //         subModuleIcon: <PerformanceIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //     if (subData.code.toLowerCase() === "customer" && subData.has_permission === true) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/customer",
      //         subModuleIcon: <UsersGroupIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //     if (
      //       subData.code.toLowerCase() === "engagement" &&
      //       subData.has_permission === true
      //     ) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/engagement",
      //         subModuleIcon: <VideoIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //     if (subData.code.toLowerCase() === "traffic" && subData.has_permission === true) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/traffic",
      //         subModuleIcon: <HashtagIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //     if (subData.code.toLowerCase() === "earned" && subData.has_permission === true) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/earned",
      //         subModuleIcon: <CompareIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //     if (subData.code.toLowerCase() === "paid" && subData.has_permission === true) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/paid",
      //         subModuleIcon: <WebsiteIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //     if (subData.code.toLowerCase() === "sales" && subData.has_permission === true) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/sales",
      //         subModuleIcon: <InfluencersIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //     if (subData.code.toLowerCase() === "realtime" && subData.has_permission === true) {
      //       listOfSidebar[dataIndex].subModules.push({
      //         subModuleName: subData.name,
      //         subModuleLink: "/ecom/realtime",
      //         subModuleIcon: <TVIcon className={"nav_link_icon"} />,
      //       });
      //     }
      //   });
      // }

      // data.sub_module.map((subData) => {
      //   if (subData.code.toLowerCase() === "summary" && subData.has_permission === true) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/summary",
      //       subModuleIcon: <PerformanceIcon className={"nav_link_icon"} />,
      //     });
      //   }
      //   if (subData.code.toLowerCase() === "customer" && subData.has_permission === true) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/customer",
      //       subModuleIcon: <UsersGroupIcon className={"nav_link_icon"} />,
      //     });
      //   }
      //   if (
      //     subData.code.toLowerCase() === "engagement" &&
      //     subData.has_permission === true
      //   ) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/engagement",
      //       subModuleIcon: <VideoIcon className={"nav_link_icon"} />,
      //     });
      //   }
      //   if (subData.code.toLowerCase() === "traffic" && subData.has_permission === true) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/traffic",
      //       subModuleIcon: <HashtagIcon className={"nav_link_icon"} />,
      //     });
      //   }
      //   if (subData.code.toLowerCase() === "earned" && subData.has_permission === true) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/earned",
      //       subModuleIcon: <CompareIcon className={"nav_link_icon"} />,
      //     });
      //   }
      //   if (subData.code.toLowerCase() === "paid" && subData.has_permission === true) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/paid",
      //       subModuleIcon: <WebsiteIcon className={"nav_link_icon"} />,
      //     });
      //   }
      //   if (subData.code.toLowerCase() === "sales" && subData.has_permission === true) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/sales",
      //       subModuleIcon: <InfluencersIcon className={"nav_link_icon"} />,
      //     });
      //   }
      //   if (subData.code.toLowerCase() === "realtime" && subData.has_permission === true) {
      //     listOfSidebar[dataIndex].subModules.push({
      //       subModuleName: subData.name,
      //       subModuleLink: "/ecom/realtime",
      //       subModuleIcon: <TVIcon className={"nav_link_icon"} />,
      //     });
      //   }
      // });


      // ARYABOT
      if (data.code.toLowerCase() === "aryabot" && data.has_permission === true) {
        listOfSidebar.push({
          moduleName: data.name,
          moduleLink: "/aryabot",
          moduleIcon: <PerformanceIcon className={"nav_link_icon"} />,
          subModules: [],
        });
      }

      if (data.code.toLowerCase() === "campaign_admin" && data.has_permission === true) {
        listOfSidebar.push({
          moduleName: data.name,
          moduleLink: "/admin/campaign",
          moduleIcon: <UsersGroupIcon className={"nav_link_icon"} />,
          subModules: [],
        });
      }
    });

  // listOfSidebar.push({
  //   moduleName: "ROI",
  //   moduleLink: "/roi",
  //   moduleIcon: <RetailIntelligenceIcon className={"nav_link_icon"} />,
  //   subModules: [],
  // });

  // listOfSidebar.map((module) => {
  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Summary",
  //       subModuleLink: "/roi/summary",
  //       subModuleIcon: <SummaryIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Sales Summary",
  //       subModuleLink: "/roi/sales-summary",
  //       subModuleIcon: <SalesIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Business Overview",
  //       subModuleLink: "/roi/business-overview",
  //       subModuleIcon: <BusinessOverviewIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Sell Through",
  //       subModuleLink: "/roi/sellthrough",
  //       subModuleIcon: <SellThroughIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Store Health",
  //       subModuleLink: "/roi/storehealth",
  //       subModuleIcon: <StoreHealthIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Customer",
  //       subModuleLink: "/roi/customer",
  //       subModuleIcon: <CustomerIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Target Group",
  //       subModuleLink: "/roi/targetgroup",
  //       subModuleIcon: <EngagementIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "ROI") {
  //     module.subModules.push({
  //       subModuleName: "Branch Score",
  //       subModuleLink: "/roi/branchscore",
  //       subModuleIcon: <StoreHealthIcon className={"nav_link_icon"} />,
  //     })
  //   }
  // })

  // listOfSidebar.push({
  //   moduleName: "Ecommerce",
  //   moduleLink: "/ecom",
  //   moduleIcon: <EcomIcon className={"nav_link_icon"} />,
  //   subModules: [],
  // });

  // listOfSidebar.map((module) => {
  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Summary",
  //       subModuleLink: "/ecom/summary",
  //       subModuleIcon: <EcomIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Customer",
  //       subModuleLink: "/ecom/customer",
  //       subModuleIcon: <CustomerIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Engagement",
  //       subModuleLink: "/ecom/engagement",
  //       subModuleIcon: <EngagementIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Traffic",
  //       subModuleLink: "/ecom/traffic",
  //       subModuleIcon: <TrafficIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Earned",
  //       subModuleLink: "/ecom/earned",
  //       subModuleIcon: <EarnedIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Paid",
  //       subModuleLink: "/ecom/paid",
  //       subModuleIcon: <PaidIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Sales",
  //       subModuleLink: "/ecom/sales",
  //       subModuleIcon: <SalesIcon className={"nav_link_icon"} />,
  //     })
  //   }

  //   if (module.moduleName === "Ecommerce") {
  //     module.subModules.push({
  //       subModuleName: "Realtime",
  //       subModuleLink: "/ecom/realtime",
  //       subModuleIcon: <RealTimeIcon className={"nav_link_icon"} />,
  //     })
  //   }

  // })

  const logoutHandler = () => {

    authCtx.logout();
  };


  // TO CLOSE SIDEBAR ON CLOSE ICON CLICK
  const sidebarToggleHandler = () => {
    // IF SCREEN SIZE IS LESS THAN 1599 PX THEN ONLY THIS FUNCTION WORK
    if (sidebarOpen === true) {
      // IF SIDEBAR IS OPEN THEN CLOSE IT
      setSidebarOpen(false);
    }
  };

  // SIDEBAR TOGGLE HANDLER ON CLICK OF MODULES
  const modulesToggleHandler = (item) => {
    // TO CHECK IF THE SCREEN SIZE IS LESS THAN 1599 PX
    if (window.innerWidth < 1599) {
      // WHEN SCREEN SIZE IS LESS THAN 1599 PX
      if (item.subModules.length === 0) {
        // IF MODULE HAS NO CHILDS THEN THE SIDEBAR WILL NOT OPEN ONCLICK
        setSidebarOpen(false);
      } else {
        // IF MODULE HAS CHILDS THEN THE SIDEBAR WILL OPEN ONCLICK
        setSidebarOpen(true);
      }
    } else {
      // IF SCREEN SIZE IF MORE THAN 1599 PX SIDEBAR IS ALWAYS OPEN
      setSidebarOpen(true);
    }
    IAEvent_Module_Visit(item.moduleName, item === "Home" ? "" : item?.subModules[0]?.subModuleName ?? '')
  };

  // TO OPEN OR CLOSE THE SIDEBAR AS PER SCREEN SIZE
  useEffect(() => {
    if (window.innerWidth > 1599) {
      setSidebarOpen(true);
      setShowSidebarCloseButton(false);
    } else {
      setSidebarOpen(false);
      setShowSidebarCloseButton(true);
    }

    const windowResize = () => {
      if (window.innerWidth > 1599) {
        setSidebarOpen(true);
        setShowSidebarCloseButton(false);
      } else {
        setSidebarOpen(false);
        setShowSidebarCloseButton(true);
      }
    };

    window.addEventListener("resize", windowResize);
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);

  return (
    <Wrapper>
      <div className="dashboard_sidebar">
        <div className="sidebar">
          <div className="module_sidebar">
            <div className="sidebar_wrapper">


              {/*   {!sidebarOpen ? ( */}
              <div className={`icons_sidebar ${!sidebarOpen ? "" : ""}`}>
                <div className="icons_sidebar_scrollbar">
                  <div className="sidebar_nav">
                    {listOfSidebar.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.moduleLink}
                        onClick={() => modulesToggleHandler(item)}
                        className={({ isActive }) =>
                          isActive
                            ? "nav_link active " + setActiveModule(item.moduleName)
                            : "nav_link"
                        }
                      >
                        {item.moduleIcon}
                      </NavLink>
                    ))}
                  </div>
                  <div className="footer">
                    <Link to={"#"} className="profile_wrapper">
                      <div className="icon_wrapper">
                        <img className="profile_icon"
                          src={companyLogoUrl}
                          alt="profile icon"
                          onError={(e) => (e.target.src = DefaultProfileImage)}
                        />
                      </div>
                    </Link>
                    <div className="sidebar_nav">
                      <div onClick={logoutHandler} className="nav_link">
                        <LogoutIcon className={"nav_link_icon"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`detailed_sidebar ${sidebarOpen ? "opened" : "closed"}`}>
                {showSidebarCloseButton && (
                  <Wrapper>
                    <div className="burger_menu_wrapper">
                      <div
                        onClick={sidebarToggleHandler}
                        className="burger_menu"
                      ></div>
                    </div>
                  </Wrapper>
                )}
                <div className="detailed_sidebar_scrollbar">
                  {listOfSidebar.map((item, index) => (
                    <Wrapper key={index}>
                      <div
                        key={(item, index)}
                        className="sidebar_module_tab_wrapper"
                      >
                        <NavLink
                          onClick={() => modulesToggleHandler(item)}
                          className={({ isActive }) =>
                            isActive
                              ? "sidebar_module_tab active " + setActiveModule(item.moduleName)
                              : "sidebar_module_tab"
                          }
                          to={item.moduleLink}
                        >
                          <div className="sidebar_module_tab_content">
                            <div className="sidebar_module_icon_wrapper">
                              {item.moduleIcon}
                            </div>
                            <div className="sidebar_module_title">
                              {item.moduleName}
                            </div>
                          </div>
                          {item.subModules.length != 0 && (
                            <Wrapper>
                              {activeModule === item.moduleName ? (
                                <div className="minus"></div>
                              ) : (
                                <div className="plus"></div>
                              )}
                            </Wrapper>
                          )}
                        </NavLink>
                        {activeModule === item.moduleName && item.subModules.length !== 0 ? (
                          <div className="sidebar_submodules_wrapper">
                            <Submenu
                              // activeModule={activeModule}
                              // activeSubModuleHandler={setActiveSubModule}
                              subModules={item.subModules}
                              sidebarToggleHandler={setSidebarOpen}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Wrapper>
                  ))}
                </div>
                <div className="detailed_sidebar_footer">
                  <Link to={"#"} className="profile_wrapper">
                    <div className="icon_wrapper">
                      <img
                        className="profile_icon"
                        src={companyLogoUrl}
                        alt="profile icon"
                        onError={(e) => (e.target.src = DefaultProfileImage)}
                      />
                    </div>
                    <div className="profile_details">
                      <div className="profile_title">
                        {userData !== undefined &&
                          userData?.first_name + " " + userData?.last_name}
                      </div>
                      <div className="profile_subtitle">
                        {userData !== undefined && userData?.company?.name}
                      </div>

                    </div>
                  </Link>
                  <div className="sidebar_nav">
                    <div onClick={logoutHandler} className="nav_link">
                      <div className="nav_link_icon_wrapper" style={{ "height": "30px" }}>
                        <LogoutIcon className={"nav_link_icon"} />
                      </div>
                      Logout
                    </div>
                  </div>
                </div>
              </div>






            </div>
          </div>
        </div>
      </div>
    </Wrapper >
  );
};

export default Sidebar;
