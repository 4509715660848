import Wrapper from "../../components/helpers/Wrapper";

const OutlierAnalysisIcon = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6272 5.24311C14.3196 5.24311 13.2544 4.17918 13.2544 2.87032C13.2544 1.56147 14.3184 0.497538 15.6272 0.497538C16.9361 0.497538 18 1.56147 18 2.87032C18 4.17918 16.9348 5.24311 15.6272 5.24311ZM15.6272 1.41603C14.8261 1.41603 14.1729 2.06919 14.1729 2.87032C14.1729 3.67146 14.8261 4.32461 15.6272 4.32461C16.4283 4.32461 17.0815 3.67146 17.0815 2.87032C17.0815 2.06919 16.4283 1.41603 15.6272 1.41603ZM2.55138 5.42171C2.55138 4.71752 1.97987 4.14602 1.27569 4.14602C0.57151 4.14602 0 4.71752 0 5.42171C0 6.12589 0.57151 6.6974 1.27569 6.6974C1.97987 6.6974 2.55138 6.12589 2.55138 5.42171ZM5.10276 14.9894C5.10276 14.2852 4.53125 13.7137 3.82707 13.7137C3.12289 13.7137 2.55138 14.2852 2.55138 14.9894C2.55138 15.6936 3.12289 16.2651 3.82707 16.2651C4.53125 16.2651 5.10276 15.6936 5.10276 14.9894ZM10.2055 13.7137C10.2055 13.0095 9.63402 12.438 8.92984 12.438C8.22566 12.438 7.65415 13.0095 7.65415 13.7137C7.65415 14.4179 8.22566 14.9894 8.92984 14.9894C9.63402 14.9894 10.2055 14.4179 10.2055 13.7137ZM7.65415 9.88662C7.65415 9.18244 7.08264 8.61093 6.37845 8.61093C5.67427 8.61093 5.10276 9.18244 5.10276 9.88662C5.10276 10.5908 5.67427 11.1623 6.37845 11.1623C7.08264 11.1623 7.65415 10.5908 7.65415 9.88662Z" fill="#3247B5" />
                <path d="M6.37845 4.146C7.08264 4.146 7.65415 4.71751 7.65415 5.42169C7.65415 6.12587 7.08264 6.69738 6.37845 6.69738C5.67427 6.69738 5.10276 6.12587 5.10276 5.42169C5.10276 4.71751 5.67427 4.146 6.37845 4.146Z" fill="#3247B5" />
                <path d="M2.23246 8.92984C2.93664 8.92984 3.50815 9.50135 3.50815 10.2055C3.50815 10.9097 2.93664 11.4812 2.23246 11.4812C1.52828 11.4812 0.956768 10.9097 0.956768 10.2055C0.956768 9.50135 1.52828 8.92984 2.23246 8.92984Z" fill="#3247B5" />
                <path d="M3.18923 0C3.89341 0 4.46492 0.57151 4.46492 1.27569C4.46492 1.97987 3.89341 2.55138 3.18923 2.55138C2.48505 2.55138 1.91354 1.97987 1.91354 1.27569C1.91354 0.57151 2.48505 0 3.18923 0Z" fill="#3247B5" />
            </svg>
        </Wrapper>
    )
}

export default OutlierAnalysisIcon;
