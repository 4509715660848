import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../helpers/Wrapper";
import SuggestedQuestionsList from "./suggestedQuestions";
import InsightsTable from "./insightsTable";
import InsightsChart from "./insightsChart";
import InsightsImg from "./insightsImg";
import InsightsTitle from "./insightTitle";
import InsightsDetails from "./insightDetails";
import SkeletonLoader, { ErrorLoader } from "./skeletonLoader";
import FeedbackSection from "../ui/feedback/feedbackSection";
import ErrorBoundary from "../../utils/ErrorBoundary";
import ExportDropdown from "../ui/dropdown/ExportDropdown";
import ChatMessageExportDropdown from "../export/chatMessageExport";

const Message = ({ message, type, onSuggestedQuestionClickHandler }) => {
  console.log("message", message);

  const messageStartRef = useRef(null); // TO SCROLL TO THE NEW MESSAGE ON EVERY NEW MESSAGE

  // UPDATE THE MESSAGE END REF WHEN USER SUBMIT THE NEW MESSAGE
  useEffect(() => {
    messageStartRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [message]);

  let content = <SkeletonLoader />

  let ID = `insights_${type}_${Math.random()}`;

  const [showSplit, setShowSplit] = useState(false)

  useEffect(() => {
    if (type === "bot") {

      const chartData = message[0]?.model_data?.chart_data?.data || null;
      const tableData = message[0]?.model_data?.table_data?.data?.results || null;

      const chartDataLength = chartData.length !== 0 ? chartData.length : null;
      const chartDataExists = chartData.length !== 0 ? Object.keys(chartData[0]).length : null;
      const tableDataExists = tableData.length !== 0 ? Object.keys(tableData[0]).length : null;

      console.log("chartDataExists", chartDataLength, chartDataExists, tableDataExists);


      if (message[0]?.model_data?.chart_data?.chart_type === "pie-chart") {
        if (tableDataExists < 3) {
          setShowSplit(true);
        } else {
          setShowSplit(false);
        }
      } else {
        if ((chartDataLength < 10 && chartDataExists < 4 && tableDataExists <= 3)) {
          console.log("show split", chartDataLength, chartDataExists, tableDataExists);

          setShowSplit(true);
        } else {
          setShowSplit(false);
        }
      }
    }
  }, [type, message]);


  if (type === "bot") {
    content = <Wrapper>
      <div className={`message message_${type}`}>
        <div id={ID} className="message-content">
          <div className="message-header">
            <div className="left-section">
              {(
                message[0]?.title !== null &&
                message[0]?.title !== undefined &&
                message[0]?.title !== ""
              ) && (
                  <ErrorBoundary>
                    <InsightsTitle title={message[0]?.title} />
                  </ErrorBoundary>
                )}
            </div>
            <div className="right-section" data-html2canvas-ignore={true}>
              <ChatMessageExportDropdown
                sectionId={ID}
                sectionName={message[0]?.title}
                sectionData={message[0]}
              />
            </div>
          </div>

          {(
            message[0]?.model_data?.rich_text !== null &&
            message[0]?.model_data?.rich_text !== undefined &&
            message[0]?.model_data?.rich_text !== ""
          ) && (
              <ErrorBoundary>
                <InsightsDetails details={message[0]?.model_data?.rich_text} />
              </ErrorBoundary>
            )}


          {(
            message[0]?.model_data?.image !== null &&
            message[0]?.model_data?.image !== undefined &&
            message[0]?.model_data?.image !== ""
          ) && (
              <ErrorBoundary>
                <InsightsImg imgData={message[0]?.model_data?.image} />
              </ErrorBoundary>
            )}
          <div className={`${showSplit && "show-split"}`}>
            {(
              message[0]?.model_data?.chart_data !== null &&
              message[0]?.model_data?.chart_data !== undefined &&
              message[0]?.model_data?.chart_data?.data.length !== 0 &&
              message[0]?.model_data?.chart_data?.data !== null &&
              message[0]?.model_data?.chart_data?.data !== undefined &&
              message[0]?.model_data?.chart_data.chart_type !== null &&
              message[0]?.model_data?.chart_data.chart_type !== undefined
            ) && (
                <div className={`chartsWrapper`}>
                  <ErrorBoundary>
                    <InsightsChart chartData={message[0]?.model_data?.chart_data} />
                  </ErrorBoundary>
                </div>
              )}

            {(
              message[0]?.model_data?.table_data !== null &&
              message[0]?.model_data?.table_data !== undefined &&
              message[0]?.model_data?.table_data?.data !== null &&
              message[0]?.model_data?.table_data?.data !== undefined &&
              message[0]?.model_data?.table_data?.data?.results.length !== 0 &&
              message[0]?.model_data?.table_data?.data?.results !== null &&
              message[0]?.model_data?.table_data?.data?.results !== undefined
            ) && (
                <ErrorBoundary>
                  <InsightsTable tableData={message[0]?.model_data?.table_data?.data} chartType={message[0]?.model_data?.chart_data.chart_type} />
                </ErrorBoundary>
              )}
          </div>
        </div>
        {(
          message[0]?.suggested_question !== null &&
          message[0]?.suggested_question !== undefined &&
          Object.values(message[0]?.suggested_question).length !== 0
        ) && (
            <ErrorBoundary>
              <SuggestedQuestionsList questions={message[0]?.suggested_question} onClickHandler={onSuggestedQuestionClickHandler} />
            </ErrorBoundary>
          )}

      </div>
      <ErrorBoundary>
        <FeedbackSection />
      </ErrorBoundary>
    </Wrapper>
  }
  else if (type === "bot_error") {
    content = <Wrapper>
      <div className="message message_bot">
        <ErrorLoader />
      </div>
    </Wrapper>
  }
  else {
    content = <Wrapper>
      <div className={`message message_${type} `}>
        {message}
      </div>
    </Wrapper>
  }

  return (
    <Wrapper>
      <div ref={messageStartRef} />
      {content}
    </Wrapper>
  );
};

export default Message;
