import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../store/authContext";
import {
  setSelectedBrandsFromBrandsCategoryDropdown,
  setSelectedCategoriesFromBrandsCategoryDropdown,
} from "../../store/riSalesBrandsSlice";
import FullPageExportContext from "../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../components/helpers/axios";
import Wrapper from "../../components/helpers/Wrapper";
import PageHeader from "../../components/layout/pageHeader";
import Card from "../../components/ui/Card";
import Slider from "../../components/ui/Slider";
import CompareSalesBrands from "../../components/ui/CompareSalesBrands";
import SectionDefinationsBadge from "../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import Tab from "../../components/ui/Tab";

// MAP
import IndiaMap from "../../components/ui/map/IndiaMap";
import NewUSMap from "../../components/ui/map/NewUSMap";

// CHARTS
import Datatable from "../../components/ui/tables/Datatable";
import SimpleTreeMap from "../../components/ui/charts/SimpleTreeMap";
import BubbleChartSizeShapeDynamic from "../../components/ui/charts/BubbleChartSizeShapeDynamic";
import MultiAxisMultiLine from "../../components/ui/charts/MultiAxisMultiLine";

// LOADERS
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";

// UTILS
import ErrorBoundary from "../../utils/ErrorBoundary";
import TextFormatter from "../../utils/TextFormatter";
import { IAEvent_Platform_Visit } from "../../utils/IAEvents";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import { formatDateDisplay } from "../../components/ui/Datepicker";

import Modal from "../../components/ui/Modal";
import LineChart from "../../components/ui/charts/LineChart";
import PartitionedLineChart from "../../components/ui/charts/PartitionedLineChart";
import ExpandIcon from "../../assets/icons/ExpandIcon";
import { COKE_COMPANY_ID, IND_CENTER_POINTS, IND_NORTH_POINTS, IND_SOUTH_POINTS, UK_CENTER_POINTS, UK_NORTH_POINTS, UK_SOUTH_POINTS, US_CENTER_POINTS, US_NORTH_POINTS, US_SOUTH_POINTS } from "../../constants/constants";
import NewUKMap from "../../components/ui/map/NewUKMap";
import useApi from "../../hooks/useApi";
import SectionComments, { SectionCommentsErrorLoader, SectionCommentsSkeltonLoader } from "../../components/sectionComments/sectionComments";
// import SectionInsights from "../../components/insightsChat/sectionInsights";



const RiSales = () => {
  const ARYABOT_BASE_URL = process.env.REACT_APP_ARYABOT_API_URL;

  // PAGE CONSTANTS
  let pageModule = "Retail Intelligence"
  let pageSubModule = "Sales"
  let pagePlatform = "Overall";

  const userData = useSelector((state) => state.userData.user);

  let companyID = userData?.company?.id ?? '';

  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setExportPageName, setexportSummary, exportSummary, setIsExportDisabled } = useContext(FullPageExportContext);

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED BRANDS FROM DROPDOWN
  const selectedBrandIds = useSelector((state) => state.riSalesBrandsCategory.selectedBrandIds);
  const selectedCategories = useSelector((state) => state.riSalesBrandsCategory.selectedCategories);

  // BRANDS DATA MIN MAX DATES
  const brandMinMaxDataDates = useSelector((state) => state.moduleData.module);

  // TO GET THE MIN AND MAX DATE OF TV DATA AVAILABLE
  let minDatesArr = []
  let maxDatesArr = []
  let minDate = "";
  let maxDate = "";

  if (brandMinMaxDataDates.length !== 0 && brandMinMaxDataDates["ri"] !== undefined && brandMinMaxDataDates["ri"]["sales"] !== undefined && brandMinMaxDataDates["ri"]["sales"]["sales"] !== undefined) {
    let tvData = Object.values(brandMinMaxDataDates["ri"]["sales"]["sales"])

    tvData.map((brand) => (
      minDatesArr.push(new Date(brand.min_date)),
      maxDatesArr.push(new Date(brand.max_date))
    ))

    minDate = new Date(Math.min(...minDatesArr))
    maxDate = new Date(Math.max(...maxDatesArr))
  }

  // ARYABOT

  // SECTION NAMES
  let pageSectionName = {
    section1: {
      title: "Performance Trend",
      annotations: []
    },
    section2: {
      title: "Top / Bottom Performing Markets",
      annotations: []
    },
    section3: {
      title: "Summary",
      annotations: []
    },
    section4: {
      title: "Brandwise Performance",
      annotations: []
    },
  }

  // API CALLS
  const allCommentsAPI = useApi();

  const handleAllRecords = async () => {
    let params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
    }

    await allCommentsAPI.fetchData(ARYABOT_BASE_URL, 'aryabot/annotation/', params)
  }

  useEffect(() => {
    handleAllRecords()
  }, [selectedDates.startDate, selectedDates.endDate])

  // SEPERATE THE SECTION WISE ANNOTATIONS
  if (!allCommentsAPI.apiStatus.isLoading && allCommentsAPI.apiStatus.isLoaded) {
    if (allCommentsAPI.apiStatus.error !== null) {
      pageSectionName = null;
    } else {
      let allData = allCommentsAPI?.data?.data?.results;

      // Iterate through the data array
      allData.forEach(item => {

        // Iterate through the sections in pageSectionName
        for (let section in pageSectionName) {

          // Check if the section title matches the data section
          if (pageSectionName[section].title === item.section) {

            // Add the item to the annotations array of the matching section
            pageSectionName[section].annotations.push(item);

          }
        }
      });
    }
  }

  let section1 =
    <Wrapper>
      <ErrorBoundary>
        <SectionCommentsSkeltonLoader />
      </ErrorBoundary>
    </Wrapper>

  let section2 = <Wrapper>
    <ErrorBoundary>
      <SectionCommentsSkeltonLoader />
    </ErrorBoundary>
  </Wrapper>

  let section3 = <Wrapper>
    <ErrorBoundary>
      <SectionCommentsSkeltonLoader />
    </ErrorBoundary>
  </Wrapper>

  let section4 = <Wrapper>
    <ErrorBoundary>
      <SectionCommentsSkeltonLoader />
    </ErrorBoundary>
  </Wrapper>

  if (!allCommentsAPI.apiStatus.isLoading && allCommentsAPI.apiStatus.isLoaded) {
    if (pageSectionName) {
      section1 =
        <Wrapper>
          <ErrorBoundary>
            <SectionComments
              sectionModule={pageModule}
              sectionSubmodule={pageSubModule}
              sectionPlatform={pagePlatform}
              section={pageSectionName.section1.title}
              data={pageSectionName.section1.annotations}
            />
          </ErrorBoundary>
        </Wrapper>

      section2 = <Wrapper>
        <ErrorBoundary>
          <SectionComments
            sectionModule={pageModule}
            sectionSubmodule={pageSubModule}
            sectionPlatform={pagePlatform}
            section={pageSectionName.section2.title}
            data={pageSectionName.section2.annotations}
          />
        </ErrorBoundary>
      </Wrapper>

      section3 = <Wrapper>
        <ErrorBoundary>
          <SectionComments
            sectionModule={pageModule}
            sectionSubmodule={pageSubModule}
            sectionPlatform={pagePlatform}
            section={pageSectionName.section3.title}
            data={pageSectionName.section3.annotations}
          />
        </ErrorBoundary>
      </Wrapper>

      section4 = <Wrapper>
        <ErrorBoundary>
          <SectionComments
            sectionModule={pageModule}
            sectionSubmodule={pageSubModule}
            sectionPlatform={pagePlatform}
            section={pageSectionName.section4.title}
            data={pageSectionName.section4.annotations}
          />
        </ErrorBoundary>
      </Wrapper>
    } else {
      section1 = <Wrapper>
        <ErrorBoundary>
          <SectionCommentsErrorLoader />
        </ErrorBoundary>
      </Wrapper>;

      section2 = <Wrapper>
        <ErrorBoundary>
          <SectionCommentsErrorLoader />
        </ErrorBoundary>
      </Wrapper>;

      section3 = <Wrapper>
        <ErrorBoundary>
          <SectionCommentsErrorLoader />
        </ErrorBoundary>
      </Wrapper>;

      section4 = <Wrapper>
        <ErrorBoundary>
          <SectionCommentsErrorLoader />
        </ErrorBoundary>
      </Wrapper>;
    }
  }




  // INDIA MAP 
  const [MapData, setMapData] = useState([]);
  const [isMapLoading, setMapIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [MapError, setMapError] = useState(null);

  let DEFAULT_CENTER_POINT = userData.country_code === "US" ? US_CENTER_POINTS : userData.country_code === "UK" ? UK_CENTER_POINTS : IND_CENTER_POINTS;
  let DEFAULT_NORTH_POINT = userData.country_code === "US" ? US_NORTH_POINTS : userData.country_code === "UK" ? UK_NORTH_POINTS : IND_NORTH_POINTS;
  let DEFAULT_SOUTH_POINT = userData.country_code === "US" ? US_SOUTH_POINTS : userData.country_code === "UK" ? UK_SOUTH_POINTS : IND_SOUTH_POINTS;

  const [centerPoint, setCenterPoint] = useState(userData.country_code === "US" ? US_CENTER_POINTS : userData.country_code === "UK" ? UK_CENTER_POINTS : IND_CENTER_POINTS);
  const [northPoint, setNorthPoint] = useState(userData.country_code === "US" ? US_NORTH_POINTS : userData.country_code === "UK" ? UK_NORTH_POINTS : IND_NORTH_POINTS);
  const [southPoint, setSouthPoint] = useState(userData.country_code === "US" ? US_SOUTH_POINTS : userData.country_code === "UK" ? UK_SOUTH_POINTS : IND_SOUTH_POINTS);

  let zoomLevel = 2.5;

  const [mapLevel, setMapLevel] = useState(0);
  const [mapBreadCrumbList, setMapBreadCrumbList] = useState([userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India"]);
  const [mapZone, setMapZone] = useState(null);
  // const [mapState, setMapState] = useState();
  const [mapStateCode, setMapStateCode] = useState(null);

  // BRAND CARDS
  const [brandsCards, setBrandsCards] = useState([]);
  const [isBrandsCardsLoading, setBrandsCardsIsLoading] = useState(false);
  const [isBrandsCardsLoaded, setBrandsCardsIsLoaded] = useState(false);
  const [brandsCardserror, setBrandsCardsError] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState("all_brands");

  const activeBrand = (brandId) => {
    if (selectedBrand !== brandId) {
      setSelectedBrand(brandId);
      dispatch(setSelectedCategoriesFromBrandsCategoryDropdown([]));
      dispatch(setSelectedBrandsFromBrandsCategoryDropdown([]));
    } else {
      dispatch(setSelectedCategoriesFromBrandsCategoryDropdown([]));
      dispatch(setSelectedBrandsFromBrandsCategoryDropdown([]));
    }
    IAEvent_Platform_Visit("Retail Intelligence", "Sales", brandId);
  };

  // PERFORMANCE METRICS CARDS
  const [performanceMetricsCards, setPerformanceMetricsCards] = useState([]);
  const [isPerformanceMetricsCardsLoading, setPerformanceMetricsCardsIsLoading] = useState(false);
  const [isPerformanceMetricsCardsLoaded, setPerformanceMetricsCardsIsLoaded] = useState(false);
  const [performanceMetricsCardserror, setPerformanceMetricsCardsError] = useState(null);

  // PERFORMANCE TREND 
  const [performanceTrend, setPerformanceTrend] = useState([]);
  const [performanceTrendLabel, setPerformanceTrendLabel] = useState([]);
  const [isPerformanceTrendLoading, setPerformanceTrendIsLoading] = useState(false);
  const [isPerformanceTrendLoaded, setPerformanceTrendIsLoaded] = useState(false);
  const [performanceTrenderror, setPerformanceTrendError] = useState(null);

  // TOP BOTTOM PERFORMING MARKETS
  const [topBottomPerformingMarketsData, setTopBottomPerformingMarketsData] = useState([]);
  const [topBottomPerformingMarketsLabels, setTopBottomPerformingMarketsLables] = useState([]);
  const [isTopBottomPerformingMarketsLoading, setTopBottomPerformingMarketsIsLoading] = useState(false);
  const [isTopBottomPerformingMarketsLoaded, setTopBottomPerformingMarketsIsLoaded] = useState(false);
  const [topBottomPerformingMarketsError, setTopBottomPerformingMarketsError] = useState(null);

  // STATE SUMMARY
  const [stateSummaryTable, setStateSummaryTable] = useState([]);
  const [isStateSummaryTableLoading, setStateSummaryTableIsLoading] = useState(false);
  const [isStateSummaryTableLoaded, setStateSummaryTableIsLoaded] = useState(false);
  const [stateSummaryTableerror, setStateSummaryTableError] = useState(null);

  // STATE TABLE HEADER
  const StateTableHeaders = [
    { name: "State", field: "state_name" },
    // { name: "Zone Name", field: "zone_name" },
    { name: "Sales Volume", field: "sales_volume" },
    { name: "Sales Vol. Change", field: "sales_volume_percent" },
    { name: "Sales Value", field: "sales_value" },
    { name: "Sales Val. Change", field: "sales_value_percent" },
    { name: "Spend", field: "spend" },
    { name: "GRPs", field: "grps" },
    { name: "Market Share", field: "market_share" },
    { name: "Impressions", field: "impression" },
    { name: "CPM", field: "cpm" },
    // { name: "State Code", field: "state_code" },
  ];

  // BRANDWISE PERFORMANCE
  const [brandwisePerformance, setBrandwisePerformance] = useState([]);
  const [isBrandwisePerformanceLoading, setBrandwisePerformanceIsLoading] = useState(false);
  const [isBrandwisePerformanceLoaded, setBrandwisePerformanceIsLoaded] = useState(false);
  const [brandwisePerformanceerror, setBrandwisePerformanceError] = useState(null);


  //   BRANDWISE PERFORMANCE - TREE MAP HANDLER
  let treemapLevel = 1;
  const [treemapBrand, setTreemapBrand] = useState("");
  const [treemapBrandId, setTreemapBrandId] = useState("");
  const [treemapBrandZone, setTreemapBrandZone] = useState("");
  const [treemapParams, setTreemapParams] = useState({
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    zone: mapZone,
    state_code: mapStateCode,
    brand_id: selectedBrandIds.length === 0 ? selectedBrand : selectedBrandIds.join(","),
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === 1) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        level: 2,
        brand_id: data.brand_id,
      });
      setTreemapBrandId(data.brand_id);
      setTreemapBrand(data.name);
    } else if (data.level === 2) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // zone: mapZone,
        state_code: mapStateCode,
        level: 3,
        brand_id: treemapBrandId,
        zone: data.zone_name,
      });
      setTreemapBrandZone(data.zone_name);
    } else {
      alert("No further drill down available");
    }
  };

  //BRAND WISE TOP POSTS COMPARE
  const [brandWisePostCompare, setBrandWisePostCompare] = useState([]);
  const [isBrandWisePostCompareLoading, setBrandWisePostCompareIsLoading] = useState(false);
  const [isBrandWisePostCompareLoaded, setBrandWisePostCompareIsLoaded] = useState(false);
  const [brandWisePostCompareerror, setBrandWisePostCompareError] = useState(null);

  // GEOGRAPHYWISE TOP POSTS COMPARE
  const [geographyWisePostCompare, setGeographyWisePostCompare] = useState([]);
  const [isGeographyWisePostCompareLoading, setGeographyWisePostCompareIsLoading] = useState(false);
  const [isGeographyWisePostCompareLoaded, setGeographyWisePostCompareIsLoaded] = useState(false);
  const [geographyWisePostCompareerror, setGeographyWisePostCompareError] = useState(null);

  const MarketStatesTabOptions = ["Brand Wise", "Geography Wise"];
  const [selectedMarketStateTab, setSelectedMarketStateTab] = useState(MarketStatesTabOptions[0]);
  const [unCheck, setUnCheck] = useState([false]);
  const tabsOnClickHandler = (event) => {
    setSelectedMarketStateTab(event);
    setUnCheck(!unCheck);
  };

  // // MARKET KPI SCORE
  // const [marketScore, setmarketScore] = useState([]);
  // const [isMarketScoreLoading, setmarketScoreIsLoading] = useState(false);
  // const [isMarketScoreLoaded, setmarketScoreIsLoaded] = useState(false);
  // const [marketScoreError, setmarketScoreError] = useState(null);


  // // MARKET KPI SCORE BIFURICATION
  // const [marketKPIScoreBifurication, setMarketKPIScoreBifurication] = useState([]);
  // const [isMarketKPIScoreBifuricationLoading,setMarketKPIScoreBifuricationIsLoading] = useState(false);
  // const [isMarketKPIScoreBifuricationLoaded,setMarketKPIScoreBifuricationIsLoaded] = useState(false);
  // const [marketKPIScoreBifuricationError, setMarketKPIScoreBifuricationError] = useState(null);


  // const [marketScoreDropdownValue, setMarketScoreDropdownValue] = useState({
  //   label: "All Mediums",
  //   value: "all_mediums",
  // });

  // const marketScoreOptions = [
  //   { label: "All Mediums", value: "all_mediums" },
  //   { label: "Digital", value: "digital" },
  //   { label: "TV", value: "tv" },
  // ];

  // const marketScoreDropdownHandler = (e) => {
  //   if (e === null) {
  //     setMarketScoreDropdownValue("");
  //   } else {
  //     setMarketScoreDropdownValue(e);
  //   }
  // };

  // // MARKET SUMMARY
  // const [marketCountryData, setMarketCountryData] = useState([]);
  // const [marketZonesData, setMarketZonesData] = useState([]);
  // const [isMarketDataLoading, setMarketDataIsLoading] = useState(false);
  // const [isMarketDataLoaded, setMarketDataIsLoaded] = useState(false);
  // const [marketDataerror, setMarketDataError] = useState(null);

  // // MARKET TABLE HEADERS
  // const MarketTableHeaders = [
  //   { name: "Zone", field: "market" },
  //   { name: "Market Share", field: "market_share" },
  //   { name: "Sales Volume", field: "sales_volume" },
  //   { name: "Sales Value", field: "sales_value" },
  //   { name: "Spend", field: "spend" },
  //   { name: "GRP", field: "grp" },
  //   { name: "Impressions", field: "impression" },
  //   { name: "CPM", field: "cpm" },
  // ];

  useEffect(() => {
    fetchMapDataHandler();
  }, [
    selectedDates,
    mapZone,
    mapStateCode,
    selectedBrand,
    selectedBrandIds,
    // mapState,
    // mapBreadCrumbList,
  ]);

  useEffect(() => {
    fetchBrandWisePostCompareHandler();
    fetchGeographyWisePostCompareHandler();
  }, [
    selectedDates,
    mapZone,
    mapStateCode,
    selectedBrand,
    selectedBrandIds,
    selectedMarketStateTab,
    // mapState,
    // mapBreadCrumbList,
  ]);

  useEffect(() => {
    fetchBrandsCardsHandler();
  }, [
    selectedDates,
    mapZone,
    mapStateCode,
    // mapState,
    // mapBreadCrumbList
  ]);

  useEffect(() => {
    // fetchMarketScoreHandler();
    // fetchMarketScoreBifuricationHandler();
  }, [
    selectedDates,
    mapZone,
    mapStateCode,
    // mapState,
    // mapBreadCrumbList
  ]);

  useEffect(() => {
    fetchMarketStatesSummaryTableHandler();
    // fetchMarketDataHandler();
  }, [selectedDates, selectedBrand, selectedBrandIds]);

  useEffect(() => {
    fetchPerformanceMetricsCardsHandler();
    fetchPerformanceMarketHandler();
    fetchPerformanceTrendHandler();
    setTreemapParams({
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      zone: mapZone,
      state_code: mapStateCode,
      brand_id:
        selectedBrandIds.length === 0
          ? selectedBrand
          : selectedBrandIds.join(","),
      level: 1,
    });
  }, [
    selectedDates,
    mapZone,
    mapStateCode,
    selectedBrand,
    selectedBrandIds,
    // mapState,
    // mapBreadCrumbList,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // INDIA MAP
  async function handleIndiaMapChange(value) {
    setMapLevel(value[0] + 1);
    setCenterPoint(value[1]);
    setNorthPoint(value[2]);
    setSouthPoint(value[3]);
    setMapZone(value[4]);
    if (value[0] === 1) {
      // setMapState(value[5]);
      setMapStateCode(value[6]);
      setMapBreadCrumbList([userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India", value[4], value[5]]);
    } else {
      setMapBreadCrumbList([userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India", value[4]]);
    }
  }

  // TO FETCH MAP DATA FROM API
  const fetchMapDataHandler = async () => {
    setMapIsLoading(true);
    setIsMapLoaded(false);
    setMapError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/map-data/", config);
      setMapData(res?.data);
      setMapIsLoading(false);
      setIsMapLoaded(true);
      setMapError(null);
    } catch (error) {
      setMapIsLoading(false);
      setIsMapLoaded(false);
      setMapError(error.code);
    }
  };

  let IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isMapLoaded && !isMapLoading) {
    if (NoDataAvailableChecker(MapData)) {
      IndiaMapChart = <Wrapper><NoDataAvailableLoader chartType="indiaChartType" /></Wrapper>;
    } else {
      if (userData.country_code === "US") {
        IndiaMapChart = (
          <Wrapper>
            <ErrorBoundary chartType="indiaChartType">
              <NewUSMap
                showBreadcrumb={true}
                geoJson={MapData}
                northPoint={northPoint}
                southPoint={southPoint}
                centerPoint={centerPoint}
                zoomLevel={zoomLevel}
                mapLevel={mapLevel}
                onChange={handleIndiaMapChange}
                growthDegrowth={"sales"}
                toolTipValues={["sales", "market_share"]}
                toolTipLabels={["Sales Volume", "Market Share"]}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else if (userData.country_code === "UK") {
        IndiaMapChart = (
          <Wrapper>
            <ErrorBoundary chartType="indiaChartType">
              <NewUKMap
                showBreadcrumb={true}
                geoJson={MapData}
                northPoint={northPoint}
                southPoint={southPoint}
                centerPoint={centerPoint}
                zoomLevel={zoomLevel}
                mapLevel={mapLevel}
                onChange={handleIndiaMapChange}
                growthDegrowth={"sales"}
                toolTipValues={["sales", "market_share"]}
                toolTipLabels={["Sales Volume", "Market Share"]}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        IndiaMapChart = (
          <Wrapper>
            <ErrorBoundary chartType="indiaChartType">
              <IndiaMap
                showBreadcrumb={true}
                geoJson={MapData}
                northPoint={northPoint}
                southPoint={southPoint}
                centerPoint={centerPoint}
                zoomLevel={zoomLevel}
                mapLevel={mapLevel}
                onChange={handleIndiaMapChange}
                growthDegrowth={"sales"}
                toolTipValues={["sales", "market_share"]}
                toolTipLabels={["Sales Volume", "Market Share"]}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (MapError) {
    IndiaMapChart = (
      <Wrapper><ServerErrorsLoader chartType="indiaChartType" error={MapError} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMapLoading) {
    IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>;
  }

  function mapBreadcrumbsHandler(index, value) {
    if (index === 0) {
      let a = [userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India"];
      setMapBreadCrumbList(a);
      setMapLevel(0);
      setMapZone(null);
      // setMapState();
      setMapStateCode();
      setCenterPoint(DEFAULT_CENTER_POINT);
      setNorthPoint(DEFAULT_NORTH_POINT);
      setSouthPoint(DEFAULT_SOUTH_POINT);
    }
    if (index === 1) {
      let a = [userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India", value];
      setMapBreadCrumbList(a);
      setMapLevel(1);
      // setMapState();
      setMapStateCode();
    }
  }

  // const [selectedTab, setSelectedTab] = useState(
  //   SolidGaugeSampleData[0].market_title
  // );

  // TO SET PLATFORM ACTIVE ON CLICK
  // const activeTab = (tab) => {
  //   if (selectedTab !== tab) {
  //     setSelectedTab(tab);
  //     setMarketKPIScoreBifurication(
  //       selectedTab === "Sales"
  //         ? SolidGaugeSalesSampleData
  //         : SolidGaugeMarketSampleData
  //     );
  //   }
  // };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // MARKET KPI SCORE

  // const fetchMarketScoreHandler = async () => {
  //   setmarketScoreIsLoading(true);
  //   setmarketScoreIsLoaded(false);
  //   setmarketScoreError(null);

  //   const config = {
  //     params: {
  //       brand_id:
  //         selectedBrandIds.length === 0
  //           ? selectedBrand
  //           : selectedBrandIds.join(","),
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       zone: mapZone,
  //       state_code: mapStateCode,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("performance/summary/ad-metric/", config);

  //     setmarketScore(SolidGaugeSampleData);
  //     setmarketScoreIsLoading(false);
  //     setmarketScoreIsLoaded(true);
  //     setmarketScoreError(null);
  //   } catch (error) {
  //     setmarketScore([]);
  //     setmarketScoreIsLoading(false);
  //     setmarketScoreIsLoaded(false);
  //     setmarketScoreError(error.code);
  //   }
  // };

  // let marketKPIScoreSection = <Loader loaderType="solidGaugeChartLoader" />;

  // // IF DATA IS LOADED
  // if (isMarketScoreLoaded && !isMarketScoreLoading) {
  //   if (NoDataAvailableChecker(marketScore)) {
  //     marketKPIScoreSection = <NoDataAvailableLoader />;
  //   } else {
  //     marketKPIScoreSection = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <div className="default_tab tab">
  //             <div className="title_wrapper">
  //               <div className="score_title">66</div>
  //               <div className="score_subtitle">Market Score</div>
  //             </div>
  //             <div className="section_card_chart_wrapper">
  //               <SolidGauge
  //                 chartClass="section_card_chart"
  //                 chartData={SolidGaugeDataSample}
  //                 chartId="performance market"
  //               />
  //             </div>
  //           </div>
  //           {marketScore.map((data, index) => (
  //             <div
  //               className={
  //                 selectedTab === data.market_title ? "tab active" : "tab"
  //               }
  //               key={index}
  //               onClick={() => activeTab(data.market_title)}
  //             >
  //               <div className="header">
  //                 <div className="score_title">{data.market_score}</div>
  //               </div>
  //               <div className="section_card_chart_wrapper">
  //                 <SolidGauge
  //                   chartClass="section_card_chart"
  //                   chartData={data.data}
  //                   chartId={data.market_title}
  //                 />
  //               </div>
  //               <div className="footer">
  //                 <div className="score_subtitle">{data.market_title}</div>
  //               </div>
  //               <div className="active_arrow"></div>
  //             </div>
  //           ))}
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (marketScoreError) {
  //   marketKPIScoreSection = <ServerErrorsLoader error={marketScoreError} />;
  // }

  // // IF DATA LOADING
  // if (isMarketScoreLoading) {
  //   marketKPIScoreSection = <Loader loaderType="solidGaugeChartLoader" />;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // MARKET KPI SCORE BIFURICATION

  // const fetchMarketScoreBifuricationHandler = async () => {
  //   setMarketKPIScoreBifuricationIsLoading(true);
  //   setMarketKPIScoreBifuricationIsLoaded(false);
  //   setMarketKPIScoreBifuricationError(null);

  //   const config = {
  //     params: {
  //       brand_id:
  //         selectedBrandIds.length === 0
  //           ? selectedBrand
  //           : selectedBrandIds.join(","),
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       zone: mapZone,
  //       state_code: mapStateCode,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("performance/summary/ad-metric/", config);

  //     setMarketKPIScoreBifurication(SolidGaugeSalesSampleData);
  //     setMarketKPIScoreBifuricationIsLoading(false);
  //     setMarketKPIScoreBifuricationIsLoaded(true);
  //     setMarketKPIScoreBifuricationError(null);
  //   } catch (error) {
  //     setMarketKPIScoreBifurication([]);
  //     setMarketKPIScoreBifuricationIsLoading(false);
  //     setMarketKPIScoreBifuricationIsLoaded(false);
  //     setMarketKPIScoreBifuricationError(error.code);
  //   }
  // };

  // let marketKPIScoreBifuricationSection = (
  //   <div className="grid grid_cols_1">
  //     <Loader loaderType="solidGaugeChartLoader" />
  //   </div>
  // );

  // // IF DATA IS LOADED
  // if (
  //   isMarketKPIScoreBifuricationLoaded &&
  //   !isMarketKPIScoreBifuricationLoading
  // ) {
  //   if (NoDataAvailableChecker(marketScore)) {
  //     marketKPIScoreBifuricationSection = (
  //       <div className="grid grid_cols_1">
  //         <NoDataAvailableLoader />
  //       </div>
  //     );
  //   } else {
  //     marketKPIScoreBifuricationSection = (
  //       <Wrapper>
  //         <div className="grid grid_cols_4">
  //           <ErrorBoundary>
  //             {marketKPIScoreBifurication.map((data, index) => (
  //               <div key={index} className="grid col_span_1">
  //                 <div className="ri_sales_chart_tabs_content_wrapper">
  //                   <div className="header">
  //                     <div className="score_title">{data.market_score}</div>
  //                   </div>
  //                   <div className="section_card_chart_wrapper">
  //                     <SolidGauge
  //                       chartClass="section_card_chart"
  //                       chartData={data.data}
  //                       chartId={data.market_title + index}
  //                       chartColorSet={SolidGaugeColorSet1}
  //                     />
  //                   </div>
  //                   <div className="footer">
  //                     <div className="score_subtitle">{data.market_title}</div>
  //                   </div>
  //                 </div>
  //               </div>
  //             ))}
  //           </ErrorBoundary>
  //         </div>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (marketKPIScoreBifuricationError) {
  //   marketKPIScoreBifuricationSection = (
  //     <div className="grid grid_cols_1">
  //       <ServerErrorsLoader error={marketKPIScoreBifuricationError} />;
  //     </div>
  //   );
  // }

  // // IF DATA LOADING
  // if (isMarketKPIScoreBifuricationLoading) {
  //   marketKPIScoreBifuricationSection = (
  //     <div className="grid grid_cols_1">
  //       <Loader loaderType="solidGaugeChartLoader" />
  //     </div>
  //   );
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND CARDS

  const fetchBrandsCardsHandler = async () => {
    setBrandsCardsIsLoading(true);
    setBrandsCardsIsLoaded(false);
    setBrandsCardsError(null);

    const config = {
      params: {
        // brand_id:
        //   selectedBrandIds.length === 0
        //     ? selectedBrand
        //     : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/card/", config);

      setBrandsCards(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setBrandsCardsIsLoading(false);
      setBrandsCardsIsLoaded(true);
      setBrandsCardsError(null);
    } catch (error) {
      setBrandsCards([]);
      setBrandsCardsIsLoading(false);
      setBrandsCardsIsLoaded(false);
      setBrandsCardsError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  let brandCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Brands
          </h2></div>
        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isBrandsCardsLoaded && !isBrandsCardsLoading) {
    if (NoDataAvailableChecker(brandsCards)) {
      brandCardsSection = <Wrapper><NoDataAvailableLoader chartType="cardChartType" /></Wrapper>;
    } else {
      brandCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Brands"}
              sliderWrapperClass={"tab_card_slider"}
            // showSliderHeader={false}
            >
              {brandsCards.map((data, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => activeBrand(data.brand_id)}
                >
                  <Card
                    variant={"compare"}
                    showPreviousCompare={true}
                    cardClass={
                      selectedBrandIds.length === 0
                        ? selectedBrand === data.brand_id
                          ? "tabCard active"
                          : "tabCard"
                        : selectedBrandIds.includes(data.brand_id) === true
                          ? "tabCard active"
                          : "tabCard"
                    }
                    cardIconLink={data.brand_logo}
                    cardTitle={data.cardTitle}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    second_currentValue={data.second_currentValue}
                    second_previousValue={data.second_previousValue}
                    rowOneTitle={data.rowOneTitle}
                    rowTwoTitle={data.rowTwoTitle}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandsCardserror) {
    brandCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Brands
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={brandsCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isBrandsCardsLoading) {
    brandCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Brands
            </h2></div>

          </div>
          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE METRICS CARDS

  const fetchPerformanceMetricsCardsHandler = async () => {
    setPerformanceMetricsCardsIsLoading(true);
    setPerformanceMetricsCardsIsLoaded(false);
    setPerformanceMetricsCardsError(null);

    const config = {
      params: {
        brand_id: selectedBrandIds.length === 0 ? selectedBrand : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/metric-card/", config);

      setPerformanceMetricsCards(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data);
      setPerformanceMetricsCardsIsLoading(false);
      setPerformanceMetricsCardsIsLoaded(true);
      setPerformanceMetricsCardsError(null);
    } catch (error) {
      setPerformanceMetricsCards([]);
      setPerformanceMetricsCardsIsLoading(false);
      setPerformanceMetricsCardsIsLoaded(false);
      setPerformanceMetricsCardsError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  let performanceMetricsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Performance Metrics
          </h2></div>

        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="chartCard" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceMetricsCardsLoaded && !isPerformanceMetricsCardsLoading) {
    if (NoDataAvailableChecker(performanceMetricsCards)) {
      performanceMetricsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Performance Metrics
              </h2></div>

            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      performanceMetricsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Performance Metrics"}
              sliderWrapperClass={"tab_card_slider"}
            >
              {performanceMetricsCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="sales"
                    platform="sales_overall"
                    section="sales_overall"
                    variant={"chartCard"}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    chartType={"Line"}
                    chartData={data.subData?.data}
                    chartDataLabels={data.subData?.dimension}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceMetricsCardserror) {
    performanceMetricsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Performance Metrics
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={performanceMetricsCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceMetricsCardsLoading) {
    performanceMetricsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Performance Metrics
            </h2></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" variantType="chartCard" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE TREND

  let defaultperformanceDisabledvalues = [];

  if (companyID === COKE_COMPANY_ID) {
    defaultperformanceDisabledvalues = []
  } else {
    defaultperformanceDisabledvalues = []
  }

  const [isPerformanceOpen, setIsPerformanceOpen] = useState(false)
  const [performanceDisabledvalues, setPerformanceDisabledvalues] = useState(defaultperformanceDisabledvalues)

  const fetchPerformanceTrendHandler = async () => {
    setPerformanceTrendIsLoading(true);
    setPerformanceTrendIsLoaded(false);
    setPerformanceTrendError(null);

    const config = {
      params: {
        brand_id: selectedBrandIds.length === 0 ? selectedBrand : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // video_duration_type: videoDurationType,
        // video_id: videoIdentity,
        // hashtag: selectedHashtag,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/trend-line/", config);

      setPerformanceTrend(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setPerformanceTrendLabel(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );
      setPerformanceTrendIsLoading(false);
      setPerformanceTrendIsLoaded(true);
      setPerformanceTrendError(null);
    } catch (error) {
      setPerformanceTrend([]);
      setPerformanceTrendLabel([]);
      setPerformanceTrendIsLoading(false);
      setPerformanceTrendIsLoaded(false);
      setPerformanceTrendError(error.code);
    }
  };

  let performanceTrendSection = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
  let performanceTrendSection2 = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isPerformanceTrendLoaded && !isPerformanceTrendLoading) {
    if (NoDataAvailableChecker(performanceTrend) || NoDataAvailableChecker(performanceTrendLabel)) {
      performanceTrendSection = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
      performanceTrendSection2 = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);

    } else {

      if (isMultiLineCluster) {

        performanceTrendSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={performanceTrend}
                chartId="performanceTrend"
                chartLabels={performanceTrendLabel}
                showLegend={true}
                disabledLegendArray={performanceDisabledvalues}
                setDisabledValues={setPerformanceDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );


        performanceTrendSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={performanceTrend}
                chartId="performanceTrend_2"
                chartLabels={performanceTrendLabel}
                showLegend={true}
                disabledLegendArray={performanceDisabledvalues}
                setDisabledValues={setPerformanceDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        performanceTrendSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <MultiAxisMultiLine
                chartClass="section_card_chart"
                chartData={performanceTrend}
                chartId="performanceTrend"
                chartLabels={performanceTrendLabel}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceTrenderror) {
    performanceTrendSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceTrenderror}
        />
      </Wrapper>
    );

    performanceTrendSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceTrenderror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceTrendLoading) {
    performanceTrendSection = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
    performanceTrendSection2 = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);

  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP BOTTOM PERFORMING MARKETS

  const fetchPerformanceMarketHandler = async () => {
    setTopBottomPerformingMarketsIsLoading(true);
    setTopBottomPerformingMarketsIsLoaded(false);
    setTopBottomPerformingMarketsError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/top-bottom-markets/", config);

      if (userData.country_code === "US" || userData.country_code === "UK") {
        res.data.data.forEach((data) => {
          delete data.state_code
        })
      }

      setTopBottomPerformingMarketsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setTopBottomPerformingMarketsLables(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
            res.data.dimension
      );

      setTopBottomPerformingMarketsIsLoading(false);
      setTopBottomPerformingMarketsIsLoaded(true);
      setTopBottomPerformingMarketsError(null);
    } catch (error) {
      setTopBottomPerformingMarketsData([]);
      setTopBottomPerformingMarketsLables([]);
      setTopBottomPerformingMarketsIsLoading(false);
      setTopBottomPerformingMarketsIsLoaded(false);
      setTopBottomPerformingMarketsError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  let topBottomPerfMarketSection = <Wrapper><Loader loaderType="bubbleChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isTopBottomPerformingMarketsLoaded && !isTopBottomPerformingMarketsLoading) {
    if (NoDataAvailableChecker(topBottomPerformingMarketsData) || NoDataAvailableChecker(topBottomPerformingMarketsLabels)) {
      topBottomPerfMarketSection = (
        <Wrapper><NoDataAvailableLoader chartType="bubbleChartType" /></Wrapper>
      );
    } else {
      topBottomPerfMarketSection = (
        <Wrapper>
          <ErrorBoundary chartType="bubbleChartType">
            <BubbleChartSizeShapeDynamic
              chartId="zoomablechart"
              chartClass="section_card_chart"
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
              chartData={topBottomPerformingMarketsData}
              chartLabels={topBottomPerformingMarketsLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topBottomPerformingMarketsError) {
    topBottomPerfMarketSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="bubbleChartType"
          error={topBottomPerformingMarketsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopBottomPerformingMarketsLoading) {
    topBottomPerfMarketSection = <Wrapper><Loader loaderType="bubbleChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   MARKETS SUMMARY

  const fetchMarketStatesSummaryTableHandler = async () => {
    setStateSummaryTableIsLoading(true);
    setStateSummaryTableIsLoaded(false);
    setStateSummaryTableError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/markets-data/", config);

      // setStateSummaryTableMarketsData(
      //   res.data.length === 0
      //     ? []
      //     : res.data.markets.length === 0
      //       ? []
      //       : res.data.markets
      // );

      if (userData.country_code === "US" || userData.country_code === "UK") {
        res.data.states.forEach((data) => {
          delete data.state_code
        })
      }

      setStateSummaryTable(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.states === undefined || res.data.states.length === 0 ? [] :
            res.data.states
      );

      setStateSummaryTableIsLoading(false);
      setStateSummaryTableIsLoaded(true);
      setStateSummaryTableError(null);
    } catch (error) {
      // setStateSummaryTableMarketsData([]);
      setStateSummaryTable([]);
      setStateSummaryTableIsLoading(false);
      setStateSummaryTableIsLoaded(false);
      setStateSummaryTableError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING

  // let marketsSummaryTableSection = <Loader loaderType="smallTableLoader" />;
  let statesSummaryTableSection = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="sales"
                platform="overall"
                section="overall"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>);

  // IF DATA IS LOADED
  if (isStateSummaryTableLoaded && !isStateSummaryTableLoading) {
    // if (NoDataAvailableChecker(stateSummaryTableMarketsData) || NoDataAvailableChecker(MarketTableHeaders)) {
    //   marketsSummaryTableSection = <NoDataAvailableLoader />
    // } else {
    //   marketsSummaryTableSection = (
    //     <Wrapper>
    //       <ErrorBoundary>
    //         <Datatable
    //           tableHeader={MarketTableHeaders}
    //           tableData={stateSummaryTableMarketsData}
    //           tableLength={10}
    //           isFooterShow={true}
    //           tableDropdown={false}
    //           searchPlaceHolder={"Search"}
    //         />
    //       </ErrorBoundary>
    //     </Wrapper>
    //   );
    // }

    if (NoDataAvailableChecker(stateSummaryTable) || NoDataAvailableChecker(StateTableHeaders)) {
      statesSummaryTableSection = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Summary
                  <SectionDefinationsBadge
                    title={"Summary"}
                    module="sales"
                    platform="overall"
                    section="overall"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>);
    } else {
      statesSummaryTableSection = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={"Summary"}
              tablePageSubModule="sales"
              tablePagePlatform="overall"
              tablePagePrimaryTab="overall"
              tableHeader={StateTableHeaders}
              tableData={stateSummaryTable}
              tableLength={10}
              isFooterShow={true}
              tableDropdown={false}
              searchPlaceHolder={"Search States"}
              tableID="state_table"
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"state_table"}
                  sectionName="State Summary"
                  sectionData={stateSummaryTable}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateSummaryTableerror) {
    // marketsSummaryTableSection = (
    // //   <Wrapper><ServerErrorsLoader error={stateSummaryTableerror} /></Wrapper>
    // );
    statesSummaryTableSection = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module="sales"
                  platform="overall"
                  section="overall"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader error={stateSummaryTableerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateSummaryTableLoading) {
    // marketsSummaryTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;
    statesSummaryTableSection = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module="sales"
                  platform="overall"
                  section="overall"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>);
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   BRANDWISE PERFORMANCE

  const fetchBrandwisePerformanceHandler = async () => {
    setBrandwisePerformanceIsLoading(true);
    setBrandwisePerformanceIsLoaded(false);
    setBrandwisePerformanceError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/tree-map/", config);

      setBrandwisePerformance(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setBrandwisePerformanceIsLoading(false);
      setBrandwisePerformanceIsLoaded(true);
      setBrandwisePerformanceError(null);
    } catch (error) {
      setBrandwisePerformance([]);
      setBrandwisePerformanceIsLoading(false);
      setBrandwisePerformanceIsLoaded(false);
      setBrandwisePerformanceError(error.code);
    }
  };

  let brandwisePerformanceSection = (
    <Wrapper>
      <div className="section_card_chart_wrapper flex column">
        <Loader loaderType="treeChartLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isBrandwisePerformanceLoaded && !isBrandwisePerformanceLoading) {
    if (NoDataAvailableChecker(brandwisePerformance) || (brandwisePerformance.length === 1 && brandwisePerformance[0].value === 0)) {
      brandwisePerformanceSection = (
        <Wrapper>
          <div className="section_card_chart_wrapper flex column">
            <NoDataAvailableLoader chartType="treeChartType" />
          </div>
        </Wrapper>
      );
    } else {
      if (treemapLevel > 3) {
        brandwisePerformanceSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <NoDataAvailableLoader chartType="treeChartType" />
            </div>
          </Wrapper>
        );
      } else {
        brandwisePerformanceSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <ErrorBoundary chartType="treeChartType">
                <SimpleTreeMap
                  chartId="simpleTreemaptest"
                  chartClass="section_card_chart"
                  chartData={brandwisePerformance}
                  levelHandler={chartlevelHandler}
                  colorChangeParameter={"growth_percentage"}
                  chartLabels={[
                    {
                      name: "name",
                      value: "value",
                      toolTipvalue1: "share_of_spends",
                      toolTipvalue1Label: "Share of Spends",
                      toolTipvalue2: "share_of_voice",
                      toolTipvalue2Label: "Share of Voice",
                      toolTipvalue3: "growth_percentage",
                      toolTipvalue3Label: "Sales Vol. Change",
                    },
                  ]}
                />
              </ErrorBoundary>
            </div>
            {isBrandwisePerformanceLoaded &&
              !isBrandwisePerformanceLoading &&
              Object.keys(brandwisePerformance).length !== 0 && (
                <div className="w_100">
                  <ul className="icz-rilist-inline">
                    <li>
                      <div className="legend-circle circleGreen"></div> Growth
                    </li>
                    <li>
                      <div className="legend-circle circleRed"></div> Degrowth
                    </li>
                  </ul>
                </div>
              )}
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandwisePerformanceerror) {
    brandwisePerformanceSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <ServerErrorsLoader
            chartType="treeChartType"
            error={brandwisePerformanceerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isBrandwisePerformanceLoading) {
    brandwisePerformanceSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <Loader loaderType="treeChartLoader" />
        </div>
      </Wrapper>
    );
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchBrandwisePerformanceHandler();
  }, [treemapParams]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND WISE TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchBrandWisePostCompareHandler = async () => {
    setBrandWisePostCompareIsLoading(true);
    setBrandWisePostCompareIsLoaded(false);
    setBrandWisePostCompareError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        // market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/compare-brands/", config);
      setBrandWisePostCompare(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setBrandWisePostCompareIsLoading(false);
      setBrandWisePostCompareIsLoaded(true);
      setBrandWisePostCompareError(null);
    } catch (error) {
      setBrandWisePostCompare([]);
      setBrandWisePostCompareIsLoading(false);
      setBrandWisePostCompareIsLoaded(false);
      setBrandWisePostCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let brandWisePostCompareFeat = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header"></div>
      <div className="grid col_span_4">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  let brandWisePostCompareHeaderTitle = "Post";

  // IF DATA IS LOADED
  if (isBrandWisePostCompareLoaded && !isBrandWisePostCompareLoading) {
    if (NoDataAvailableChecker(brandWisePostCompare)) {
      brandWisePostCompareFeat = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header"></div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      brandWisePostCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <CompareSalesBrands
              products={brandWisePostCompare}
              isSales={true}
              Title={brandWisePostCompareHeaderTitle}
              tabClear={unCheck}
              salesBrand={true}
              compare_platform="compare-trend-line"
              hasExportDropdown={true}
              mapZone={mapZone}
              mapStateCode={mapStateCode}
              compareType="SalesCompareBrand"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandWisePostCompareerror) {
    brandWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={brandWisePostCompareerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isBrandWisePostCompareLoading) {
    brandWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchGeographyWisePostCompareHandler = async () => {
    setGeographyWisePostCompareIsLoading(true);
    setGeographyWisePostCompareIsLoaded(false);
    setGeographyWisePostCompareError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        // market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/compare-states/", config);

      setGeographyWisePostCompare(res?.data);
      setGeographyWisePostCompareIsLoading(false);
      setGeographyWisePostCompareIsLoaded(true);
      setGeographyWisePostCompareError(null);
    } catch (error) {
      setGeographyWisePostCompare([]);
      setGeographyWisePostCompareIsLoading(false);
      setGeographyWisePostCompareIsLoaded(false);
      setGeographyWisePostCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let geographyWisePostCompareFeat = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header"></div>
      <div className="grid col_span_4">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  let geographyWisePostCompareHeaderTitle = "Post";

  // IF DATA IS LOADED
  if (isGeographyWisePostCompareLoaded && !isGeographyWisePostCompareLoading) {
    if (NoDataAvailableChecker(geographyWisePostCompare)) {
      geographyWisePostCompareFeat = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header"></div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      geographyWisePostCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <CompareSalesBrands
              products={geographyWisePostCompare}
              isSales={true}
              Title={geographyWisePostCompareHeaderTitle}
              tabClear={unCheck}
              salesBrand={false}
              compare_platform="compare-states-trend-line"
              hasExportDropdown={true}
              mapZone={mapZone}
              mapStateCode={mapStateCode}
              compareType="SalesCompareGeo"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (geographyWisePostCompareerror) {
    geographyWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={geographyWisePostCompareerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isGeographyWisePostCompareLoading) {
    geographyWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }





  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // MARKET LINE CHART DATATABLE

  // const fetchMarketDataHandler = async () => {
  //   setMarketDataIsLoading(true);
  //   setMarketDataIsLoaded(false);
  //   setMarketDataError(null);

  //   const config = {
  //     params: {
  //       brand_id:
  //         selectedBrandIds.length === 0
  //           ? selectedBrand
  //           : selectedBrandIds.join(","),
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       zone: mapZone,
  //       state_code: mapStateCode,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("ri/sales/markets/", config);

  //     setMarketCountryData(
  //       res.data.length === 0
  //         ? []
  //         : res.data.India.length === 0
  //           ? []
  //           : res.data.India
  //     );
  //     setMarketZonesData(
  //       res.data.length === 0
  //         ? []
  //         : res.data.zones.length === 0
  //           ? []
  //           : res.data.zones
  //     );
  //     setMarketDataIsLoading(false);
  //     setMarketDataIsLoaded(true);
  //     setMarketDataError(null);
  //   } catch (error) {
  //     setMarketCountryData([]);
  //     setMarketZonesData([]);
  //     setMarketDataIsLoading(false);
  //     setMarketDataIsLoaded(false);
  //     setMarketDataError(error.code);
  //   }
  // };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  // let marketCountryDataSection = (
  //   <Wrapper>
  //     <div className="grid grid_cols_1 grid_margin_bottom">
  //       <div className="grid col_span_4 section_header">
  //         <div className="section_title">Market</div>
  //       </div>
  //       <div className="grid col_span_4 grid_margin_bottom">
  //         <Loader />
  //       </div>
  //     </div>
  //   </Wrapper>
  // );

  // IF DATA IS LOADED
  // if (isMarketDataLoaded && !isMarketDataLoading) {
  //   if (
  //     NoDataAvailableChecker(marketCountryData) ||
  //     NoDataAvailableChecker(marketCountryData[0])
  //   ) {
  //     marketCountryDataSection = (
  //       <Wrapper>
  //         <div className="grid grid_cols_1 grid_margin_bottom">
  //           <div className="grid col_span_4 section_header">
  //             <div className="section_title">Market</div>
  //           </div>
  //           <div className="grid col_span_4 grid_margin_bottom">
  //             <NoDataAvailableLoader chartType="cardChartType" />
  //           </div>
  //         </div>
  //       </Wrapper>
  //     );
  //   } else {
  //     marketCountryDataSection = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <Slider
  //             sliderTitle={"Markets"}
  //             showNavigation={false}
  //             slidestoshow={3}
  //             breakpoints={{
  //               0: {
  //                 slidesPerView: "auto",
  //               },
  //               426: {
  //                 slidesPerView: 1,
  //               },
  //               768: {
  //                 slidesPerView: 1,
  //               },
  //               1300: {
  //                 slidesPerView: 1,
  //               },
  //               2100: {
  //                 slidesPerView: 2,
  //               },
  //             }}
  //           >
  //             <SwiperSlide>
  //               {marketCountryData.map((data, index) => (
  //                 <div key={index} className="grid grid_cols_1">
  //                   <div className="card_wrapper">
  //                     <div className="section_card market_card">
  //                       <div className="section_card_header">
  //                         <div className="section_card_title">All India</div>
  //                       </div>
  //                       <div className="section_card_body">
  //                         <div className="section_card_chart_wrapper">
  //                           <ErrorBoundary>
  //                             <LineChart
  //                               chartClass="section_card_chart"
  //                               chartData={data.chart.data}
  //                               chartLabels={data.chart.dimension}
  //                               chartId={"storybookChart1" + Math.random()}
  //                               showLegend={true}
  //                               showLabels={true}
  //                             />
  //                           </ErrorBoundary>
  //                         </div>
  //                         <div className="table_wrapper">
  //                           <table className="table table_bordered">
  //                             <tbody>
  //                               {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                               <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       {data.metric[0].value}
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[0].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[1].value}
  //                                         title={data.metric[1].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[1].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr>
  //                               <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[2].value}
  //                                         title={data.metric[2].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[2].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[3].value}
  //                                         title={data.metric[3].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[3].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr>
  //                               <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[5].value}
  //                                         title={data.metric[5].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       {data.metric[5].label}
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[7].value}
  //                                         title={data.metric[7].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[7].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr>
  //                               {/* <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <TextFormatter string={data.metric[4].value} />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter string={data.metric[4].label} />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <TextFormatter string={data.metric[6].value} />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter string={data.metric[6].label} />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr> */}
  //                             </tbody>
  //                           </table>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ))}
  //             </SwiperSlide>
  //           </Slider>
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // IF THERE IS SOME ERROR FROM SERVER
  // if (marketDataerror) {
  //   marketCountryDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title">Market</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <ServerErrorsLoader
  //             chartType="cardChartType"
  //             error={marketDataerror}
  //           />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // IF DATA LOADING
  // if (isMarketDataLoading) {
  //   marketCountryDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title">Market</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <Loader />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // SALES METRICS CARDS SLIDER DATA HANDLING
  // let marketZonesDataSection = (
  //   <div className="grid grid_cols_1 grid_margin_bottom">
  //     <div className="grid col_span_4 section_header">
  //       <div className="section_title visibility_hidden">Zones</div>
  //     </div>
  //     <div className="grid col_span_4 grid_margin_bottom">
  //       <Loader />
  //     </div>
  //   </div>
  // );

  // IF DATA IS LOADED
  // if (isMarketDataLoaded && !isMarketDataLoading) {
  //   if (NoDataAvailableChecker(marketZonesData)) {
  //     marketZonesDataSection = (
  //       <Wrapper>
  //         <div className="grid grid_cols_1 grid_margin_bottom">
  //           <div className="grid col_span_4 section_header">
  //             <div className="section_title visibility_hidden">Zones</div>
  //           </div>
  //           <div className="grid col_span_4 grid_margin_bottom">
  //             <NoDataAvailableLoader chartType="cardChartType" />
  //           </div>
  //         </div>
  //       </Wrapper>
  //     );
  //   } else {
  //     marketZonesDataSection = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <Slider
  //             sliderTitle={""}
  //             slidestoshow={3}
  //             breakpoints={{
  //               0: {
  //                 slidesPerView: "auto",
  //               },
  //               426: {
  //                 slidesPerView: 1,
  //               },
  //               768: {
  //                 slidesPerView: 1,
  //               },
  //               1300: {
  //                 slidesPerView: 1,
  //               },
  //               2100: {
  //                 slidesPerView: 2,
  //               },
  //             }}
  //           >
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">East</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.EAST.chart.data}
  //                             chartLabels={marketZonesData.EAST.chart.dimension}
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.EAST.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[4].label} /></span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">South</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.SOUTH.chart.data}
  //                             chartLabels={
  //                               marketZonesData.SOUTH.chart.dimension
  //                             }
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.SOUTH.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[4].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">West</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.WEST.chart.data}
  //                             chartLabels={marketZonesData.WEST.chart.dimension}
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.WEST.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[4].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">North</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.NORTH.chart.data}
  //                             chartLabels={
  //                               marketZonesData.NORTH.chart.dimension
  //                             }
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.NORTH.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[4].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //           </Slider>
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // IF THERE IS SOME ERROR FROM SERVER
  // if (marketDataerror) {
  //   marketZonesDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title visibility_hidden">Zones</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <ServerErrorsLoader
  //             chartType="cardChartType"
  //             error={marketDataerror}
  //           />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // IF DATA LOADING
  // if (isMarketDataLoading) {
  //   marketZonesDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title visibility_hidden">Zones</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <Loader />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  var allData = {
    Summary: exportSummary,
    "SalesIndiaChart_Map": MapData.features ? MapData.features : [],
    "TVcards_Brands ": brandsCards,
    "PieofPie_Performance Metrics": performanceMetricsCards,
    "Performance Trend": performanceTrend,
    "TopPerfMarket_Top - Bottom Performing Markets":
      topBottomPerformingMarketsData,
    "Summary Table": stateSummaryTable,
    "SalesTreemap_Brandwise Performance": brandwisePerformance,
    "SalesCompareBrand_Brand Wise Compare": brandWisePostCompare,
    "SalesCompareGeo_Geography Wise Compare": geographyWisePostCompare,
  };

  useEffect(() => {
    setExportPageName("Sales");
    setFullPageExport(allData);
  }, [
    MapData,
    brandsCards,
    performanceMetricsCards,
    performanceTrend,
    topBottomPerformingMarketsData,
    stateSummaryTable,
    brandwisePerformance,
    brandWisePostCompare,
    geographyWisePostCompare,
  ]);

  useEffect(() => {
    if (
      isMapLoaded &&
      isBrandsCardsLoaded &&
      isPerformanceMetricsCardsLoaded &&
      isPerformanceTrendLoaded &&
      isTopBottomPerformingMarketsLoaded &&
      isStateSummaryTableLoaded &&
      isBrandwisePerformanceLoaded &&
      isBrandWisePostCompareLoaded &&
      isGeographyWisePostCompareLoaded
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    isMapLoaded,
    isBrandsCardsLoaded,
    isPerformanceMetricsCardsLoaded,
    isPerformanceTrendLoaded,
    isTopBottomPerformingMarketsLoaded,
    isStateSummaryTableLoaded,
    isBrandwisePerformanceLoaded,
    isBrandWisePostCompareLoaded,
    isGeographyWisePostCompareLoaded
  ])

  useEffect(() => {
    setexportSummary([
      selectedDates,
      {
        "Brand ID": selectedBrandIds.join(","),
        "Brand Categories": selectedCategories.join(","),
      },
    ]);
  }, [selectedDates, selectedBrandIds]);

  return (
    <Wrapper>
      <div className="grid drawer_grid">
        {/* INDIA MAP */}
        <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
          <div id="indiaMap" className="section_card">
            <div className="mapHeaderWrapper w_100">
              <div className="grid col_span_4">

                <div className="section_card_header mapHeader">
                  <div className="section_info">
                    <h2 className="section_card_title card_map_title">
                      {userData.country_code === "US" ? "US" : userData.country_code === "UK" ? "UK" : "India"}
                    </h2>
                    <SectionDefinationsBadge
                      title={"Map"}
                      module="sales"
                      platform="overall"
                      section="overall"
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <ExportDropdown
                      moduleName={pageModule}
                      subModuleName={pageSubModule}

                      sectionId="indiaMap"
                      sectionName="SalesIndiaChart_Map"
                      sectionData={MapData.features}
                    />
                  </div>
                </div>



              </div>
              <div className="grid col_span_4">
                <div className="section_card_header map_breadcrumb_header">
                  {mapBreadCrumbList.map((item, index) => (
                    <div
                      onClick={() => {
                        mapBreadcrumbsHandler(index, item);
                      }}
                      key={index}
                    >
                      {index === 0 ? " " : "/ "}
                      <TextFormatter string={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="section_card_body mapBoxContainer">
              <div className="grid grid_cols_4 grid_margin_bottom">
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_map_wrapper">
                      {IndiaMapChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mapBoxContainer">
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle icz-rilist-bullet1"></div>{" "}
                    North
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet2"></div>{" "}
                    West
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet3"></div>{" "}
                    South
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet4"></div>{" "}
                    East
                  </li>
                </ul>
              </div>
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle circleGreen"></div> Growth
                  </li>
                  <li>
                    <div className="legend-circle circleRed"></div> Degrowth
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="right_side col_span_3 relative-right-grid">
          <Wrapper>
            <div className="content">
              <div className="content_section">
                {/* PAGE FILTER DROPDOWN SELECTED VALUES */}
                {selectedBrandIds.length !== 0 && (
                  <div className="header_badges_wrapper">
                    <div className="badges_list_wrapper">
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedCategories.length} Categories Selected
                        </div>
                      </div>
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedBrandIds.length} Brands Selected
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <PageHeader pageTitle={"Market Performance"} />
                <div className="page_content">
                  <div className="dashboard_home">
                    {/* MARKET KPI SCORE */}
                    {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card" id="market-kpi-score-section">
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            Market KPI Score
                            {/* : {marketScoreDropdownValue.label} //
                          </h2>
                          <div className="filters_wrapper">
                            {/* <Dropdown
                              ismulti={false}
                              placeholder={"Select"}
                              options={marketScoreOptions}
                              className="section_dropdown filter"
                              value={marketScoreDropdownValue}
                              selectedOptions={marketScoreDropdownValue}
                              setStatedropdown={marketScoreDropdownHandler}
                            /> //
                            <div className="filter export_filter">
                              <ExportDropdown
                                sectionId="market-kpi-score-section"
                                sectionName="Market KPI Score"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body ri_sales_chart_tabs_header_wrapper">
                          {marketKPIScoreSection}
                        </div>
                      </div>
                    </div> */}

                    {/* MARKET KPI SCORE BIFURICATION */}
                    {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                      {marketKPIScoreBifuricationSection}
                    </div> */}

                    {/* BRANDSBrands */}
                    <div className="grid_margin_bottom">
                      {brandCardsSection}
                    </div>

                    {/* PERFORMANCE METRICS */}
                    <div className="grid_margin_bottom">
                      {performanceMetricsSection}
                    </div>

                    {/* MARKETS */}
                    {/* <div className="grid grid_cols_2">
                      {marketCountryDataSection}
                      {marketZonesDataSection}
                    </div> */}

                    <div
                      id="performance_trend"
                      className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                    >
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                          <div className="section_info">
                            <h2 className="section_card_title">
                              Performance Trend
                            </h2>
                            <SectionDefinationsBadge
                              title={"Performance Trend"}
                              module="sales"
                              platform="overall"
                              section="overall"
                            />
                            {section1}
                            {/* <SectionInsights query={"Performance Trend"} /> */}
                          </div>

                          <Modal
                            open={isPerformanceOpen}
                            setOpen={setIsPerformanceOpen}
                            exportDropdown={
                              <ExportDropdown
                                moduleName={pageModule}
                                subModuleName={pageSubModule}
                                sectionId="performanceTrend_2"
                                sectionName="Performance Trend"
                                sectionData={performanceTrend}
                              />}
                            parentId={"performance_trend"}
                            title={`Performance Trend`}
                            chart={performanceTrendSection2}
                          />
                          <div data-html2canvas-ignore={true}>
                            {isMultiLineCluster && <button onClick={() => setIsPerformanceOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                              <ExpandIcon />
                            </button>}
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}
                              sectionId="performance_trend"
                              sectionName="Performance Trend"
                              sectionData={performanceTrend}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper">
                            {performanceTrendSection}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="Bottom-performance-market"
                      className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                    >
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                          <div className="section_info">
                            <h2 className="section_card_title">
                              Top / Bottom Performing Markets
                            </h2>
                            <SectionDefinationsBadge
                              title={"Top / Bottom Performing Markets"}
                              module="sales"
                              platform="overall"
                              section="overall"
                            />
                            {section2}
                            {/* <SectionInsights query={"Top / Bottom Performing Markets"} /> */}
                          </div>
                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}

                              sectionId="Bottom-performance-market"
                              sectionName="TopPerfMarket_Top - Bottom Performing Markets"
                              sectionData={topBottomPerformingMarketsData}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper flex column">
                            {topBottomPerfMarketSection}
                          </div>
                          {isTopBottomPerformingMarketsLoaded &&
                            !isTopBottomPerformingMarketsLoading &&
                            Object.keys(topBottomPerformingMarketsData)
                              .length !== 0 && (
                              <div className="w_100">
                                <ul className="icz-rilist-inline">
                                  <li>
                                    <div className="legend-circle circleGreen"></div>{" "}
                                    Growth
                                  </li>
                                  <li>
                                    <div className="legend-circle circleRed"></div>{" "}
                                    Degrowth
                                  </li>
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                        <div className="section_info">     <h2 className="section_card_title">
                            Summary
                             </h2> <SectionDefinationsBadge
                              title={"Summary"}
                              module="sales"
                              platform="overall"
                              section="overall"
                            />
                        </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body"> */}
                    <div className="grid_margin_bottom">
                      {statesSummaryTableSection}
                    </div>
                    {/* </div>
                      </div>
                    </div> */}

                    <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                      <div className="grid col_span_4">
                        <div className="section_card_header p_b_0 ">
                          <div className="flex column align_start">
                            <div className="section_info">
                              <h2 className="section_card_title">
                                Brandwise Performance
                              </h2>
                              <SectionDefinationsBadge
                                title={"Brandwise Performance"}
                                module="sales"
                                platform="overall"
                                section="overall"
                              />
                              {section4}
                              {/* <SectionInsights query={"Brandwise Performance"} /> */}
                            </div>
                            {Object.keys(brandwisePerformance).length !== 0 && (
                              <div className="treemap_breadcrumb">
                                <span
                                  onClick={() => {
                                    setTreemapParams({
                                      start_date: selectedDates.startDate,
                                      end_date: selectedDates.endDate,
                                      previous_start_date:
                                        selectedDates.prevStartDate,
                                      previous_end_date:
                                        selectedDates.prevEndDate,
                                      zone: mapZone,
                                      state_code: mapStateCode,
                                      brand_id: "all_brands",
                                      level: 1,
                                    });
                                    setTreemapBrand("");
                                    setTreemapBrandZone("");
                                  }}
                                >
                                  Home
                                </span>
                                {treemapBrand && (
                                  <span
                                    onClick={() => {
                                      setTreemapParams({
                                        start_date: selectedDates.startDate,
                                        end_date: selectedDates.endDate,
                                        previous_start_date:
                                          selectedDates.prevStartDate,
                                        previous_end_date:
                                          selectedDates.prevEndDate,
                                        zone: mapZone,
                                        state_code: mapStateCode,
                                        brand_id: treemapBrandId,
                                        level: 2,
                                      });
                                      setTreemapBrandZone("");
                                    }}
                                  >
                                    {" "}
                                    / <TextFormatter string={treemapBrand} />
                                  </span>
                                )}

                                {treemapBrandZone && (
                                  <span>
                                    {" "}
                                    /{" "}
                                    <TextFormatter
                                      string={treemapBrandZone}
                                    />{" "}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>

                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}

                              sectionId={"brandwise_performance_section"}
                              sectionName={"SalesTreemap_Brandwise Performance"}
                              sectionData={brandwisePerformance}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body">
                          {brandwisePerformanceSection}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_margin_bottom">
                      <Tab
                        varient={"primary"}
                        tabData={MarketStatesTabOptions}
                        activeTab={selectedMarketStateTab}
                        handleCallback={(event) => tabsOnClickHandler(event)}
                      />
                    </div>

                    {selectedMarketStateTab === "Brand Wise" ? (
                      <Wrapper>
                        <div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
                          <div className="section_card_body">
                            {brandWisePostCompareFeat}
                          </div>
                        </div>
                      </Wrapper>
                    ) : (
                      <Wrapper>
                        <div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
                          <div className="section_card_body">
                            {geographyWisePostCompareFeat}
                          </div>
                        </div>
                      </Wrapper>
                    )}

                    {/* {brandMinMaxDataDates !== undefined &&
                      <Wrapper>
                        <div className="page_footer">
                          Sales data available from {" " + formatDateDisplay(minDate) + " "} to  {" " + formatDateDisplay(maxDate)}
                        </div>
                      </Wrapper>
                    } */}


                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </Wrapper>
  );
};
export default RiSales;
