import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class LayeredColumnChart extends Component {
    componentDidMount() {
        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let titleX = info['Xtitle']
        let titleY = info['Ytitle']

        // let card_name = info['name'];
        let chart_id = info['chart_id'];
        let data = info['graph-data'];
        let bar1_color = info['bar1_color'];
        let bar2_color = info['bar2_color'];

        
        let chart = am4core.create(chart_id, am4charts.XYChart);
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS
        chart.data = data;

        chart.colors.list = [
            am4core.color(bar1_color),
            am4core.color(bar2_color)
        ];

        // Add percent sign to all numbers
chart.numberFormatter.numberFormat = "#.#'%'";

// Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "category";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 30;
categoryAxis.title.text = titleX;
categoryAxis.renderer.baseGrid.disabled = true;
categoryAxis.renderer.grid.template.strokeWidth = 0;
categoryAxis.renderer.grid.template.disabled = true;
categoryAxis.renderer.labels.template.fill = am4core.color("#757575");
categoryAxis.renderer.labels.template.fontSize = 10;
categoryAxis.tooltip.disabled = true; // for disabling to the y axis tooltip
categoryAxis.renderer.labels.template.rotation = -45;
categoryAxis.renderer.labels.template.horizontalCenter = "right";
categoryAxis.renderer.labels.template.verticalCenter = "middle";

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.title.text = titleY;
valueAxis.renderer.minGridDistance = 20;
valueAxis.renderer.labels.template.fill = am4core.color("#757575");
valueAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
valueAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
valueAxis.renderer.labels.template.fontSize = 12;
valueAxis.tooltip.disabled = true; // for disabling to the y axis tooltip


// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueY = "value";
series.dataFields.categoryX = "category";
series.name = title1;
series.clustered = false;
series.columns.template.width = am4core.percent(40);

let series2 = chart.series.push(new am4charts.ColumnSeries());
series2.dataFields.valueY = "value2";
series2.dataFields.categoryX = "category";
series2.name = title2;
series2.clustered = false;
series2.columns.template.width = am4core.percent(20);


var tooltipHTML =
`
<div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
  <div class="text-left d-flex flex-column justify-start" style="width: 150px; padding:10px">
  <span style="font-size: 14px; color: #fff; font-weight: 400; margin-bottom:5px">{category}</span>
    <span style="font-size: 13px; color: #fff; font-weight: 400">{value}</span>
    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">{series.name}</span>
    <hr style="margin: 4px 0"/>
    <span style="font-size: 13px; color: #fff; font-weight: 400">{value2}</span>
    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">{series2.name}</span>
  </div>
</div>
`;

// Tooltip 
series.tooltip.getFillFromObject = false;
series.tooltip.background.fill = am4core.color("#213345");
series.tooltip.background.stroke = am4core.color("#213345");
series.tooltip.background.cornerRadius = 5;
series.tooltip.label.minWidth = 150;
series.tooltip.label.padding(0, 0, 0, 0);
series.tooltip.label.fontSize = 12;
series.tooltip.position = "pointer";
// series.tooltip.pointerOrientation = "vertical";
series.tooltipHTML = tooltipHTML;
var shadow = series.tooltip.background.filters.getIndex(0);
shadow.dx = 3;
shadow.dy = 10;
shadow.blur = 10;
shadow.color = am4core.color("#14567829");

chart.cursor = new am4charts.XYCursor();
chart.cursor.lineX.disabled = true;
chart.cursor.lineY.disabled = true;

// LEGEND
chart.legend = new am4charts.Legend();
chart.legend.useDefaultMarker = true;
chart.legend.labels.template.text = "{name}";
chart.legend.labels.template.fill = "#213345";
chart.legend.fill = "rgba(112, 112, 112, 0.1)";
chart.legend.opacity = 1;
var markerTemplate = chart.legend.markers.template;
let marker = chart.legend.markers.template.children.getIndex(0);
marker.cornerRadius(12, 12, 12, 12);
markerTemplate.width = 13;
markerTemplate.height = 13;


}

  render() {
    let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
            // <div id="chartdiv"></div>
        )
    }
}
