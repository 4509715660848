import React, { Component } from 'react';
import { Carousel } from '../../../shared/components/carousel/carousel';
import { StoredDtailsCard } from '../../../shared/components/cards/ristoredetailscard/ristoredetailscard';

export default class Top5Movers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Top5Movers: this.props.Top5Movers,
    }
  }
  render() {
    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    }

    const SliderCards = [];
    for (let i = 0; i < this.state.Top5Movers.length; i++) {

      SliderCards.push(<StoredDtailsCard
        index={i + 1}
        CardClass="icz-ristorecardwrapper"
        CardIcon={i + 1}
        CardTitle={this.state.Top5Movers[i].city}
        CardRowTitle={this.state.Top5Movers[i].site_desc}
        CardRowSubTitle={this.state.Top5Movers[i].store_code}
        CardScoreCurrent={this.state.Top5Movers[i].current_score}
        CardScoreText="Current Score"
        CardScorePrevious={this.state.Top5Movers[i].last_period_score}
        CardScoreText2="Previous Score"
      />)
    }

    return (
      <div className="icz-carouselcontainer">
        <Carousel
          Settings={CarouselSettings}
          class={"icz-cardcontainer"}
          SliderCards={SliderCards}>
        </Carousel>

      </div>
    );
  }
}
