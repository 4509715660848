import React, { useState } from 'react';
import Wrapper from '../components/helpers/Wrapper';
import Button from '../components/ui/Button';
import { isRichText } from './Utils';

const TextTrimmer = ({ text, onViewMoreClickHandler }) => {

    const keyTakeawaysMatch = text.match(/<p><strong>Key Takeaways?:<\/strong><\/p>(.*?)<p><strong>Actionable Points:<\/strong>/s) ||
        text.match(/<p><strong>Key Takeaways?:<\/strong>(.*?)<\/p>/s);
    const keyTakeawayText = keyTakeawaysMatch ? keyTakeawaysMatch[1].trim() : '';

    let htmlContent = ""

    if (keyTakeawayText.length > 200) {
        htmlContent = `${keyTakeawayText.slice(0, 200)}... <a class="text_btn" onclick="${onViewMoreClickHandler}">view more</a>`;
    } else {
        htmlContent = `${keyTakeawayText} <a class="text_btn" onclick="${onViewMoreClickHandler}">view more</a>`;
    }

    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Wrapper >
    );
};

export default TextTrimmer;
