const IconNeutral = (props) => {
    return (

        <svg className={props.className} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_0_1)">
                <path
                    d="M14.435 1.816C13.735 1.816 13.075 1.816 12.416 1.816C12.2967 1.82027 12.1778 1.80101 12.066 1.75932C11.9542 1.71763 11.8517 1.65433 11.7644 1.57303C11.588 1.40885 11.4841 1.18132 11.4755 0.9405C11.4669 0.699683 11.5543 0.465306 11.7184 0.288928C11.8826 0.11255 12.1102 0.00861954 12.351 0C13.791 0 15.233 0 16.677 0C16.9028 0.00554568 17.1179 0.0975259 17.2779 0.256977C17.4379 0.416429 17.5306 0.631196 17.537 0.857C17.5416 2.299 17.5416 3.741 17.537 5.183C17.5237 5.41388 17.4226 5.63094 17.2544 5.78969C17.0863 5.94845 16.8637 6.03689 16.6325 6.03689C16.4012 6.03689 16.1787 5.94845 16.0105 5.78969C15.8424 5.63094 15.7413 5.41388 15.728 5.183C15.722 4.553 15.728 3.924 15.728 3.294C15.7309 3.23242 15.716 3.1713 15.685 3.118C15.639 3.173 15.598 3.233 15.547 3.284C13.743 5.08933 11.939 6.89467 10.135 8.7C10.0478 8.81962 9.93367 8.91696 9.80177 8.98407C9.66987 9.05117 9.52397 9.08615 9.37598 9.08615C9.22799 9.08615 9.08209 9.05117 8.95019 8.98407C8.81828 8.91696 8.70411 8.81962 8.61698 8.7C8.01098 8.083 7.39398 7.475 6.78898 6.86C6.68898 6.76 6.63298 6.752 6.52798 6.86C4.90665 8.48667 3.28265 10.1127 1.65598 11.738C1.53854 11.8828 1.38127 11.9901 1.20357 12.0467C1.02587 12.1032 0.835506 12.1064 0.655978 12.056C0.513203 12.0155 0.382667 11.9403 0.275997 11.8371C0.169327 11.7339 0.0898331 11.6059 0.0445953 11.4645C-0.000642359 11.3232 -0.0102198 11.1728 0.016716 11.0269C0.0436518 10.8809 0.106265 10.7439 0.198978 10.628C0.254783 10.5599 0.314553 10.4951 0.377978 10.434L5.91098 4.9C5.99802 4.78234 6.11143 4.68672 6.24212 4.62084C6.37281 4.55495 6.51712 4.52063 6.66348 4.52063C6.80983 4.52063 6.95415 4.55495 7.08484 4.62084C7.21553 4.68672 7.32894 4.78234 7.41598 4.9C8.03465 5.51867 8.65298 6.138 9.27098 6.758C9.34198 6.829 9.38298 6.868 9.47698 6.774C11.117 5.128 12.76 3.485 14.437 1.808"
                    fill="#7D9EB5" />
                <path
                    d="M8.77499 15.719H0.916991C0.713488 15.7195 0.515743 15.6515 0.355552 15.526C0.19536 15.4004 0.0820321 15.2247 0.0337894 15.027C-0.0144533 14.8293 0.00519302 14.6211 0.0895693 14.4359C0.173946 14.2507 0.318149 14.0993 0.498991 14.006C0.622127 13.9404 0.759482 13.9061 0.898991 13.906H16.652C16.8923 13.9081 17.1219 14.0056 17.2903 14.177C17.4587 14.3484 17.5521 14.5797 17.55 14.82C17.5479 15.0603 17.4504 15.2899 17.279 15.4583C17.1076 15.6267 16.8763 15.7201 16.636 15.718C14.0167 15.718 11.3977 15.718 8.77899 15.718"
                    fill="#7D9EB5" />
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="17.541" height="15.721" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IconNeutral;