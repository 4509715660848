import React, { Component } from 'react';
import '../card.scss';

export class SalesCard extends Component {
    render() {
        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-risalescard">
                    <div className="icz-risalescardheader">
                        <div className="icz-risalescardtitlewrapper">
                            <h3 className="icz-risalescardtitle">{this.props.CardTitle}</h3>
                        </div>
                    </div>
                    <div className="icz-risalescardbody">
                        {this.props.chart}
                        {this.props.legends}
                    </div>
                </div>
            </div>
        );
    }
}
