import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class RadarChart extends Component {
    componentDidMount() {

        let info = this.props;
        let titleX = info['Xtitle']
        let titleY = info['Ytitle']

        let chart_id = info['chart_id'];
        let data = info['graph-data'];

        /* Create chart instance */
        let chart = am4core.create(chart_id, am4charts.RadarChart);

        chart.data = data;
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 11;//TO CHANGE FONT SIZE OF CHART LABELS

        /* Create axes */
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        categoryAxis.renderer.labels.template.bent = true;
        categoryAxis.renderer.labels.template.fontSize = 10;
        categoryAxis.title.text = titleX;
        chart.exporting.menu = new am4core.ExportMenu();
        
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.axisFills.template.fill = chart.colors.getIndex(2);
        valueAxis.renderer.axisFills.template.fillOpacity = 0.05;
        valueAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.minGridDistance = 20;
        valueAxis.title.text = titleY;
    
        /* Create and configure series */
        let series = chart.series.push(new am4charts.RadarSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "category";
        series.name = "Sales";
        series.strokeWidth = 2;
        series.fillOpacity = 0.2;
        series.tooltipText = "{valueY}";
        
    }

    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
        )
    }
}
