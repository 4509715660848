import React from "react";
import * as XLSX from "xlsx";
import { changeKeyName, convertHtmlToText, formatChartData, getUniqueKeys } from "../../utils/Utils";

const ExportToExcelButton = ({ data, filename }) => {

    const exportToExcel = () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        let title = data.title;
        let type = data.type;
        let chartData = formatChartData(data.model_data.chart_data, data.model_data.chart_data.chart_type);
        let formattedChartData = changeKeyName(chartData.data, "label", data.model_data.chart_data.x_axis_label);
        let tableData = data.model_data.table_data.data.results;
        let analysis = convertHtmlToText(data.model_data.rich_text);


        // Flatten nested objects
        const flattenChartData = formattedChartData.map((item) => flattenObject(item));
        const flattenTableData = tableData.map((item) => flattenObject(item));

        // Create array to hold all the rows for the sheet
        const sheetData = [];

        // Add title as a single-row array
        sheetData.push([`Title`]);
        sheetData.push([`${title}`]);

        // Add an empty row (to add separation in the sheet)
        sheetData.push([""]);

        // Add type as a single-row array
        sheetData.push([`Type`]);
        sheetData.push([`${type}`]);

        // Add an empty row
        sheetData.push([""]);

        // Add analysis as a single-row array
        sheetData.push(["Analysis"]);
        sheetData.push([`${analysis}`]);

        // Add an empty row
        sheetData.push([""]);

        // Add chart data with a label
        sheetData.push(["Chart Data"]);
        // Flattened chart data will be added row by row
        flattenChartData.forEach(row => sheetData.push(Object.values(row)));

        // Add an empty row
        sheetData.push([""]);

        // Add table data with a label
        sheetData.push(["Table Data"]);
        // Flattened table data will be added row by row
        flattenTableData.forEach(row => sheetData.push(Object.values(row)));

        // Create worksheet from sheetData
        const ws = XLSX.utils.aoa_to_sheet(sheetData);

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "data");

        // Write buffer
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: fileType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename + fileExtension;
        a.click();
    };

    // Function to flatten nested objects recursively
    const flattenObject = (obj, prefix = "") => {
        return Object.keys(obj).reduce((acc, key) => {
            const propKey = prefix ? `${prefix}.${key}` : key;
            if (typeof obj[key] === "object" && obj[key] !== null) {
                return { ...acc, ...flattenObject(obj[key], propKey) };
            } else {
                return { ...acc, [propKey]: obj[key] };
            }
        }, {});
    };

    return <button className="tab exportToExcel" onClick={exportToExcel}>Export to Excel</button>;
};

export default ExportToExcelButton;
