import './alerts.scss';

const Alert = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="alert-overlay">
      <div className="alert-box">
        <p className="alert-message">{message}</p>
        <div className="alert-buttons">
          <button className="alert-button cancel" onClick={onCancel}>Cancel</button>
          <button className="alert-button confirm" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default Alert;
