import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import LoginPage from "../../pages/LoginPage";
import RegistrationPage from "../../pages/RegistrationPage";

import Wrapper from "./Wrapper";

import AuthContext from "../../store/authContext";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import Campaign from "../../pages/mi/campaign/Campaign";
import Social from "../../pages/mi/social/Social";
import Hashtag from "../../pages/mi/Hashtag";
import Video from "../../pages/mi/video/Video";
import Website from "../../pages/mi/website/Website";
import Compare from "../../pages/mi/compare/Compare";
import DashboardTest from "../../pages/DashboardTest";
import Influencers from "../../pages/mi/Influencers";

import TV from "../../pages/ri/TV";
// import Sales from "../../pages/ri/sales/Sales";
import RiSales from "../../pages/ri/Sales";
import CampaignAdmin from "../../pages/admin/campaign/CampaignAdmin";
import CreateBucket from "../../pages/admin/campaign/bucketLists/CreateBucket";
import RISummary from "../../retailApp/app/modules/ri/ri-summary/RiSummary";
import RIBusinessOverview from "../../retailApp/app/modules/ri/ri-businessoverview/RiBusiness";
import SellThroughOverview from "../../retailApp/app/modules/ri/ri-sellthrough/RiSellThroughOverview";
import StoreHealthOverview from "../../retailApp/app/modules/ri/ri-storehealth/RiStoreHealthOverview";
import CustomerOverview from "../../retailApp/app/modules/ri/ri-customer/RiCustomerOverview";
import BusinessOverview from "../../retailApp/app/modules/ri/ri-businessoverview/RiBusinessOverview";
import SalesSummaryOverview from "../../retailApp/app/modules/ri/ri-salessummary/RiSalesSummaryOverview";

import Ecom from "../../retailApp/app/modules/ecom/ecom";
import Customer from "../../retailApp/app/modules/ecom/customer/Customer";
import Engagement from "../../retailApp/app/modules/ecom/Engagement/Engagement";
import Traffic from "../../retailApp/app/modules/ecom/Traffic/Traffic";
import Earned from "../../retailApp/app/modules/ecom/Earned/Earned";
import Paid from "../../retailApp/app/modules/ecom/Paid/Paid";
import Sales from "../../retailApp/app/modules/ecom/Sales/Sales";
import RealTime from "../../retailApp/app/modules/ecom/RealTime/RealTime";
import { useSelector } from "react-redux";
import { COKE_COMPANY_ID, DEMO_FMCG_USER_ID, DEMO_UAE_USER_ID, DEMO_UK_USER_ID, DEMO_US_USER_ID, HALEON_COMPANY_ID, IDFC_COMPANY_ID } from "../../constants/constants";
// import TVHaleon from "../../pages/ri/TVHaleon";
import Orm from "../../pages/mi/socialListening/orm";
import RiCustomerFilter from "../../retailApp/app/modules/ri/ri-customerfilter/RiCustomerFilter";
import CustomerFilterMobile from "../../retailApp/app/modules/ri/ri-customerfilter/CustomerFilterMobile";
import RiStoreScore from "../../retailApp/app/modules/ri/ri-storescore/RiStoreScore";
import CokeSummary from "../../pages/CokeSummary";

import Market from "../../pages/ri/Market";
import TVHaleon from "../../pages/ri/TVHaleon";
import MarketIDFC from "../../pages/ri/MarketIDFC";
import USCokeSummary from "../../pages/US-CokeSummary";
import AskIcogz from "../../pages/aryabot/askIcogz";



export default function RouteUrls() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  // PERSISTS STATES
  let store = useSelector((state) => state);

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let companyID = userData?.company?.id ?? null;

  // SIDEBAR DATA
  const sidebarData = useSelector((state) => state.sidebar.sidebarData);

  // TO CHECK WHICH MODULE IS DEFAULT
  let defaultModule = sidebarData[0]?.code ?? ''

  if (
    store.campaignAdmin === undefined ||
    store.campaignModuleFilter === undefined ||
    store.date === undefined ||
    store.globalSelectedBrandIds === undefined ||
    store.riSalesBrandsCategory === undefined ||
    store.sidebar === undefined ||
    store.tvBrandsCamapaigns === undefined ||
    store.userData === undefined
  ) {
    authCtx.isLoggedIn = false
  }


  // SHOW SUMMARY SCREEN OR NOT
  const [showSummary, setShowSummary] = useState(false)

  // SHOW SUMMARY SCREEN FOR FOLLOWING COUNTRY LOGINS: US / UK / SG
  // SHOW SUMMARY SCREEN FOR FOLLOWING CATEGORIES: FMCG
  useEffect(() => {
    if (userData.country_code === "US" || userData.country_code === "UK" || userData.country_code === "SG") {
      setShowSummary(true)
    } else {
      if (userData?.company?.id === "0d5ecfb8-3c81-4701-965c-e2cc3d77ccb6") {
        setShowSummary(true)
      } else {
        setShowSummary(false)
      }
    }
  }, [userData])


  return (
    <Wrapper>
      <Routes>
        <Route path={"/login"} element={authCtx.isLoggedIn !== true ? <LoginPage /> : <Navigate to="/" />} />

        <Route path={"/register"} element={authCtx.isLoggedIn !== true ? (<RegistrationPage />) : (<Navigate to="/" />)} />

        <Route path={"/forgot-password"} element={authCtx.isLoggedIn !== true ? (<ForgotPasswordPage />) : (<Navigate to="/" />)} />

        <Route path="/" element={
          authCtx.isLoggedIn === true ? (
            defaultModule.toLowerCase() === "home" ? (
              showSummary ? <CokeSummary /> : <Dashboard />
            ) : (
              defaultModule.toLowerCase() === "mi" ? <Navigate to="/mi" replace state={{ from: location }} /> :
                defaultModule.toLowerCase() === "ri" ? <Navigate to="/ri" replace state={{ from: location }} /> :
                  <Dashboard />
            )
          ) : (
            <Navigate to="/login" />
          )
        } />

        <Route path={"/mi"} >
          <Route path={"/mi"} exact index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/mi/campaigns" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />

          <Route path={"campaigns"} index={true} element={authCtx.isLoggedIn === true ? (<Campaign />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"social"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)}>
            <Route path={":platform"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          </Route>

          <Route path={"video"} index={true} element={authCtx.isLoggedIn === true ? (<Video />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"hashtag"} index={true} element={authCtx.isLoggedIn === true ? (<Hashtag />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"compare"} index={true} element={authCtx.isLoggedIn === true ? (<Compare />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"website"} index={true} element={authCtx.isLoggedIn === true ? (<Website />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"influencers"} index={true} element={authCtx.isLoggedIn === true ? (<Influencers />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          {/* <Route path={"tv"} index={true} element={authCtx.isLoggedIn === true ? companyID === HALEON_COMPANY_ID ? <TVHaleon /> : <TV /> : <Navigate to="/login" />} /> */}
          <Route path={"tv"} index={true} element={authCtx.isLoggedIn === true ? <TV /> : <Navigate to="/login" />} />

          <Route path={"social-listening"} index={true} element={authCtx.isLoggedIn === true ? <Orm /> : <Navigate to="/login" />} />
        </Route>

        {/* <Route path={"roi"} >
          <Route path={"roi"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/roi/tv" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          <Route path={"tv"} index={true} element={authCtx.isLoggedIn === true ? <TV /> : <Navigate to="/login" />} />
          <Route path={"sales"} index={true} element={authCtx.isLoggedIn === true ? <RiSales /> : <Navigate to="/login" />} />
    
          <Route path={"market"} index={true} element={authCtx.isLoggedIn === true ? companyID === IDFC_COMPANY_ID ? <MarketIDFC /> : <Market /> : <Navigate to="/login" />} />
    
          {companyID === IDFC_COMPANY_ID || companyID === HALEON_COMPANY_ID ?
            <Route path={"/roi"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/roi/market" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
            :
            <Route path={"/roi"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/roi/sales" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          }
        </Route> */}

        <Route path={"/ri"} >
          {/* {(companyID === HALEON_COMPANY_ID || companyID === IDFC_COMPANY_ID) ?
            <Route path={"/ri"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/ri/market" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
            :
          } */}
          <Route path={"/ri"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/ri/sales" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          <Route path={"market"} index={true} element={authCtx.isLoggedIn === true ? <Market /> : <Navigate to="/login" />} />
          <Route path={"sales"} index={true} element={authCtx.isLoggedIn === true ? <RiSales /> : <Navigate to="/login" replace state={{ from: location }} />} />
        </Route>

        <Route path={"/roi"} >
          <Route path={"/roi"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/roi/summary" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          <Route path={"/roi/summary"} index={true} element={authCtx.isLoggedIn === true ? (<RISummary />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/roi/sales-summary"} index={true} element={authCtx.isLoggedIn === true ? (<SalesSummaryOverview />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/roi/business-overview"} index={true} element={authCtx.isLoggedIn === true ? (<BusinessOverview />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/roi/sellthrough"} index={true} element={authCtx.isLoggedIn === true ? (<SellThroughOverview />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/roi/storehealth"} index={true} element={authCtx.isLoggedIn === true ? (<StoreHealthOverview />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/roi/customer"} index={true} element={authCtx.isLoggedIn === true ? (<CustomerOverview />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"targetgroup"} index={true} element={authCtx.isLoggedIn === true ? (<RiCustomerFilter />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"branchscore"} index={true} element={authCtx.isLoggedIn === true ? (<RiStoreScore />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
        </Route>

        <Route path={"/ecom"} >
          <Route path={"/ecom"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/ecom/summary" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          <Route path={"/ecom/summary"} index={true} element={authCtx.isLoggedIn === true ? (<Ecom />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/ecom/customer"} index={true} element={authCtx.isLoggedIn === true ? (<Customer />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/ecom/engagement"} index={true} element={authCtx.isLoggedIn === true ? (<Engagement />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/ecom/traffic"} index={true} element={authCtx.isLoggedIn === true ? (<Traffic />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/ecom/earned"} index={true} element={authCtx.isLoggedIn === true ? (<Earned />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/ecom/paid"} index={true} element={authCtx.isLoggedIn === true ? (<Paid />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/ecom/sales"} index={true} element={authCtx.isLoggedIn === true ? (<Sales />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          <Route path={"/ecom/realtime"} index={true} element={authCtx.isLoggedIn === true ? (<RealTime />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
        </Route>

        {/*   <Route path={"/sl"} >
          <Route path={"/sl"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/sl/orm" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          <Route path={"/sl/orm"} index={true} element={authCtx.isLoggedIn === true ? <Orm /> : <Navigate to="/login" replace state={{ from: location }} />} />

          </Route> */}



        <Route path={"/admin"} >
          <Route path={"/admin"} index={true} element={authCtx.isLoggedIn === true ? (<Navigate to="/admin/campaign" replace state={{ from: location }} />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign"} index={true} element={authCtx.isLoggedIn === true ? (<CampaignAdmin />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/create-bucket"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/edit-bucket/:bucketId"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
        </Route>

        <Route path={"/ask-icogz"} element={authCtx.isLoggedIn === true ? (<AskIcogz />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        <Route path={"/test"} element={authCtx.isLoggedIn === true ? (<DashboardTest />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        {/* <Route path={"/dev"} element={authCtx.isLoggedIn === true ? 'Build date: ' + getBuildDate(packageJson.buildDate) : (<Navigate to="/login" replace state={{ from: location }} />)} /> */}

        <Route path="*" element={<Navigate to="/" replace state={{ from: location }} />} />

      </Routes>
    </Wrapper >
  );
}
