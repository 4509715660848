export const API_URL_1 = 'https://v3.demo-stagingapi.icogz.com';
export const API_URL = 'https://v3.demo-stagingapi.icogz.com';

export const isMap = "india";
// export const CountryOrigin = "india";

export const LOADERTEXT = "Adding Intelligence To Your Data!";

export const PAGEGROWTH = [{ "id": 1, "value": "Likes" }, { "id": 2, "value": "Followers" }];

export const CURRENT_DATE_RANGE_FROM_DATE = '2021-10-01';
export const CURRENT_DATE_RANGE_TO_DATE = '2021-12-31';

export const PAGESIZE = 5;


export const CountryOrigin = "in";







