import { useLocation } from "react-router-dom";
import Wrapper from "../components/helpers/Wrapper";
import InsightsChatWrapper from "../components/insightsChat/insightsChatWrapper";
import CombinedBarLineChart from "../components/ui/charts/CombinedBarLineChart";
import { useLayoutEffect } from "react";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { formatChartData } from "../utils/Utils";
import ScatterChart from "../components/ui/charts/ScatterChart";
import LineChart from "../components/ui/charts/LineChart";
am5.addLicense("AM5C258896422");

const DashboardTest = () => {

	let labels = [
		{
			"label": "Sales Volume",
			"value": "sales_volume"
		},
		{
			"label": "Digital Impressions",
			"value": "digital_impressions"
		}
	]

	let data = [
		{
			"sales_volume": 0,
			"digital_impressions": 1153590,
			"outlier": "false",
			"label": "2"
		},
		{
			"sales_volume": 2087836.1,
			"digital_impressions": 44777085,
			"outlier": "false",
			"label": "3"
		},
		{
			"sales_volume": 2638991.6,
			"digital_impressions": 76814606,
			"outlier": "true",
			"label": "4"
		},
		{
			"sales_volume": 3024207.6,
			"digital_impressions": 35975041,
			"outlier": "false",
			"label": "5"
		},
		{
			"sales_volume": 3365345.1,
			"digital_impressions": 112782115,
			"outlier": "false",
			"label": "6"
		}
	]

	return (
		<Wrapper>
			<div className="content">
				<div className="content_section">
					<div className="page_content" style={{ "padding": 0 }}>
						<div className="dashboard_home" style={{ display: "flex", justifyContent: "center" }}>
							<div className="grid grid_cols_1 single-chart-wrapper" style={{ width: "100%", height: "350px" }}>
								<LineChart
									chartId={"12a1s2da1da2da1d23s1d3"}
									chartData={data}
									chartLabels={labels}
									chartClass="chart"
									showLegend={true}
									showLabels={true}
									showBullets={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper >
	)
}
export default DashboardTest;

