import Wrapper from "../helpers/Wrapper"
import Chat from "./chat";

const InsightsChatWrapper = ({ state }) => {

    return (
        <Wrapper>
            <Chat state={state} />
        </Wrapper>
    )
}
export default InsightsChatWrapper;