import Wrapper from "../../components/helpers/Wrapper";

const ComparisonIcon = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.744 16.7548H1.24519V15.6279L6.53726 10.9584L10.0861 14.5072C10.261 14.6812 10.4977 14.7788 10.7445 14.7788C10.9912 14.7788 11.2279 14.6812 11.4029 14.5072L18.3666 7.72716L18.0553 7.41586C18.0553 7.41586 17.8454 7.16134 17.744 7.10456L17.4327 6.79326L10.7398 13.15L7.20033 9.60118C7.02535 9.42724 6.54193 9.32961 6.54193 9.32961C6.54193 9.32961 6.05851 9.42724 5.88354 9.60118L1.24519 13.6076V8.15675L6.53726 3.48728L9.15217 6.10218C9.32714 6.27612 9.56384 6.37375 9.81056 6.37375C10.0573 6.37375 10.294 6.27612 10.469 6.10218L14.9423 1.62883L16.9875 3.67406L17.744 3.05769C17.744 3.05769 17.744 3.05769 18.0553 2.74639C17.8794 2.57177 15.5867 0.27157 15.5867 0.27157C15.5867 0.27157 15.2064 -1.71273e-07 14.9283 0C14.6502 1.71273e-07 14.4449 0.0976309 14.2699 0.27157L9.78721 4.75426L7.18165 2.13936C7.18165 2.13936 6.80139 1.86779 6.52325 1.86779C6.24512 1.86779 6.03983 1.96542 5.86486 2.13936L1.24519 6.13643V0.256005L0.933894 0.256005C0.933894 0.256005 0.744166 0.256005 0.622596 0.256005C0.501027 0.256005 0.432868 0.256005 0.311298 0.256005C0.189729 0.256005 0.311298 0.256005 0 0.256005C0 0.503689 0 17.0661 0 17.0661C0 17.3138 0.0983921 17.5513 0.273531 17.7265C0.44867 17.9016 0.68621 18 0.933894 18H17.744C17.744 17.6887 17.744 17.8103 17.744 17.6887C17.744 17.5671 17.744 17.499 17.744 17.3774C17.744 17.2558 17.744 17.1877 17.744 17.0661C17.744 16.9445 17.744 16.7548 17.744 16.7548Z" fill="#5A75F9" />
            </svg>
        </Wrapper>
    )
}

export default ComparisonIcon;
