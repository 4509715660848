import PropTypes from "prop-types";
import Wrapper from "../helpers/Wrapper";
import ExportIcon from "../../assets/icons/ExportIcon";
import { useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ExportToExcelButton from "./exportToExcel";

const ChatMessageExportDropdown = ({
    sectionId,
    sectionData,
    sectionName
}) => {
    const catMenu = useRef(null);

    function NameReducer(name) {
        if (name.includes("_")) {
            return name.split("_")[1];
        } else {
            return name;
        }
    }

    // DROPDOWN VISIBILITY STATES
    const [showDropdownOptions, setShowDropdownOptions] = useState(false);

    const closeOpenMenus = (e) => {
        if (
            catMenu.current &&
            showDropdownOptions &&
            !catMenu.current.contains(e.target)
        ) {
            setShowDropdownOptions(false);
        }
    };
    document.addEventListener("mousedown", closeOpenMenus);

    // TO SHOW AND HIDE DROPDOWN ON CLICK
    const openExportDropdown = () => {
        setShowDropdownOptions(!showDropdownOptions);
    };

    const handleImageDownload = async (type) => {
        // let exportSectionName = sectionName;
        let className = "bgTransparent";
        let background = null;

        if (type === "png") {
            className = "bgBorderTransparent";
            background = null;
        } else if (type === "jpg") {
            className = "bgTransparent";
            background = "#ffffff";
        } else {
            className = "bgBorderTransparent";
            background = "#ffffff";
        }


        var getId = document.getElementById(sectionId)
        // var hasHeight = !getId.className.includes("table table_striped table_bordered") ? 0 : getId.getElementsByTagName("img").length === 0 ? 50 : 75;

        var hasHeight = (window.scrollY * 0.025)
        const element = document.getElementById(sectionId),
            canvas = await html2canvas(element, {
                backgroundColor: background,
                scrollX: -window.scrollX,
                scrollY: -window.scrollY + hasHeight,
                windowWidth: document.documentElement.offsetWidth,
                windowHeight: document.documentElement.offsetHeight,
                onclone: function (clone) {
                    clone.getElementById(sectionId).classList.add(className);
                },
                height: document.getElementById(sectionId).clientHeight,
                useCORS: true,
                allowTaint: true,
            }),
            data = type === "png" ? canvas.toDataURL("image/png", 1) : canvas.toDataURL("image/jpg", 1),
            link = document.createElement("a");




        // TO GET THE NAME OF THE SECTION / PAGE
        // let exportSectionName = element.querySelector(".page_header h1").textContent;
        let exportSectionName = NameReducer(sectionName);

        // TO EXPORT AS PDF
        if (type === "pdf") {
            const componentWidth = element.offsetWidth;
            const componentHeight = element.offsetHeight;

            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                orientation: "p",
                unit: "px",
            });

            pdf.internal.pageSize.width = componentWidth;
            pdf.internal.pageSize.height = componentHeight;

            pdf.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
            pdf.save(NameReducer(sectionName) + ".pdf");
        } else {

            link.href = data;
            if (type === "png") {
                let filename = exportSectionName + ".png";
                link.download = filename;
            } else if (type === "jpg") {
                let filename = exportSectionName + ".jpg";
                link.download = filename;
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        setShowDropdownOptions(false);
    };

    return (
        <Wrapper>
            <div className="export_dropdown_wrapper" ref={catMenu}>
                <div
                    onClick={() => openExportDropdown()}
                    className={
                        showDropdownOptions
                            ? "export_dropdown_btn_wrapper active"
                            : "export_dropdown_btn_wrapper"
                    }
                >
                    <ExportIcon className={"export_dropdown_btn_icon"} />
                </div>
                {showDropdownOptions && (
                    <div className="options_wrapper">
                        <div className="tab">
                            <ExportToExcelButton data={sectionData} filename={sectionName} />
                        </div>
                        <div onClick={() => handleImageDownload("png")} className="tab">
                            Download PNG
                        </div>
                        <div onClick={() => handleImageDownload("jpg")} className="tab">
                            Download JPG
                        </div>
                        <div onClick={() => handleImageDownload("pdf")} className="tab">
                            Download PDF
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
};

ChatMessageExportDropdown.propTypes = {
    sectionId: PropTypes.string,
    sectionName: PropTypes.string,
};

export default ChatMessageExportDropdown;
