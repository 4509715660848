import { NavLink } from "react-router-dom";
import Wrapper from "../../helpers/Wrapper";

const Submenu = ({ subModules, sidebarToggleHandler }) => {

  const submodulesToggleHandler = (item) => {
    if (window.innerWidth < 1599) {
      sidebarToggleHandler(false);
    }
  };

  return (
    <Wrapper>
      {subModules.map((item, index) => (
        <NavLink
          key={index}
          onClick={() => submodulesToggleHandler(item)}
          className={({ isActive }) =>
            isActive
              ? "sub_nav_link active"
              : "sub_nav_link"
          }
          to={item.subModuleLink}
        >
          <div className="sub_nav_link_icon_wrapper">{item.subModuleIcon}</div>
          <div className="sub_nav_link_title">{item.subModuleName}</div>
        </NavLink>
      ))}
    </Wrapper>
  );
};

export default Submenu;
