import React, { Component } from "react"

import '../card.scss';
import Image from 'react-bootstrap/esm/Image';
import PercentMetricChange from "../../percentmetricchange/percentmetricchange";
import { NumberFormatter } from "../../../utilities/numberformatter";
import Wrapper from "../../../../helpers/wrapper";


export class ValueCard extends Component {
    render() {

        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-card" style={{"height": "252.78px"}}>
                    <div className="icz-cardheader">
                        <div className="icz-iconwrapper">
                            {this.props.CardIcon}
                        </div>
                        <div className="icz-titlewrapper">
                            <h3 className="icz-title">{this.props.CardTitle}</h3>
                        </div>
                    </div>
                    <div className="icz-cardbody d-flex align-items-center" style={{'height': "197.8px"}}>
                        <div className="icz-detailsrow">
                            <div className="icz-valuesection d-flex flex-column">
                                <div className="icz-currentvalue">
                                    <NumberFormatter Number={this.props.RowOneCurrentValue} IsNumber={this.props.IsNumber} />
                                </div>
                                <div className="icz-previousvalue">
                                    {this.props.RowOneCurrentValueUnit}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
