import Wrapper from "../helpers/Wrapper"

const InsightsImg = ({ imgData }) => {
    return (
        <Wrapper>
            <div className="imgWrapper">
                <img src={imgData} />
            </div>
        </Wrapper>
    )
}
export default InsightsImg;