import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class StackedColumnChart extends Component {
    componentDidMount() {
        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let title3 = info['metric_3']
        let title4 = info['metric_4']
        let titleX = info['Xtitle']
        let titleY = info['Ytitle']

        // let card_name = info['name'];
        let chart_id = info['chart_id'];
        let data = info['graph-data'];
        let bar1_color = info['bar1_color'];
        let bar2_color = info['bar2_color'];
        let bar3_color = info['bar3_color'];
        let bar4_color = info['bar4_color'];

        let chart = am4core.create(chart_id, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS
        chart.data = data;

        chart.colors.list = [
            am4core.color(bar1_color),
            am4core.color(bar2_color),
            am4core.color(bar3_color),
            am4core.color(bar4_color)
        ];

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.title.text = titleX;
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.baseGrid.disabled = true;
        categoryAxis.renderer.grid.template.strokeWidth = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inside = false;
        categoryAxis.renderer.labels.template.fill = am4core.color("#757575");
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.renderer.labels.template.horizontalCenter = "middle";
        categoryAxis.renderer.cellStartLocation = 0.3;
        categoryAxis.renderer.cellEndLocation = 0.5;
        categoryAxis.tooltip.disabled = true; // for disabling to the y axis tooltip


        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.renderer.minWidth = 50;
        valueAxis.title.text = titleY;
        valueAxis.renderer.labels.template.fill = am4core.color("#757575");
        valueAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        valueAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.tooltip.disabled = true; // for disabling to the y axis tooltip
        valueAxis.renderer.grid.template.location = 0;

        let series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(60);
        series1.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series1.name = title1;
        series1.dataFields.categoryX = "category";
        series1.dataFields.valueY = "value1";
        series1.dataItems.template.locations.categoryX = 0.5;
        series1.stacked = true;
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4core.color("#213345");
        series1.tooltip.background.stroke = am4core.color("#213345");
        series1.tooltip.background.cornerRadius = 5;
        series1.tooltip.label.fontSize = 12;
        series1.tooltip.position = "pointer";
       
        var shadow = series1.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");


        let series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.columns.template.width = am4core.percent(60);
        series2.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series2.name = title2;
        series2.dataFields.categoryX = "category";
        series2.dataFields.valueY = "value2";
        series2.dataItems.template.locations.categoryX = 0.5;
        series2.stacked = true;
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#213345");
        series2.tooltip.background.stroke = am4core.color("#213345");
        series2.tooltip.background.cornerRadius = 5;
        series2.tooltip.label.fontSize = 12;
        series2.tooltip.position = "pointer";
       
        var shadow = series2.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");


        let series3 = chart.series.push(new am4charts.ColumnSeries());
        series3.columns.template.width = am4core.percent(60);
        series3.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series3.name = title3;
        series3.dataFields.categoryX = "category";
        series3.dataFields.valueY = "value3";
        series3.dataItems.template.locations.categoryX = 0.5;
        series3.stacked = true;
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4core.color("#213345");
        series3.tooltip.background.stroke = am4core.color("#213345");
        series3.tooltip.background.cornerRadius = 5;
        series3.tooltip.label.fontSize = 12;
        series3.tooltip.position = "pointer";
       
        var shadow = series3.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        let series4 = chart.series.push(new am4charts.ColumnSeries());
        series4.columns.template.width = am4core.percent(60);
        series4.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% [/]";
        series4.name = title4;
        series4.dataFields.categoryX = "category";
        series4.dataFields.valueY = "value4";
        series4.dataItems.template.locations.categoryX = 0.5;
        series4.stacked = true;
        series4.columns.template.column.cornerRadiusTopLeft = 10;
        series4.columns.template.column.cornerRadiusTopRight = 10;
        series4.tooltip.getFillFromObject = false;
        series4.tooltip.background.fill = am4core.color("#213345");
        series4.tooltip.background.stroke = am4core.color("#213345");
        series4.tooltip.background.cornerRadius = 5;
        series4.tooltip.label.fontSize = 12;
        series4.tooltip.position = "pointer";
       
        var shadow = series4.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

     }

    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
           
        )
    }
}
