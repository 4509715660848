import Wrapper from "../helpers/Wrapper";
import "./sectionComments.scss";

const Comments = ({ comments }) => {

    function timeAgo(timestamp) {
        const now = new Date();
        const diff = now - new Date(timestamp); // Difference in milliseconds
      
        // Time calculations
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
      
        if (days > 0) {
          return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
          return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
          return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
          return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
      }

    return (
        <Wrapper>
            <div className="comments-list">
                {comments.map((comment, index) => (
                    <div key={index} className="comment">
                        <div className="userImg-wrapper">
                            <img src={comment.userImg} />
                        </div>
                        <div className="content-wrapper">
                            <div className="userdata-timestamp-wrapper">
                                <div className="userdata">
                                    {comment.userName}
                                </div>
                                <div className="timestamp">
                                    {timeAgo(comment.timestamp)}
                                </div>
                            </div>
                            <div className="content">
                                {comment.message}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Wrapper>
    )
}
export default Comments;