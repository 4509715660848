import React, { useState } from 'react';
import ThumpsUpIcon from '../../../assets/icons/ThumpsUpIcon';
import ThumpsDownIcon from '../../../assets/icons/ThumpsDownIcon';
import "./feedback.scss";

const LikeUnlikeButton = () => {
  const [liked, setLiked] = useState(null);

  const handleLike = (event) => {
    event.stopPropagation()
    if (liked) {
      setLiked(null)
    } else {
      setLiked(true);
    }
  };

  const handleUnlike = (event) => {
    event.stopPropagation()
    if (liked === false) {
      setLiked(null);
    } else {
      setLiked(false);
    }
  };

  return (
    <div className="like-unlike-button">
      <button
        className={`like-button ${liked === true ? 'active' : ''}`}
        onClick={handleLike}
      >
        <ThumpsUpIcon className="icon" />
      </button>
      <button
        className={`unlike-button ${liked === false ? 'active' : ''}`}
        onClick={handleUnlike}
      >
        <ThumpsDownIcon className="icon" />
      </button>
    </div>
  );
};

export default LikeUnlikeButton;
