import { CURRENCY_METRICS, PERCENTAGE_METRIC, periodOrders, TONNES_METRIC } from "../constants/constants";
import { headerFormatter } from "../constants/headerFormatter";

export const SumOfArrayChilds = (obj) => {
    var sum = 0;

    obj.map((data) => {
        sum += parseFloat(data.value)
    })

    return sum;
}

let labelsArr = [
    {
        label: "Monthly Sales Vol. %",
        value: "total_monthly_sales_vol_percent",
    },
    {
        label: "Total Spends %",
        value: "total_spend_percent"
    },
    {
        label: "TV Spends",
        value: "tv_spends"
    },
    {
        label: "TV GRPs",
        value: "tv_grp"
    },
    {
        label: "TV Digital Impressions",
        value: "tv_digital_impressions"
    },
    {
        label: "TV Digital Spends",
        value: "tv_digital_spends"
    },
    {
        label: "All Digital Impressions",
        value: "all_digital_impressions"
    },
    {
        label: "All Digital Spends",
        value: "all_digital_spends"
    },
    {
        label: "Digital Meta Spends",
        value: "digital_meta_spends"
    },
    {
        label: "Digital Meta Impressions",
        value: "digital_meta_impressions"
    },
    {
        label: "Digital DV360 Spends",
        value: "digital_dv360_spends"
    },
    {
        label: "Digital DV360 Impressions",
        value: "digital_dv360_impressions"
    }
];

const labels = (key) => {
    const labelObj = labelsArr.find((data) => data.value === key);
    return labelObj ? labelObj.label : "";
};

export const BubbleChartSizeShapeDynamicLabels = (data) => {
    if (data === undefined || data.length === 0) return [];

    let dataArr = [];
    let defaultKeys = [
        "value",
        "valuex",
        "valuey",
        "valuexLabel",
        "valueyLabel",
        "market",
        "growth",
        "color",
    ];
    let children = [];
    Object.keys(data).forEach((key) => {
        if (!defaultKeys.includes(key)) {
            children.push({ label: labels(key), value: key });
        }
    });
    data["children"] = children;
    dataArr.push(data);
    return dataArr;
};

export const tooltipNumberFormatting = (label, number, country, platform) => {
    if (typeof label !== "string") return "";
    if (number === null || number === undefined) return 0

    if (number !== undefined || null !== null) {
        let formattedNumber = null;

        formattedNumber = number.toLocaleString("en-US", { maximumFractionDigits: 2 });

        let currency = addCurrencySymbols(label, platform, country)
        let metric = addMetricsSymbols(label)
        let tempNumber = formattedNumber;

        if (currency !== null) {

            if (country.countryCode === "US") {
                tempNumber = currency + formattedNumber
            } else {
                formattedNumber = number.toLocaleString('en-IN', { maximumFractionDigits: 2 });
                tempNumber = currency + formattedNumber
            }
        }

        if (metric !== null) {
            tempNumber = formattedNumber + metric
        }

        return tempNumber

    } else {
        var emptyNumLabel = addMetricsSymbols(label);
        var emptyNum = 0 + emptyNumLabel
        return emptyNum;
    }

}


// ADD CURENCY SYMBOLS AS PER THE USER COUNTRY
export const addCurrencySymbols = (label, platform, country) => {
    if (platform === undefined) {
        platform = "default";
    }

    if (label === undefined || label === null) {
        return null
    }

    if (country === undefined) return null;

    if (CURRENCY_METRICS.includes(label.toLowerCase())) {
        if (platform === "Twitter") {
            return country.alternateCurrencySymbol;
        } else {
            return country.currencySymbol;
        }
    } else {
        return null;
    }
}

export const addMetricsSymbols = (label) => {
    if (label === undefined) return null

    if (TONNES_METRIC.includes(label.toLowerCase())) {
        return "T"
    } else if (PERCENTAGE_METRIC.includes(label.toLowerCase())) {
        return "%"
    } else {
        return null
    }
}


export default function getValueFromHeaderFormatter(labelToMatch) {
    const headerObj = headerFormatter.find(
        (obj) => obj.label === labelToMatch
    );
    return headerObj ? headerObj.value : labelToMatch;
}

// GET UNIQUE VALUES FROM THE ARRAY OF OBJECTS
export const getUniqueValuesFromArrofObj = (rawData, labelKeyToRemove) => {
    // Get all keys from the objects
    const allKeys = rawData.reduce((keys, obj) => {
        Object.keys(obj).forEach(key => keys.add(key));
        return keys;
    }, new Set());

    let allUniqueKeysArr = [...allKeys]

    // Remove "labelKeyToRemove" from the array
    const filteredKeys = allUniqueKeysArr.filter(key => key !== labelKeyToRemove);

    return filteredKeys
}


export const getHeaderKeysWithLabels = (key) => {
    if (key === undefined || key === null) return null

    let headerData = [
        {
            label: "Campaign Name",
            value: "campaign_name",
        },
        {
            label: "Objective",
            value: "objective",
        },
        {
            label: "Impressions",
            value: "impressions",
        },
        {
            label: "Clicks",
            value: "clicks",
        },
        {
            label: "Conversions",
            value: "conversions",
        },
        {
            label: "Spends",
            value: "spends",
        },
        {
            label: "CTR",
            value: "ctr",
        },
        {
            label: "Average CPC",
            value: "average_cpc",
        },
        {
            label: "Average CPM",
            value: "average_cpm",
        },
        {
            label: "Conversion Rate",
            value: "conversion_rate",
        },
        {
            label: "Cost Per Conversion",
            value: "cost_per_conversion",
        },
        {
            label: "Top Impression Rate",
            value: "top_impression_rate",
        },
        {
            label: "ABS Top Impression Rate",
            value: "abs_top_impression_rate",
        },
        {
            label: "View Through Rate",
            value: "view_through_rate",
        },
        {
            label: "Ad Group Name",
            value: "ad_group_name",
        },
        {
            label: "Keyword",
            value: "keyword",
        },
        {
            label: "Campaign Type",
            value: "campaign_type",
        },
        {
            label: "Ad Name",
            value: "ad_name",
        },
        {
            label: "Ad ID",
            value: "ad_id",
        },
        {
            label: "Ad Name",
            value: "ad_name",
        },
        {
            label: "Country",
            value: "country",
        },
        {
            label: "City",
            value: "city",
        },
        {
            label: "State",
            value: "state",
        },
        {
            label: "Average CPC",
            value: "avg_cpc",
        },
        {
            label: "Average CPM",
            value: "avg_cpm",
        },
        {
            label: "Search Term",
            value: "search_term",
        },
        {
            label: "Bid Strategy Type",
            value: "bid_strategy_type",
        },
    ]

    const matchedData = headerFormatter.find(data => data.label === key);

    return matchedData ? matchedData.value : '';

}

export const concateTotals = (mainArr, totalArr) => {

    if (mainArr === undefined || mainArr === null || mainArr.length === 0) return [];
    if (totalArr === undefined || totalArr === null || totalArr.length === 0) return mainArr;

    const addTotalString = addStringToFirstValue(totalArr, 'Total')
    const mergedArray = mainArr.concat(addTotalString)
    return mergedArray;
}

export function addStringToFirstValue(arr, str) {
    if (Array.isArray(arr) && arr.length > 0 && typeof arr[0] === 'object') {
        const firstObj = arr[0];
        const keys = Object.keys(firstObj);
        if (keys.length > 0) {
            const firstKey = keys[0];
            if (typeof firstObj[firstKey] === 'string' || typeof firstObj[firstKey] === 'number') {
                // Check if the value contains the keyword "Total"
                if (!String(firstObj[firstKey]).includes("Total")) {
                    firstObj[firstKey] = str + firstObj[firstKey];
                }
            }
        }
    }
    return arr;
}

export const sumTotals = (arr) => {
    return arr.reduce((totals, obj) => {
        for (const key in obj) {
            if (totals[key] === undefined) {
                totals[key] = 0;
            }
            if (typeof obj[key] === 'number') {
                totals[key] += obj[key];
            } else {
                totals[key] = ' ';
            }
        }
        return totals;
    }, {});
};


// FORMAT THE DATE & TIME IN THE FOLLOWING FORMAT
// 29/08/2024 | 12:45 
// INPUT IS REQUIRED IN THE new Date() format

// USED IN THE FOLLOWING COMPONENTS OR PAGES
// sectionComments.js
export const formatDateTime = () => {
    const date = new Date();

    // Get individual date components
    const day = String(date.getDate()).padStart(2, '0'); // Day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so +1
    const year = date.getFullYear();

    // Get individual time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date and time
    return `${day}/${month}/${year} | ${hours}:${minutes}`;
};

// FORMAT TABLE HEADER LABELS, CHART LABELS
export const formatLabels = (input) => {
    // Dictionary to handle specific replacements
    const replacements = {
        percent: '%',
        pec: '%',
    };

    // List of words to keep as they are (format case-sensitively)
    const keepAsIs = ['GRPs', 'ROI', 'API', "CPM"]; // Add more words as needed

    // Split the input by underscores
    let words = input.split('_');

    // Capitalize the first letter of each word and handle special cases
    words = words.map(word => {
        let lowerCaseWord = word.toLowerCase();

        // Check if the word should be replaced
        if (replacements[lowerCaseWord]) {
            return replacements[lowerCaseWord];  // Replace 'pec' or 'percent' with '%'
        }

        // Check if the word matches any in the keepAsIs list (case-insensitive check)
        const wordToKeep = keepAsIs.find(w => w.toLowerCase() === lowerCaseWord);
        if (wordToKeep) {
            return wordToKeep;  // Keep as-is (e.g., 'GRPs')
        }

        // Otherwise, capitalize the first letter and convert the rest to lowercase
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words with spaces
    let formattedString = words.join(' ');

    return formattedString;
};

// Function to sort data
const sortingSequence = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
];

// INSIGHT CHARTS DATA CALCULATIONS FOR THE CHARTS DATA
export const formatChartData = (chartData, chartType) => {

    if (chartType === "bar-line-chart") {
        chartData.data.map((data) => {
            if (typeof data[chartData.x_axis_label] !== "string") {
                let a = data[chartData.x_axis_label];
                data[chartData.x_axis_label] = a !== undefined ? a.toString() : "";
            }
        });

        // REMOVE THE EXTRA STRING VALUES FROM THE DATA OTHER THAN THE X AXIS KEY VALUE
        let cleaned_chart_data = removedStringValueKeysFromObjectArray(chartData?.data ?? [], chartData?.x_axis_label ?? "")

        let bar_metrics = chartData.bar_label

        let line_metrics = chartData.line_label;

        let x_axis_metric = chartData.x_axis_label;

        let chart_data = cleaned_chart_data;

        let chart_labels = [
            {
                linechartLabels: [],
                barchartLabels: []
            }
        ]

        bar_metrics.forEach((bar) => {
            chart_labels[0].barchartLabels.push({
                label: formatLabels(bar),
                value: bar,
            })
        })

        line_metrics.forEach((line) => {
            chart_labels[0].linechartLabels.push({
                label: formatLabels(line),
                value: line,
            })
        })

        let formatted_data = renameKeyInObjectArray(chart_data, x_axis_metric, "label")

        const sortedData = formatted_data.sort((a, b) => {
            // Convert label to string for comparison
            const labelA = a.label.toString();
            const labelB = b.label.toString();

            // Find the index of the label in the sorting sequence
            const indexA = sortingSequence.indexOf(labelA);
            const indexB = sortingSequence.indexOf(labelB);

            // If label is not found in the sequence, place it at the end
            const rankA = indexA === -1 ? sortingSequence.length : indexA;
            const rankB = indexB === -1 ? sortingSequence.length : indexB;

            // Sort based on rank
            return rankA - rankB;
        });

        return {
            labels: chart_labels,
            data: sortedData
        };
    }
    else if (chartType === "pie-chart") {

        let chart_data = chartData?.data ?? [];

        let formattedData = chart_data.map(item => {
            let childLabel = '';
            let childValue = 0;
            let label = '';
            let value = 0;
            let name = '';

            for (let key in item) {
                if (key.includes("percent")) {
                    childLabel = formatLabels(key);
                    childValue = item[key];
                } else if (typeof item[key] !== "string") {
                    label = formatLabels(key);
                    value = item[key];
                } else if (typeof item[key] === "string") {
                    name = item[key]
                }
            }

            return {
                name: name,
                label: label,
                value: value,
                childLabel: childLabel,
                childValue: childValue
            };
        });

        return {
            data: formattedData
        }
    }
    else if (chartType === "line-chart" || chartType === "bar-chart" || chartType === "group-bar-chart") {
        chartData.data.map((data) => {
            if (typeof data[chartData.x_axis_label] !== "string") {
                let a = data[chartData.x_axis_label];
                data[chartData.x_axis_label] = a !== undefined ? a.toString() : "";
            }
        });

        // REMOVE THE EXTRA STRING VALUES FROM THE DATA OTHER THAN THE X AXIS KEY VALUE
        let cleaned_chart_data = removedStringValueKeysFromObjectArray(chartData?.data ?? [], chartData?.x_axis_label ?? "")

        // RAW DATA COMING FROM THE API 
        let chart_data = cleaned_chart_data;

        // X AXIS METRIC KEY WHICH WILL PLOT ON THE X AXIS
        let x_axis_metric = chartData.x_axis_label;

        // RENAME THE X AXIS KEY TO THE LABEL AS CHART REQUIRED LABEL KEY
        let formatted_data = renameKeyInObjectArray(chart_data, x_axis_metric, "label")

        // GET THE FIRST OBJECT FROM THE UPDATED DATA TO GET THE KEYS FOR THE LABELS
        const keys = Object.keys(formatted_data[0] ?? []).filter(key => key !== "label");

        const labels = keys.map(key => ({
            label: formatLabels(key),
            value: key
        }));

        const sortedData = formatted_data.sort((a, b) => {
            // Convert label to string for comparison
            const labelA = a.label.toString();
            const labelB = b.label.toString();

            // Find the index of the label in the sorting sequence
            const indexA = sortingSequence.indexOf(labelA);
            const indexB = sortingSequence.indexOf(labelB);

            // If label is not found in the sequence, place it at the end
            const rankA = indexA === -1 ? sortingSequence.length : indexA;
            const rankB = indexB === -1 ? sortingSequence.length : indexB;

            // Sort based on rank
            return rankA - rankB;
        });

        return {
            labels: labels,
            data: sortedData
        };
    }
    else if (chartType === "scatter-chart") {
        let chart_data = chartData?.data[0] ?? []

        // Extract metric names
        const metric1 = chart_data["Metric 1"];
        const metric2 = chart_data["Metric 2"];

        // Extract metric values
        const metric1Values = chart_data["Metric 1 Values"];
        const metric2Values = chart_data["Metric 2 Values"];

        // Format the data
        const formattedData = metric1Values.map((value, index) => {
            return {
                [metric1]: value,
                [metric2]: metric2Values[index]
            };
        });

        //GET THE MINIMUM AND MAXIMUM VALUE OF A METRIC
        let metric_1_min_value = Math.min(...metric1Values);
        let metric_1_max_value = Math.max(...metric1Values);

        let metric_2_min_value = Math.min(...metric2Values);
        let metric_2_max_value = Math.max(...metric2Values);

        let line_plot_values = [
            {
                x: metric_1_min_value,
                y: metric_2_min_value
            },
            {
                x: metric_1_max_value,
                y: metric_2_max_value
            },
        ]

        // GET THE FIRST OBJECT FROM THE UPDATED DATA TO GET THE KEYS FOR THE LABELS
        const keys = Object.keys(formattedData[0]).filter(key => key !== "label");

        const labels = keys.map(key => ({
            label: formatLabels(key),
            value: key,
        }));

        return {
            labels: labels,
            data: formattedData,
            linePlot: line_plot_values
        };

    }
    else {
        // REMOVE THE EXTRA STRING VALUES FROM THE DATA OTHER THAN THE X AXIS KEY VALUE
        let cleaned_chart_data = removedStringValueKeysFromObjectArray(chartData?.data ?? [], chartData?.x_axis_label ?? "")

        let chart_data = cleaned_chart_data;

        let x_axis_metric = chartData.x_axis_label;

        let formatted_data = renameKeyInObjectArray(chart_data, x_axis_metric, "label")

        if (formatted_data[0]) {
            const keys = Object.keys(formatted_data[0]).filter(key => key !== "label");
            const labels = keys.map(key => ({
                label: formatLabels(key),
                value: key
            }));

            return {
                labels: labels,
                data: formatted_data
            };
        } else {
            return {
                labels: {},
                data: formatted_data
            };
        }


    }
}

// REMOVE THE EXTRA STRING VALUES FROM THE DATA OTHER THAN THE X AXIS KEY VALUE
export const removedStringValueKeysFromObjectArray = (chart_data, x_axis_metric) => {

    if (chart_data === undefined || chart_data === null || x_axis_metric === undefined || x_axis_metric === null) {
        return []
    }

    let cleanedData = chart_data.map(item => {
        return Object.keys(item).reduce((acc, key) => {
            if (key === x_axis_metric || typeof item[key] !== 'string') {
                acc[key] = item[key];
            }
            return acc;
        }, {});
    });

    return cleanedData;
}

// CHANGE THE NAME OF THE KEYS FROM THE OBJECT
export const renameKeyInObjectArray = (dataArray, oldKey, newKey) => {

    if (dataArray === undefined || dataArray === null || oldKey === undefined || oldKey === null || newKey === undefined || newKey === null) {
        return []
    }

    return dataArray.map(item => {
        if (item.hasOwnProperty(oldKey)) {
            const { [oldKey]: oldKeyValue, ...rest } = item;
            return {
                ...rest,
                [newKey]: oldKeyValue
            };
        }
        return item; // Return the item as is if oldKey doesn't exist
    });
}

export const isRichText = (text) => {
    if (text === undefined || text === null || text === "") {
        return ""
    }

    // Regular expression to check for HTML tags
    const htmlTagRegex = /<\/?[\w\s="/.':;#-\/]+>/gi;
    return htmlTagRegex.test(text);
}

export const RichTextComponent = ({ richText }) => {
    if (richText === undefined || richText === null || richText === "") {
        return ""
    }

    return <div dangerouslySetInnerHTML={{ __html: richText }} />
};

export const convertHtmlToText = (html) => {
    // Create a temporary DOM element
    const tempElement = document.createElement("div");

    // Set the HTML content
    tempElement.innerHTML = html;

    // Extract and return the plain text
    return tempElement.innerText || tempElement.textContent || "";
};

export const changeKeyName = (arr, oldKey, newKey) => {
    return arr.map(obj => {
        // Create a new object by copying all properties except the one to be renamed
        let newObj = { ...obj };

        // Check if the old key exists
        if (oldKey in newObj) {
            // Assign the value to the new key
            newObj[newKey] = newObj[oldKey];
            // Delete the old key
            delete newObj[oldKey];
        }

        return newObj;
    });
};

export const getUniqueKeys = (arr) => {
    return arr.reduce((acc, obj) => {
        // Get all keys of the current object
        Object.keys(obj).forEach(key => {
            // Add the key to the accumulator if it doesn't already exist
            if (!acc.includes(key)) {
                acc.push(key);
            }
        });
        return acc;
    }, []);
};

export const convertAmchartsColorToHexColor = (amchartsColor) => {
    if (amchartsColor === null || amchartsColor === undefined) return "#000000"
    return `#${amchartsColor.toString(16).padStart(6, "0")}`;
} 