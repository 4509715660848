import React, { Component } from 'react';
import Image from 'react-bootstrap/esm/Image';
import Col from "react-bootstrap/esm/Col";
import '../card.scss';

export  class CategoryCard extends Component {
    render() {
        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-risalescard">
                    <div className="icz-risalescardheader">
                        <div className="icz-risalescardtitlewrapper">
                            <h3 className="icz-risalescardtitle">{this.props.CardTitle}</h3>
                        </div>
                    </div>
                    <div className="icz-risalescardbody">
                      <Col lg={12} className="icz-risalesimgwrapper"><Image src={this.props.CardImg} className="icz-risalesimg" /></Col> 
                      <Col lg={12} className="icz-risalescardsubtitle">{this.props.SubTitle}</Col> 
                      <Col lg={12} className="icz-risalescarddetails">
                      <Col lg={4} className="icz-risalesthumbnailwrapper"><Image src={this.props.CardThumbnail} className="icz-risalesthumbnail" /></Col> 
                      <Col lg={8} className="icz-risalescardtext">{this.props.CardText} </Col> 
                    </Col> 

                    <Col lg={12} className="icz-risalescarddetails">
                      <Col lg={4} className="icz-risalesthumbnailwrapper"><Image src={this.props.CardThumbnail2} className="icz-risalesthumbnail" /></Col> 
                      <Col lg={8} className="icz-risalescardtext">{this.props.CardText2} </Col> 
                    </Col> 

                    <Col lg={12} className="icz-risalescarddetails">
                      <Col lg={4} className="icz-risalesthumbnailwrapper"><Image src={this.props.CardThumbnail3} className="icz-risalesthumbnail" /></Col> 
                      <Col lg={8} className="icz-risalescardtext">{this.props.CardText3} </Col> 
                    </Col> 

                    <Col lg={12} className="icz-risalescarddetails">
                      <Col lg={4} className="icz-risalesthumbnailwrapper"><Image src={this.props.CardThumbnail4} className="icz-risalesthumbnail" /></Col> 
                      <Col lg={8} className="icz-risalescardtext">{this.props.CardText4} </Col> 
                    </Col> 
                   
                    </div>
                </div>
            </div>
        );
    }
}

