import { isRichText } from "../../utils/Utils";
import Wrapper from "../helpers/Wrapper"

const InsightsDetails = ({ details }) => {

    return (
        <Wrapper>
            <div className="detailsWrapper">
                {isRichText(details) ? <div className="content" dangerouslySetInnerHTML={{ __html: details }} /> : details}
            </div>
        </Wrapper>
    )
}
export default InsightsDetails;