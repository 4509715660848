import Wrapper from "../../components/helpers/Wrapper";

const SummarizationIcon = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 18C13.9624 18 18 13.9624 18 9C18 4.03763 13.9624 0 9 0C4.03763 0 0 4.03763 0 9C0 13.9624 4.03763 18 9 18ZM9 17.25C6.86025 17.25 4.908 16.4306 3.44063 15.0896L9.15525 9.375H17.2406C17.0437 13.7505 13.4235 17.25 9 17.25ZM17.2406 8.625H9.375V0.759375C13.6264 0.950625 17.0494 4.37363 17.2406 8.625ZM8.625 0.759375V8.84475L2.91037 14.5594C1.56937 13.092 0.75 11.1397 0.75 9C0.75 4.5765 4.2495 0.95625 8.625 0.759375Z" fill="#DB7823" />
            </svg>
        </Wrapper>
    )
}

export default SummarizationIcon;
