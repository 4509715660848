import { useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../helpers/Wrapper"
import IcogzIcon from "../../assets/icons/IcogzIcon";
import "./chat.scss";

const SectionInsights = ({ query }) => {
    const location = useLocation();
    const navigate = useNavigate();

    let sectionQuery = null

    if (query === undefined) {
        sectionQuery = null
    } else if (query === null) {
        sectionQuery = null
    } else if (query === "") {
        sectionQuery = null
    } else {
        sectionQuery = query
    }

    const onInsightBtnClickHandler = () => {
        if (sectionQuery !== null) {
            navigate("/ask-icogz", { state: { from: location.pathname, query: sectionQuery, data: null } });
        }
    }

    return (
        <Wrapper>
            <div className="section-insights-wrapper" onClick={onInsightBtnClickHandler} >
                <IcogzIcon className="icon" />
            </div>
        </Wrapper>
    )
}
export default SectionInsights;