import Wrapper from "../../components/helpers/Wrapper";

const ThumpsUpIcon = (props) => {
  return (
    <Wrapper>
      <svg className={props.className} width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.40816 6.52525L7.13496 1C7.67733 1 8.19748 1.19404 8.58099 1.53944C8.9645 1.88483 9.17995 2.35329 9.17995 2.84175V5.29755L13.0385 5.29553C13.2361 5.29351 13.4318 5.33021 13.6121 5.40309C13.7924 5.47596 13.9529 5.58327 14.0826 5.71757C14.2122 5.85187 14.3079 6.00995 14.363 6.18087C14.4181 6.35178 14.4313 6.53145 14.4016 6.7074L13.4605 12.2347C13.4112 12.5274 13.2461 12.7943 12.9956 12.986C12.7451 13.1778 12.4261 13.2816 12.0973 13.2782H4.40816M4.40816 6.52525V13.2782M4.40816 6.52525H2.36318C2.00164 6.52525 1.65491 6.6546 1.39926 6.88484C1.14362 7.11507 1 7.42735 1 7.75295V12.0505C1 12.3761 1.14362 12.6884 1.39926 12.9186C1.65491 13.1489 2.00164 13.2782 2.36318 13.2782H4.40816" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </Wrapper>
  );
};

export default ThumpsUpIcon;
