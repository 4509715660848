const IconDeGrowthdefault = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="17.541" height="15.721" viewBox="0 0 17.541 15.721" fill="#486F88" stroke="none">
            <g id="Group_11" data-name="Group 11" transform="translate(0 0)">
                <path id="Path_46" data-name="Path 46" d="M14.524,10.281c-.7,0-1.36,0-2.019,0a.906.906,0,0,0-.065,1.811q2.163.01,4.326,0a.882.882,0,0,0,.86-.857q.007-2.163,0-4.326a.906.906,0,0,0-1.809,0c-.006.63,0,1.259,0,1.889a.32.32,0,0,1-.043.176c-.046-.055-.087-.115-.138-.166Q12.93,6.1,10.224,3.4a.939.939,0,0,0-1.518,0c-.61.61-1.223,1.218-1.828,1.833-.1.1-.156.108-.261,0Q4.185,2.795,1.745.358a.955.955,0,0,0-1-.318A.9.9,0,0,0,.288,1.469a2.242,2.242,0,0,0,.179.194L6,7.193a.936.936,0,0,0,1.505,0Q8.43,6.26,9.357,5.331c.071-.071.112-.11.206-.016,1.64,1.646,3.283,3.289,4.96,4.966" transform="translate(-0.089 0)" fill="#486F88" />
                <path id="Path_47" data-name="Path 47" d="M8.776,277.2H.918A.907.907,0,0,1,.5,275.487a.852.852,0,0,1,.4-.1l15.753,0a.906.906,0,0,1-.016,1.812q-3.929,0-7.857,0" transform="translate(-0.001 -261.481)" fill="#486F88" />
            </g>
        </svg>
    )
}

export default IconDeGrowthdefault;