import Wrapper from "../../components/helpers/Wrapper";

const SentimentAnalysisIcon = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.0001 5.15385C19.0001 5.9754 18.7565 6.7785 18.3 7.4616C17.8436 8.14469 17.1949 8.6771 16.4358 8.9915C15.6768 9.30589 14.8416 9.38815 14.0359 9.22788C13.2301 9.0676 12.4899 8.67198 11.909 8.09106C11.3281 7.51013 10.9325 6.76999 10.7722 5.96422C10.6119 5.15845 10.6942 4.32325 11.0086 3.56424C11.323 2.80522 11.8554 2.15648 12.5385 1.70005C13.2216 1.24362 14.0247 1 14.8462 1C15.9479 1 17.0044 1.43764 17.7834 2.21663C18.5624 2.99563 19.0001 4.05218 19.0001 5.15385Z" stroke="#FF9931" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.4397 4.00001C12.653 3.83797 12.9136 3.75024 13.1815 3.75024C13.4493 3.75024 13.7099 3.83797 13.9232 4.00001" stroke="#FF9931" strokeWidth="0.5" strokeLinecap="round" />
                <path d="M15.6042 4.00001C15.8176 3.83797 16.0781 3.75024 16.346 3.75024C16.6139 3.75024 16.8744 3.83797 17.0878 4.00001" stroke="#FF9931" strokeWidth="0.5" strokeLinecap="round" />
                <path d="M14.7765 7.87088C13.6829 7.87088 12.7279 6.97078 12.5502 5.73352H17.003C16.8253 6.97094 15.8701 7.87088 14.7765 7.87088Z" stroke="#FF9931" strokeWidth="0.5" />
                <mask id="path-5-inside-1_443_568" fill="white">
                    <path d="M14.7765 7.72528C15.7666 7.72528 16.6069 6.94225 16.8294 5.87912H12.7238C12.9462 6.94225 13.7864 7.72528 14.7765 7.72528ZM14.7765 8.12088C13.4505 8.12088 12.3517 6.9624 12.2749 5.48352H17.2782C17.2014 6.96259 16.1024 8.12088 14.7765 8.12088Z" />
                </mask>
                <path d="M14.7765 7.72528C15.7666 7.72528 16.6069 6.94225 16.8294 5.87912H12.7238C12.9462 6.94225 13.7864 7.72528 14.7765 7.72528ZM14.7765 8.12088C13.4505 8.12088 12.3517 6.9624 12.2749 5.48352H17.2782C17.2014 6.96259 16.1024 8.12088 14.7765 8.12088Z" fill="#486F88" />
                <path d="M16.8294 5.87912L17.3188 5.98153L17.4448 5.37912H16.8294V5.87912ZM12.7238 5.87912V5.37912H12.1083L12.2344 5.98151L12.7238 5.87912ZM12.2749 5.48352V4.98352H11.7483L11.7756 5.50943L12.2749 5.48352ZM17.2782 5.48352L17.7776 5.50945L17.8049 4.98352H17.2782V5.48352ZM14.7765 8.22528C16.0492 8.22528 17.0579 7.2282 17.3188 5.98153L16.34 5.77672C16.1559 6.6563 15.484 7.22528 14.7765 7.22528V8.22528ZM16.8294 5.37912H12.7238V6.37912H16.8294V5.37912ZM12.2344 5.98151C12.4951 7.22819 13.5038 8.22528 14.7765 8.22528V7.22528C14.069 7.22528 13.3972 6.65631 13.2132 5.77674L12.2344 5.98151ZM14.7765 7.62088C13.7633 7.62088 12.8398 6.72012 12.7742 5.45761L11.7756 5.50943C11.8636 7.20468 13.1377 8.62088 14.7765 8.62088V7.62088ZM12.2749 5.98352H17.2782V4.98352H12.2749V5.98352ZM16.7789 5.45759C16.7133 6.72027 15.7896 7.62088 14.7765 7.62088V8.62088C16.4152 8.62088 17.6895 7.20492 17.7776 5.50945L16.7789 5.45759Z" fill="#FF9931" mask="url(#path-5-inside-1_443_568)" />
                <path d="M9.30769 14.8462C9.30769 15.6678 9.06407 16.4709 8.60764 17.154C8.15121 17.8371 7.50247 18.3695 6.74346 18.6839C5.98444 18.9983 5.14924 19.0805 4.34347 18.9203C3.5377 18.76 2.79756 18.3644 2.21664 17.7834C1.63571 17.2025 1.24009 16.4624 1.07982 15.6566C0.91954 14.8508 1.0018 14.0156 1.31619 13.2566C1.63059 12.4976 2.163 11.8489 2.84609 11.3924C3.52919 10.936 4.33229 10.6924 5.15385 10.6924C6.25552 10.6924 7.31206 11.13 8.09106 11.909C8.87006 12.688 9.30769 13.7446 9.30769 14.8462Z" stroke="#486F88" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.49243 17.299C3.69391 17.0498 3.94648 16.8468 4.2331 16.7034C4.51972 16.5601 4.83373 16.4799 5.15397 16.4683C5.47421 16.4799 5.78822 16.5601 6.07484 16.7034C6.36146 16.8468 6.61403 17.0498 6.81551 17.299" stroke="#486F88" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.91494 14.3846C3.91494 14.6835 3.67263 14.9258 3.37373 14.9258C3.07483 14.9258 2.83252 14.6835 2.83252 14.3846C2.83252 14.0857 3.07483 13.8434 3.37373 13.8434C3.67263 13.8434 3.91494 14.0857 3.91494 14.3846Z" fill="white" stroke="#486F88" strokeWidth="0.5" />
                <path d="M3.37368 14.978C3.70141 14.978 3.96709 14.7123 3.96709 14.3845C3.96709 14.0568 3.70141 13.7911 3.37368 13.7911C3.04595 13.7911 2.78027 14.0568 2.78027 14.3845C2.78027 14.7123 3.04595 14.978 3.37368 14.978Z" stroke="#486F88" strokeWidth="0.5" />
                <path d="M7.34316 14.3846C7.34316 14.6835 7.10085 14.9258 6.80195 14.9258C6.50305 14.9258 6.26074 14.6835 6.26074 14.3846C6.26074 14.0857 6.50305 13.8434 6.80195 13.8434C7.10085 13.8434 7.34316 14.0857 7.34316 14.3846Z" fill="white" stroke="#486F88" strokeWidth="0.5" />
                <path d="M6.80215 14.978C7.12988 14.978 7.39555 14.7123 7.39555 14.3845C7.39555 14.0568 7.12988 13.7911 6.80215 13.7911C6.47442 13.7911 6.20874 14.0568 6.20874 14.3845C6.20874 14.7123 6.47442 14.978 6.80215 14.978Z" stroke="#486F88" strokeWidth="0.5" />
                <path d="M4.42848 13.4615L3.37354 12.6703" stroke="#486F88" strokeWidth="0.5" strokeLinecap="round" />
                <path d="M5.74707 13.4615L6.80202 12.6703" stroke="#486F88" strokeWidth="0.5" strokeLinecap="round" />
                <path d="M19.0001 14.8462C19.0001 15.6678 18.7565 16.4709 18.3 17.154C17.8436 17.8371 17.1949 18.3695 16.4358 18.6839C15.6768 18.9983 14.8416 19.0805 14.0359 18.9203C13.2301 18.76 12.4899 18.3644 11.909 17.7834C11.3281 17.2025 10.9325 16.4624 10.7722 15.6566C10.6119 14.8508 10.6942 14.0156 11.0086 13.2566C11.323 12.4976 11.8554 11.8489 12.5385 11.3924C13.2216 10.936 14.0247 10.6924 14.8462 10.6924C15.9479 10.6924 17.0044 11.13 17.7834 11.909C18.5624 12.688 19.0001 13.7446 19.0001 14.8462Z" stroke="#5A75F9" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.1848 16.5076C13.3863 16.2585 13.6389 16.0554 13.9255 15.9121C14.2121 15.7688 14.5261 15.6886 14.8464 15.6769C15.1666 15.6886 15.4806 15.7688 15.7672 15.9121C16.0538 16.0554 16.3064 16.2585 16.5079 16.5076" stroke="#5A75F9" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.6001 13.6H13.6048" stroke="#5A75F9" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.0923 13.6H16.097" stroke="#5A75F9" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.30769 5.15385C9.30769 5.9754 9.06407 6.7785 8.60764 7.4616C8.15121 8.14469 7.50247 8.6771 6.74346 8.9915C5.98444 9.30589 5.14924 9.38815 4.34347 9.22788C3.5377 9.0676 2.79756 8.67198 2.21664 8.09106C1.63571 7.51013 1.24009 6.76999 1.07982 5.96422C0.91954 5.15845 1.0018 4.32325 1.31619 3.56424C1.63059 2.80522 2.163 2.15648 2.84609 1.70005C3.52919 1.24362 4.33229 1 5.15385 1C6.25552 1 7.31206 1.43764 8.09106 2.21663C8.87006 2.99563 9.30769 4.05218 9.30769 5.15385Z" stroke="#11A1FD" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.49243 5.98462C3.69391 6.2338 3.94648 6.4369 4.2331 6.58021C4.51972 6.72352 4.83373 6.80371 5.15397 6.81539C5.47421 6.80371 5.78822 6.72352 6.07484 6.58021C6.36146 6.4369 6.61403 6.2338 6.81551 5.98462" stroke="#11A1FD" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.90771 3.90759H3.9124" stroke="#11A1FD" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.3999 3.90759H6.40459" stroke="#11A1FD" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </Wrapper>
    )
}

export default SentimentAnalysisIcon;
