import React, { Component } from 'react';
import { Carousel } from '../../../shared/components/carousel/carousel';
import { SalesCard } from '../../../shared/components/cards/risalescard/risalescard';
import {SalesDetailsCard} from '../../../shared/components/cards/risalescard/risalesdetailscard'
import RiSalesHBarChart from '../../../shared/components/charts/RiSalesHBarChart';


export class StorePresence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesdata: [{ "category": "5", "value": "3.2" }, { "category": "4", "value": "2.2" }, { "category": "3", "value": "4.4" }, { "category": "2", "value": "3.8" }, { "category": "1", "value": "2.6" }],
      storedata: [{ "category": "Positive", "value": "1.8" }, { "category": "Negative", "value": "1.5" }, { "category": "Neutral", "value": "1.9" }],
      compititorsdata: [{ "category": "Reliance", "value": "1.4" }, { "category": "Max", "value": "1.5" }, { "category": "Central", "value": "1.9" }, { "category": "Pantaloons", "value": "1.5" }, { "category": "Shoppers Stop", "value": "1.8" }, { "category": "Pantaloons", "value": "1.5" }],   
      max_carpet_area:this.props.max_carpet_area,
      avg_carpet_area:this.props.avg_carpet_area,
      min_carpet_area:this.props.min_carpet_area,
    }
  }

  render() {
    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    }

    return (
      <div className="icz-carouselcontainer">
        <Carousel
          Settings={CarouselSettings}
          class={"icz-cardcontainer"}
          SliderCards={[
            <SalesDetailsCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Carpet Area"
              CardHighlightText={this.state.max_carpet_area}
              CardSmallText="sq.ft."
              CardSubTitle="Highest"
              CardHighlightText2={this.state.avg_carpet_area}
              CardSmallText2="sq.ft."
              CardSubTitle2="Average"
              CardHighlightText3={this.state.min_carpet_area}
              CardSmallText3="sq.ft."
              CardSubTitle3="Lowest"
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Store Star Rating"
              chart={<RiSalesHBarChart chart_id="store_rating" chart_class="icz-risalescardchart" Ytitle={"(value in K)"} metric_1={"No. of Ratings"} graph-data={this.state.salesdata} bar_color={"#FF9931"} />}
            />,
           <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Store Reviews"
              chart={<RiSalesHBarChart chart_id="store_reviews" chart_class="icz-risalescardchart" Ytitle={"(value in K)"} metric_1={"No. of Reviews"} graph-data={this.state.storedata} bar_color={"#5a75f9"} />}
            />,
            
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Store Competitors"
              chart={<RiSalesHBarChart chart_id="store_compititors" chart_class="icz-risalescardchart" Ytitle={"(value in K)"} metric_1={"No. of Stores"} graph-data={this.state.compititorsdata} bar_color={"#5a75f9"} />}
            />
        ]}>
        </Carousel>

      </div>
    );
  }

}

