import Wrapper from "../../components/helpers/Wrapper";

const ThumpsDownIcon = (props) => {
  return (
    <Wrapper>
      <svg className={props.className} width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4258 7.75307L8.69902 13.2783C8.15666 13.2783 7.63651 13.0843 7.253 12.7389C6.86949 12.3935 6.65403 11.925 6.65403 11.4366L6.65403 8.98077L2.79552 8.98279C2.59792 8.98481 2.4022 8.94811 2.22191 8.87523C2.04163 8.80236 1.88109 8.69505 1.75142 8.56075C1.62175 8.42645 1.52605 8.26837 1.47096 8.09745C1.41586 7.92654 1.40269 7.74687 1.43235 7.57092L2.37349 2.04364C2.42279 1.7509 2.5879 1.48406 2.83841 1.2923C3.08891 1.10054 3.40792 0.996768 3.73667 1.00012H11.4258M11.4258 7.75307L11.4258 1.00012M11.4258 7.75307H13.4708C13.8323 7.75307 14.1791 7.62372 14.4347 7.39348C14.6904 7.16324 14.834 6.85098 14.834 6.52537L14.834 2.22782C14.834 1.90221 14.6904 1.58994 14.4347 1.3597C14.1791 1.12946 13.8323 1.00012 13.4708 1.00012H11.4258" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Wrapper>
  );
};

export default ThumpsDownIcon;
