const IconReach = (props) => {
    return (

        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.163 40.408L21.499 33.5" strokeWidth="1.5" />
            <path d="M34.5 20.499L37.466 17.687" strokeWidth="1.5" />
            <path d="M13 27H19" strokeWidth="1.5" />
            <path d="M36 27H41" strokeWidth="1.5" />
            <path d="M27.649 13.143V18.499" strokeWidth="1.5" />
            <path d="M27.649 35.5V41.654" strokeWidth="1.5" />
            <path d="M14 13L21 20" strokeWidth="1.5" />
            <path d="M33 33L37 37" strokeWidth="1.5" />
            <path d="M39 19C40.6569 19 42 17.6569 42 16C42 14.3431 40.6569 13 39 13C37.3431 13 36 14.3431 36 16C36 17.6569 37.3431 19 39 19Z" fill="#486F88" />
            <path d="M13 45C14.6569 45 16 43.6569 16 42C16 40.3431 14.6569 39 13 39C11.3431 39 10 40.3431 10 42C10 43.6569 11.3431 45 13 45Z" fill="#486F88" />
            <path d="M13.5 15C14.8807 15 16 13.8807 16 12.5C16 11.1193 14.8807 10 13.5 10C12.1193 10 11 11.1193 11 12.5C11 13.8807 12.1193 15 13.5 15Z" fill="#486F88" />
            <path d="M38.5 41C39.8807 41 41 39.8807 41 38.5C41 37.1193 39.8807 36 38.5 36C37.1193 36 36 37.1193 36 38.5C36 39.8807 37.1193 41 38.5 41Z" fill="#486F88" />
            <path d="M43 31C45.2091 31 47 29.2091 47 27C47 24.7909 45.2091 23 43 23C40.7909 23 39 24.7909 39 27C39 29.2091 40.7909 31 43 31Z" fill="#486F88" />
            <path d="M12 31C14.2091 31 16 29.2091 16 27C16 24.7909 14.2091 23 12 23C9.79086 23 8 24.7909 8 27C8 29.2091 9.79086 31 12 31Z" fill="#486F88" />
            <path d="M27.5 14C29.433 14 31 12.433 31 10.5C31 8.567 29.433 7 27.5 7C25.567 7 24 8.567 24 10.5C24 12.433 25.567 14 27.5 14Z" fill="#486F88" />
            <path d="M27.5 35.25C32.3325 35.25 36.25 31.3325 36.25 26.5C36.25 21.6675 32.3325 17.75 27.5 17.75C22.6675 17.75 18.75 21.6675 18.75 26.5C18.75 31.3325 22.6675 35.25 27.5 35.25Z" strokeWidth="1.5" />
            <path d="M27.5 47C29.433 47 31 45.433 31 43.5C31 41.567 29.433 40 27.5 40C25.567 40 24 41.567 24 43.5C24 45.433 25.567 47 27.5 47Z" fill="#486F88" />
        </svg>


    )
}

export default IconReach;