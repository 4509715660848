import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PerformanceIcon from "../../../assets/icons/PerformanceIcon";
import AuthContext from "../../../store/authContext";
import axios from "../../helpers/axios";
import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import TextFormatter from "../../../utils/TextFormatter";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import Loader from "../loaders/Loader";
import ServerErrorsLoader from "../loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import FilterIcon from "../../../assets/icons/FilterIcon";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import {
  resetcampaignAdmin,
  setAdminCreateBucketCampaignObjectivesFilter,
  setAdminBucketPlatformandObjective
} from "../../../store/admin/campaignAdminSlice";

const CampaignPlatformObjectiveDropdown = ({ isFilterActive, filterCount }) => {
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED PLATFORMS & OBJECTIVES FROM DROPDOWN
  // const objectiveFilter = useSelector(
  //   (state) => state.campaignAdmin.adminCreateBucketCampaignObjectivesFilter
  // );

  // TO SHOW AND HIDE THE DROPDOWN ON CLICK
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const buttonClickHandler = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  // PLATFORM DATA
  const [platformsData, setPlatformsData] = useState([]);
  const [isPlatfromsDataLoading, setPlatformsDataIsLoading] = useState(false);
  const [isBrandsCategoryDataIsLoaded, setPlatformsDataIsLoaded] =
    useState(false);
  const [brandsCategoryDataError, setPlatformsDataError] = useState(null);

  // TO SET THE FIRST PLATFORM SELECTED BY DEFAULT
  const [selectedPlatform, setSelectedPlatform] = useState("");

  // SELECT PLATFORM ONCLICK
  const platformOnClickHandler = (platform) => {
    setSelectedPlatform(platform);
  };

  // OBJECTIVES DATA
  const [objectivesData, setObjectivesData] = useState([]);
  const [isObjectivesDataLoading, setObjectivesDataIsLoading] = useState(false);
  const [isObjectivesDataLoaded, setObjectivesDataIsLoaded] = useState(false);
  const [objectivesDataError, setObjectivesDataError] = useState(null);

  // CAMPAIGN SEARCH STATE
  const [objectivesSearchTerm, setObjectivesSearchTerm] = useState("");

  // FILTER OBJECTIVES LIST AS PER USER SEARCH
  const filteredObjectivesList = useMemo(() => {
    if (objectivesSearchTerm === "") return objectivesData;
    if (objectivesData === "") return objectivesData;

    const filteredList = objectivesData.filter((objective) => {
      return (
        objective.platform === selectedPlatform.toLowerCase() &&
        objective.campaign_objective
          .toLowerCase()
          .includes(objectivesSearchTerm.toLowerCase())
      );
    });
    return filteredList;
  }, [objectivesSearchTerm, objectivesData]);

  // SELECTED OBJECTIVES
  const [selectedObjectives, setSelectedObjectives] = useState([]);

  const campaignObjectiveOnClickHandler = (objective) => {

    if (selectedObjectives.some((selectedObjectives) => selectedObjectives === objective)) {

      var tempArr = selectedObjectives.filter((a) => a.campaign_objective_id !== objective.campaign_objective_id)
      setSelectedObjectives([...tempArr]);

    } else {

      var tempArr = [];
      tempArr.push(objective)
      setSelectedObjectives([...selectedObjectives, ...tempArr]);
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TO FETCH CAMPAIGNS DATA FROM API
  const fetchPlatformsDataHandler = async () => {
    setPlatformsDataIsLoading(true);
    setPlatformsDataIsLoaded(false);
    setPlatformsDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/platform/", config);

      setSelectedPlatform(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data[0] === undefined || res.data[0].length === 0
            ? []
            : res.data[0]
      );
      setPlatformsData(res.data);
      setPlatformsDataIsLoading(false);
      setPlatformsDataIsLoaded(true);
      setPlatformsDataError(null);
    } catch (error) {
      setPlatformsData([]);
      setPlatformsDataIsLoading(false);
      setPlatformsDataIsLoaded(false);
      setPlatformsDataError(error.code);
    }
  };

  // PLATFORMS LISTS
  let platformList = (
    <Wrapper>
      <Loader />
    </Wrapper>
  );

  if (isBrandsCategoryDataIsLoaded && !isPlatfromsDataLoading) {
    if (NoDataAvailableChecker(platformsData)) {
      platformList = (
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
      );
    } else {
      platformList = platformsData.map((platform, platformIndex) => {
        return (
          <Wrapper key={platformIndex}>
            <div
              className={selectedPlatform === platform ? "tab active" : "tab"}
              onClick={() => platformOnClickHandler(platform)}
            >
              <ErrorBoundary>
                <TextFormatter string={platform} />
              </ErrorBoundary>
            </div>
          </Wrapper>
        );
      });
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandsCategoryDataError) {
    platformList = (
      <Wrapper>
        <ServerErrorsLoader error={brandsCategoryDataError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlatfromsDataLoading) {
    platformList = (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TO FETCH CAMPAIGN OBJECTIVES DATA FROM API
  const fetchObjectivesDataHandler = async () => {
    setObjectivesDataIsLoading(true);
    setObjectivesDataIsLoaded(false);
    setObjectivesDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/objective/", config);

      setObjectivesData(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setObjectivesDataIsLoading(false);
      setObjectivesDataIsLoaded(true);
      setObjectivesDataError(null);
    } catch (error) {
      setObjectivesData([]);
      setObjectivesDataIsLoading(false);
      setObjectivesDataIsLoaded(false);
      setObjectivesDataError(error.code);
    }
  };

  // BRANDS LISTS
  let campaignsObjectivesList = (
    <Wrapper>
      <Loader />
    </Wrapper>
  );

  if (isObjectivesDataLoaded && !isObjectivesDataLoading) {
    if (NoDataAvailableChecker(filteredObjectivesList)) {
      campaignsObjectivesList = (
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
      );
    } else {
      campaignsObjectivesList = filteredObjectivesList.map(
        (objective, objectiveIndex) =>
          objective.platform === selectedPlatform.toLowerCase() && (
            <Wrapper key={objectiveIndex}>
              <div
                className={
                  selectedObjectives.some((obj) => obj === objective) === true
                    ? "tab active"
                    : "tab"
                }
                onClick={() => campaignObjectiveOnClickHandler(objective)}
              >
                <ErrorBoundary>
                  <TextFormatter string={objective.campaign_objective} />
                </ErrorBoundary>
              </div>
            </Wrapper>
          )
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (objectivesDataError) {
    campaignsObjectivesList = (
      <Wrapper>
        <ServerErrorsLoader error={objectivesDataError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isObjectivesDataLoading) {
    campaignsObjectivesList = (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  const cancelButtonOnclickHandler = () => {
    setDropdownVisible(false);
  };

  const clearButtonOnclickHandler = () => {
    setDropdownVisible(false);
    setSelectedObjectives([]);

    dispatch(setAdminCreateBucketCampaignObjectivesFilter([]));
    dispatch(setAdminBucketPlatformandObjective([]))

    resetcampaignAdmin();
  };

  const applyButtonOnclickHandler = () => {
    setDropdownVisible(false);
    dispatch(setAdminCreateBucketCampaignObjectivesFilter(selectedObjectives));
    dispatch(setAdminBucketPlatformandObjective([
      ...new Set(selectedObjectives.map((item) => ({ "campaign_objective_id": item.campaign_objective_id, "platform": item.platform }))),
    ]))
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchPlatformsDataHandler();
    fetchObjectivesDataHandler();
  }, [selectedDates]);

  return (
    <Wrapper>
      <div className="brands_dropdown_container">
        <div
          className={
            isFilterActive === true
              ? "filter_active brands_dropdown"
              : "brands_dropdown"
          }
        >
          <Button
            icon={<FilterIcon className={"btn_icon"} />}
            notificationNumber={filterCount}
            className={
              isDropdownVisible === true
                ? "secondary_btn active"
                : "secondary_btn"
            }
            type="button"
            handleClick={() => buttonClickHandler()}
          ></Button>
        </div>
        {isDropdownVisible && (
          <div className="brands_dropdown_wrapper">
            <div className="grid grid_cols_2 brands_dropdown_section">
              <div className="grid col_span_1 brands_container">
                <div className="header">Platform</div>
                <div className="body">{platformList}</div>
              </div>
              <div className="grid col_span_1 campaigns_container">
                <div className="header">Objectives</div>
                <div id="campaign_objectives_body" className="body">
                  <div className="filter">
                    <input
                      className="form_control input"
                      type="text"
                      autoComplete="off"
                      placeholder="Search Campaign Objectives"
                      onChange={(event) => {
                        setObjectivesSearchTerm(event.target.value);
                      }}
                    />
                  </div>
                  <div className="tabs_container">

                    {/* {campaignsObjectivesList.every(
                      (val, i, arr) => val === arr[0]
                    ) ? (
                      <NoDataAvailableLoader />
                    ) : (
                      campaignsObjectivesList
                    )} */}

                    {campaignsObjectivesList}
                  </div>
                </div>
              </div>
            </div>
            <div className="brands_dropdown_footer">
              <div className="">
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => cancelButtonOnclickHandler()}
                    className="secondary_danger_btn"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="flex">
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => clearButtonOnclickHandler()}
                    className="secondary_btn"
                  >
                    Clear
                  </Button>
                </div>
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => applyButtonOnclickHandler()}
                    className="primary_btn"
                  // disabled={selectedAds.length === 0 ? true : false}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};
export default CampaignPlatformObjectiveDropdown;
