export const headerFormatter = [
  {
    value: "Ad Name",
    label: "ad_name",
  },
  {
    label: "total_video_views",
    value: "3 Second Video Views",
  },
  {
    label: "total_video_views_unique",
    value: "3 Second Unique Video Views",
  },
  {
    label: "total_video_10s_views",
    value: "10 Second Video Views",
  },
  {
    label: "total_video_15s_views",
    value: "15 Second Video Views",
  },
  {
    label: "total_video_30s_views",
    value: "30 Second Video Views",
  },
  {
    label: "total_video_complete_views",
    value: "Complete Video Views",
  },
  {
    label: "total_video_10s_views_unique",
    value: "10 Second Unique Video Views",
  },
  {
    label: "total_video_30s_views_unique",
    value: "30 Second Unique Video Views",
  },
  {
    label: "total_video_complete_views_unique",
    value: "Complete Unique Video Views",
  },
  {
    label: "xaxis_label",
    value: "Time of the Day",
  },
  {
    label: "yaxis_label",
    value: "Day of the Week",
  },
  {
    label: "yaxis_label",
    value: "Day of the Week",
  },
  {
    label: "day_of_week",
    value: " ",
  },
  {
    label: "label",
    value: "Label",
  },
  {
    label: "value",
    value: "Value",
  },
  {
    label: "currentValue",
    value: "Current Value",
  },
  {
    label: "startDate",
    value: "Start Date",
  },
  {
    label: "endDate",
    value: "End Date",
  },
  {
    label: "prevStartDate",
    value: "Previous Start Date",
  },
  {
    label: "prevEndDate",
    value: "Previous End Date",
  },
  {
    label: "previousValue",
    value: "Previous Value",
  },
  {
    label: "likes",
    value: "Likes",
  },
  {
    label: "unlikes",
    value: "Unlikes",
  },
  {
    label: "dislikes",
    value: "Dislikes",
  },
  {
    label: "comments",
    value: "Comments",
  },
  {
    label: "shares",
    value: "Shares",
  },
  {
    label: "clicks",
    value: "Clicks",
  },
  {
    label: "reactions",
    value: "Reactions",
  },
  {
    label: "platform",
    value: "Platform",
  },
  {
    label: "caption",
    value: "Caption",
  },
  {
    label: "id",
    value: "ID",
  },
  {
    label: "post_link",
    value: "Post Link",
  },
  {
    label: "post_urn",
    value: " ",
  },
  {
    label: "image",
    value: "Image",
  },
  {
    label: "time",
    value: "Time",
  },
  {
    label: "hashtag",
    value: "Hashtag",
  },
  {
    label: "channel_name",
    value: "Channel Name",
  },
  {
    label: "data_date",
    value: "Date",
  },
  {
    label: "channel_logo",
    value: "Logo",
  },
  {
    label: "link",
    value: "Link",
  },
  {
    label: "subscriber",
    value: "Subscriber",
  },
  {
    label: "sac",
    value: "SAC",
  },
  {
    label: "rs",
    value: "RS",
  },
  {
    label: "videos",
    value: "Videos",
  },
  {
    label: "push_score",
    value: "Push Score",
  },
  {
    label: "views",
    value: "Views",
  },
  {
    label: "net_audience_views",
    value: "Net Audience Views",
  },
  {
    label: "tofe",
    value: "TOFE",
  },
  {
    label: "pes",
    value: "PES",
  },
  {
    label: "name",
    value: "Name",
  },
  {
    label: "views_in_minutes",
    value: "Views in Minutes",
  },
  {
    label: "thumbnail",
    value: "Thumbnail",
  },
  {
    label: "post_description",
    value: "Post Description",
  },
  {
    label: "percentage_growth",
    value: "Growth Percentage",
  },
  {
    label: "duration",
    value: "Duration",
  },
  {
    label: "total_views",
    value: "Total Views",
  },
  {
    label: "total_video_60s_excludes_shorter_views",
    value: "Above 60 Second Video Views",
  },
  {
    label: "total_video_views_sound_on",
    value: "Sound On Video Views",
  },
  {
    label: "total_video_views_clicked_to_play",
    value: "Clicked to Play Video Views",
  },
  {
    label: "total_video_views_autoplayed",
    value: "Autoplayed Video Views",
  },
  {
    label: "video_completion_rate",
    value: "Video Completion Rate",
  },
  {
    label: "returning_views",
    value: "Video Returning Views",
  },
  {
    label: "audience_retention_rate",
    value: "Video Retention Rate",
  },
  {
    label: "super_viral_rank",
    value: "Super Viral Rank",
  },
  {
    label: "resonance_strength",
    value: "Resonance Strength",
  },
  {
    label: "viral_grade",
    value: "Viral Grade",
  },
  {
    label: "total_no_of_videos",
    value: "Total No. of Videos",
  },
  {
    label: "no_of_videos_previous",
    value: "No of Videos Previous Period",
  },
  {
    label: "category",
    value: "Category",
  },
  {
    label: "view_count",
    value: "View Count",
  },
  {
    label: "total_videos",
    value: "Total Videos",
  },
  {
    label: "completion_rate",
    value: "Completion Rate",
  },
  {
    label: "no_of_views",
    value: "No. of Views",
  },
  {
    label: "spend",
    value: "Spends",
  },
  {
    label: "grp",
    value: "GRPs",
  },
  // {
  //   label: "GRPs",
  //   value: "GRPs",
  // },
  {
    label: "sales_value",
    value: "Sales Value",
  },

  { value: "Campaign Name", label: "campaign_name" },
  { value: "Creative Name", label: "creative" },
  { value: "GRP", label: "grp" },
  { value: "Spends", label: "spends" },
  { value: "CPM", label: "cpm" },
  { value: "Impressions", label: "impressions" },
  { value: "CPRP", label: "cprp" },
  { value: "Prime Time Share", label: "prime_time" },
  { value: "HSM Share", label: "hsm_share" },
  {
    label: "brand_id",
    value: "Brand ID",
  },
  {
    label: "brand_name",
    value: "Brand Name",
  },
  {
    label: "growth_percentage",
    value: "Growth Percentage",
  },
  {
    label: "level",
    value: "Level",
  },
  {
    label: "non_hsm",
    value: "Non HSM",
  },
  {
    label: "hsm",
    value: "HSM",
  },
  {
    label: "brand_logo",
    value: "Brand Logo",
  },
  {
    label: "impression",
    value: "Impression",
  },
  {
    label: "sales_volume",
    value: "Sales Volume",
  },
  {
    label: "color",
    value: "Color",
  },
  {
    label: "growth",
    value: "Growth",
  },
  {
    label: "state_code",
    value: "State Code",
  },
  {
    label: "zone_name",
    value: "Zone Name",
  },
  {
    label: "is_company",
    value: " ",
  },
  {
    label: "sale_volume",
    value: "Sales Volume",
  },
  {
    label: "market",
    value: "Market",
  },
  {
    label: "grps",
    value: "GRPs",
  },
  { value: "State", label: "state_name" },
  { value: "Sales Vol. Change", label: "sales_volume_percent" },
  { value: "Sales Val. Change", label: "sales_value_percent" },
  { value: "Market Share", label: "market_share" },

  { value: "Country", label: "country" },
  { value: "Share of Spends", label: "share_of_spends" },
  { value: "Share of Voice", label: "share_of_voice" },
  { value: "State Image", label: "state_image" },
  { value: "Impressions Count", label: "impression_count" },
  { value: "Engagement Count", label: "engangment_count" },
  { value: "Engagement Rate", label: "engangment_rate" },
  { value: "Current Value", label: "current_value" },
  { value: "Previous Value", label: "previous_value" },
  { value: "Previous Date", label: "previous_date" },
  { value: "Average Duration", label: "avg_duration" },
  { value: "PT", label: "pt" },
  { value: "Non-PT", label: "non_pt" },
  { value: "Premium", label: "premium" },
  { value: "Non-Premium", label: "non_premium" },
  { value: "Unique Markets", label: "unique_markets" },

  { value: "Sessions", label: "sessions" },
  { value: "Users", label: "user" },
  { value: "Bounce Rate", label: "bounceRate" },

  { value: "New Users", label: "newUser" },
  { value: "Goal Conversion Rate", label: "goalConversionRateAll" },
  { value: "Channel Grouping", label: "channelGrouping" },

  { value: "Goal Value", label: "goalValueAll" },
  { value: "Goal Completions", label: "goalCompletionsAll" },
  { value: "New Sessions", label: "newSession" },
  { value: "Avg Session Duration", label: "avgSessionDuration" },
  { value: "Page Views Per Session", label: "pageviewsPerSession" },
  { value: "Facebook", label: "facebook" },
  { value: "Instagram", label: "instagram" },
  { value: "Objective", label: "objective" },
  { value: "Conversions", label: "conversions" },
  { value: "CTR", label: "ctr" },
  { value: "CPC", label: "cpc" },
  { value: "CPV", label: "cpv" },
  { value: "CTC", label: "ctc" },

  { value: "CR", label: "cr" },
  { value: "Engagement Rate", label: "engagement_rate" },

  { value: "Total Engagement", label: "total_engagement" },
  { value: "View Rate", label: "view_rate" },
  { value: "Reach Rate", label: "reach_rate" },
  { value: "Reach", label: "reach" },
  { value: "Frequency", label: "frequency" },
  { value: "Benchmark View Rate", label: "benchmark_view_rate" },
  { value: "Benchmark CPV", label: "benchmark_cpv" },
  { value: "Benchmark CPC", label: "benchmark_cpc" },
  { value: "Benchmark CPM", label: "benchmark_cpm" },
  { value: "Click Conversion", label: "click_conversion" },
  { value: "Leads", label: "lead" },
  { value: "Leads", label: "leads" },
  { value: "Video Views", label: "video_views" },
  { value: "Cost Per Conversion", label: "cost_per_conversion" },
  { value: "App Install", label: "app_install" },
  { value: "App Install", label: "app-install" },
  { value: "Engagement", label: "engagement" },
  { value: "No. of Campaigns", label: "no_of_campaign" },
  { value: "User Likes", label: "likes_users" },
  { value: "Video Count", label: "video_count" },
  { value: "Video 10s Views", label: "video_views_10s_total" },
  { value: "Comment Count", label: "comment_count" },
  { value: "Post Clicks", label: "post_clicks" },
  { value: "Current Growth", label: "currentGrowth" },
  { value: "Followers", label: "followers" },
  { value: "Followers", label: "follower" },
  { value: "Saved", label: "saved" },
  { value: "No. of Videos", label: "no_of_videos" },
  { value: "Label 2", label: "childLabel" },
  { value: "Value 2", label: "childValue" },
  { value: "Value 1", label: "value1" },
  { value: "Value 2", label: "value2" },
  { value: "Value 3", label: "value3" },

  { value: "Followers Gained", label: "follower_gained" },
  { value: "Followers Lost", label: "follower_lost" },
  { value: "Members", label: "members" },
  { value: "Tweet", label: "tweet" },
  { value: "Timestamp", label: "timestamp" },
  { value: "Retweets", label: "retweets" },
  { value: "Replies", label: "replies" },
  { value: "Profile Image", label: "profile_image_url" },
  { value: "Mentions", label: "mentions" },
  { value: "Engagements", label: "engagements" },
  { value: "Description", label: "description" },
  { value: "Follower Twitter ID", label: "follower_twitter_user_id" },

  { value: "Following", label: "following" },
  { value: "Handle Name", label: "handle_name" },
  { value: "Ratio", label: "ratio" },

  { value: "Tweets", label: "tweets" },
  { value: "Username", label: "username" },
  { value: "Organic Impressions", label: "organic_impressions" },

  { value: "Paid Impressions", label: "paid_impressions" },
  { value: "Twitter Media Type", label: "twitter_media_type" },
  { value: "Difference", label: "difference" },
  { value: "Quote Tweets", label: "quote_tweets" },
  { value: "Total", label: "total" },
  { value: "Current Tweet Count", label: "current_tweet_count" },
  { value: "Previous Tweet Count", label: "previous_tweet_count" },
  { value: "Source", label: "source" },
  { value: "Date", label: "date_date" },
  { value: "Date", label: "date" },
  { value: "Label", label: "title" },
  { value: "Users", label: "users" },
  { value: "New Sessions %", label: "percentNewSessions" },
  { value: "New Users", label: "newUsers" },
  { value: "Goal Value", label: "goalValue" },
  { value: "Goal Conversion Rate", label: "goalConversionRate" },
  { value: "Goal Completions", label: "goalCompletions" },
  { value: "Browser", label: "browser" },
  { value: "City", label: "city" },
  { value: "State Name", label: "state_name" },

  { value: "Landing Page", label: "ga:landingPagePath" },
  { value: "Sessions", label: "ga:sessions" },
  { value: "New Sessions %", label: "ga:percentNewSessions" },
  { value: "Users", label: "ga:users" },
  { value: "New User", label: "ga:newUsers" },
  { value: "Bounce Rate", label: "ga:bounceRate" },
  { value: "Pages/Session", label: "ga:pageviewsPerSession" },
  { value: "Avg. Session Duration", label: "ga:avgSessionDuration" },
  { value: "Organic Searches", label: "ga:organicSearches" },

  { value: "Page Views", label: "ga:pageviews" },
  { value: "Page Views Per Session", label: "ga:pageviewsPerSession" },
  { value: "Goal Conversion Rate", label: "ga:goalConversionRateAll" },

  { value: "Source", label: "ga:source" },
  { value: "Category", label: "ga:eventCategory" },
  { value: "Event Action", label: "ga:eventAction" },
  { value: "Total Events", label: "ga:totalEvents" },
  { value: "Unique Events", label: "ga:uniqueEvents" },
  { value: "Event Value", label: "ga:eventValue" },

  { value: "Genre", label: "genre" },
  { value: "Ad Duration", label: "ad_duration" },
  { value: "Twitter", label: "twitter" },

  { value: "Linkedin", label: "linkedin" },
  { value: "Google", label: "google" },
  { value: "From", label: "from" },
  { value: "To", label: "to" },
  { value: "Youtube", label: "youtube" },
  { value: "Current Date", label: "current_date" },
  { value: "City", label: "city_name" },
  { value: "Content Type", label: "content_type" },
  { value: "Engagement Type", label: "engagement_type" },
  { value: "Actions", label: "actions" },
  { value: "Reactions", label: "reactions" },
  { value: "Device Avg.", label: "device_avg" },
  { value: "Avg. Value", label: "avg_value" },
  { value: "Device Type", label: "device" },

  { value: "Industry", label: "industry" },
  { value: "Designation", label: "designation" },
  { value: "Object Type", label: "object_type" },
  { value: "Platform Name", label: "platform_name" },
  { value: "Duration", label: "durations" },
  { value: "Subscribers", label: "subscribers" },
  { value: "Age", label: "age" },
  { value: "CPE", label: "cpe" },
  { value: "Gender", label: "gender" },

  { value: "Product Name", label: "productName" },
  { value: "Quantity", label: "itemQuantity" },
  { value: "Unique Purchases", label: "uniquePurchases" },
  { value: "Revenue", label: "itemRevenue" },
  { value: "Revenue / Item", label: "revenuePerItem" },
  { value: "Items / Purchase", label: "itemsPerPurchase" },
  { value: "DV 360", label: "dv 360" },
  { value: "Avg. Time", label: "avg" },

  { label: "video_play_actions", value: "Play Actions" },
  { label: "video_p25_watched_actions", value: "25% Watched Actions" },
  { label: "video_p50_watched_actions", value: "50% Watched Actions" },
  { label: "video_p75_watched_actions", value: "75% Watched Actions" },
  { label: "video_p95_watched_actions", value: "95% Watched Actions" },
  { label: "video_p100_watched_actions", value: "100% Watched Actions" },

  { label: "avg-time", value: "Avg. Time" },
  { label: "video-play-actions", value: "Play Actions" },
  { label: "video-p25-watched-actions", value: "25% Watched Actions" },
  { label: "video-p50-watched-actions", value: "50% Watched Actions" },
  { label: "video-p75-watched-actions", value: "75% Watched Actions" },
  { label: "video-p95-watched-actions", value: "95% Watched Actions" },
  { label: "video-p100-watched-actions", value: "100% Watched Actions" },

  { label: "video_content_starts", value: "Video Starts" },
  { label: "video_6s_views", value: "6s Video Views" },
  { label: "video_views_50", value: "50% Video Views" },
  { label: "video_views_100", value: "100% Video Views" },
  { label: "video starts", value: "Video Starts" },
  { label: "6s video views", value: "6s Video Views" },
  { label: "50% video views", value: "50% Video Views" },
  { label: "100% video views", value: "100% Video Views" },
  { value: "Ad", label: "ad" },
  { value: "Campaign", label: "campaign" },
  { value: "Ad group", label: "ad group" },
  { value: "Ad Group Name", label: "adgroup_name" },
  { value: "Keywords", label: "keywords" },
  { value: "Campaign Group", label: "campaign group" },

  { value: "VIDEO VIEWS", label: "video_views" },
  { value: "WEBSITE CLICKS", label: "website_clicks" },
  { value: "OUTCOME AWARENESS", label: "outcome_awareness" },
  { value: "OUTCOME AWARENESS", label: "outcome_engagement" },
  { value: "OUTCOME TRAFFIC", label: "outcome_traffic" },

  { value: "Follow", label: "follow" },
  { value: "Unfollow", label: "unfollow" },

  { value: "Complete Views", label: "complete_views" },
  { value: "Device Type", label: "device_type" },

  { value: "Masthead Ad", label: "Masthead_ad" },
  { value: "First Quartile", label: "first_quartile" },
  { value: "Mid Point", label: "mid_point" },
  { value: "Third Quartile", label: "third_quartile" },
  { value: "Bumper Ad", label: "Bumper_ad" },
  { value: "Non-skippable", label: "Non_skippable" },
  { value: "Non-skippable", label: "Non-skippable" },
  { value: "Skippable", label: "Skippable" },
  { value: "State", label: "state" },
  { value: "City", label: "City" },
  { value: "Spends", label: "Spends" },

  { value: "Complete Views", label: "Complete Views" },
  { value: "Views", label: "Views" },
  { value: "VTR", label: "VTR" },
  { value: "VTR", label: "vtr" },
  { value: "CVTR", label: "CVTR" },
  { value: "CVTR", label: "cvtr" },
  { value: "CPV", label: "CPV" },
  { value: "CPV", label: "cpv" },
  { value: "CPCV", label: "CPCV" },
  { value: "CPCV", label: "cpcv" },
  { value: "Impression", label: "Impression" },
  { value: "VTR", label: "VTR" },
  { value: "VTR", label: "vtr" },
  { value: "CVTR", label: "CVTR" },
  { value: "CVTR", label: "cvtr" },
  { value: "Benchmark 1", label: "benchmark1" },
  { value: "Benchmark 2", label: "benchmark2" },

  { value: "Overall Views", label: "overall_views" },
  { value: "Minutes Watched", label: "minutes_watched" },

  { value: "Red Minute Watched", label: "red_minute_watched" },
  { value: "Red Views", label: "red_views" },
  { value: "Overall Retention", label: "overall-retention" },
  { value: "Relative Retention", label: "relative-retenation" },
  { value: "Tweet Length", label: "tweet_length" },

  // { value: "Impressions", label: "Impressions" },
  // { value: "Clicks", label: "Clicks" },
  // { value: "Comments", label: "Comments" },
  { value: "Share of Voice", label: "Share of Voice" },
  { value: "Conversions", label: "Conversions" },
  { value: "Spends", label: "Spends" },

  { value: "Platform", label: "Platform" },
  { value: "Sales vol.", label: "Sales vol." },
  { value: "Sales Vol.", label: "Sales Vol." },
  // { value: "Monthly Sales Vol.", label: "Monthly Sales Vol." },
  // { value: "Monthly Sales Vol. Change", label: "Monthly Sales Vol. Change" },

  { value: "Growth Percentage", label: "Growth Percentage" },
  // { value: "Market", label: "Market" },
  // { value: "Market Code", label: "Market Code" },
  { value: "Market Code", label: "market_code" },


  { value: "CONTENT : Impressions", label: "CONTENT : Impressions" },
  { value: "CONTENT : Clicks", label: "CONTENT : Clicks" },
  { value: "CONTENT : Conversions", label: "CONTENT : Conversions" },
  { value: "CONTENT : Spends", label: "CONTENT : Spends" },

  { value: "MIXED : Impressions", label: "MIXED : Impressions" },
  { value: "MIXED : Clicks", label: "MIXED : Clicks" },
  { value: "MIXED : Conversions", label: "MIXED : Conversions" },
  { value: "MIXED : Spends", label: "MIXED : Spends" },

  { value: "SEARCH : Impressions", label: "SEARCH : Impressions" },
  { value: "SEARCH : Clicks", label: "SEARCH : Clicks" },
  { value: "SEARCH : Conversions", label: "SEARCH : Conversions" },
  { value: "SEARCH : Spends", label: "SEARCH : Spends" },

  {
    value: "SEARCH-PARTNERS : Impressions",
    label: "SEARCH-PARTNERS : Impressions",
  },
  { value: "SEARCH-PARTNERS : Clicks", label: "SEARCH-PARTNERS : Clicks" },
  {
    value: "SEARCH-PARTNERS : Conversions",
    label: "SEARCH-PARTNERS : Conversions",
  },
  { value: "SEARCH-PARTNERS : Spends", label: "SEARCH-PARTNERS : Spends" },

  {
    value: "YOUTUBE-SEARCH : Impressions",
    label: "YOUTUBE-SEARCH : Impressions",
  },
  { value: "YOUTUBE-SEARCH : Clicks", label: "YOUTUBE-SEARCH : Clicks" },
  {
    value: "YOUTUBE-SEARCH : Conversions",
    label: "YOUTUBE-SEARCH : Conversions",
  },
  { value: "YOUTUBE-SEARCH : Spends", label: "YOUTUBE-SEARCH : Spends" },

  {
    value: "YOUTUBE-WATCH : Impressions",
    label: "YOUTUBE-WATCH : Impressions",
  },
  { value: "YOUTUBE-WATCH : Clicks", label: "YOUTUBE-WATCH : Clicks" },
  {
    value: "YOUTUBE-WATCH : Conversions",
    label: "YOUTUBE-WATCH : Conversions",
  },
  { value: "YOUTUBE-WATCH : Spends", label: "YOUTUBE-WATCH : Spends" },

  { value: "Average View Duration", label: "average-view-duration" },
  { value: "No. of Views", label: "number-of-views" },
  { value: "No. of Views", label: "no-of-views" },
  { value: "TOM", label: "tom" },
  { value: "SPONT", label: "spont" },
  { value: "Consideration T2b", label: "consideration_t2b" },
  { value: "App / URL", label: "app_url" },

  { value: "In Feed Video Ad", label: "In_feed_video_ad" },
  { value: "First Quartile VTR", label: "first_quartile_vtr" },

  { value: "Midpoint Quartile VTR", label: "midpoint_quartile_vtr" },
  { value: "Third Quartile VTR", label: "third_quartile_vtr" },
  { value: "Complete Quartile VTR", label: "complete_quartile_vtr" },

  { value: "Benchmark 1:Average", label: "benchmarking_1_avg" },

  { value: "Actual Impressions", label: "actual_impressions" },
  { value: "Planned Impressions", label: "planned_impressions" },
  { value: "Actual Spends", label: "actual_spends" },
  { value: "Planned Spends", label: "planned_spends" },
  { value: "Youtube Viral Grade", label: "yt_viral_grade" },
  { value: "No. of Posts", label: "no_of_posts" },
  { value: "Bounce Rate", label: "bounce_rate" },

  { value: "Total Campaign", label: "total_campaign" },
  { value: "Sales", label: "sales" },
  { value: "Orders", label: "orders" },
  { value: "ACOS", label: "acos" },
  { value: "NTB Units", label: "ntb_units" },
  {
    value: "Total New To Purchases Clicks",
    label: "total_new_to_brand_purchases_clicks",
  },
  { value: "Campaign Type", label: "campaign_type" },
  { value: "Brand Name", label: "sub_brand" },
  { value: "ATC", label: "atc" },
  { value: "ATCR", label: "atcr" },
  { value: "Click Throughs", label: "click_throughs" },

  { value: "DPV", label: "dpv" },
  { value: "DPVR", label: "dpvr" },
  { value: "Purchases", label: "purchases" },
  { value: "ROAS", label: "roas" },
  { value: "Units Sold", label: "units_sold" },
  { value: "Conversion Rate", label: "conversion_rate" },

  { value: "True View", label: "trueview" },
  { value: "Complete Video Views", label: "complete_views_video" },
  { value: "Total Conversions", label: "total_conversions" },
  { value: "Content Position", label: "content_position" },
  { value: "Total Conversions", label: "Total Conversions" },

  { value: "View Rate", label: "trueview_rate" },
  { value: "Rich Media Engagement", label: "rich_media_engagement" },
  { value: "Complete View Rate", label: "complete_view_rate" },
  { value: "Benchmark View Rate", label: "benchmark_view_rate" },
  { value: "Benchmark CPC", label: "benchmark_cpc" },
  { value: "Benchmark CPM", label: "benchmark_cpm" },
  { value: "Gmail Conversions", label: "gmail_conversions" },
  { value: "Media Type", label: "media_type" },
  { value: "Line Item", label: "line item" },

  { value: "Unique User", label: "uniqueUser" },
  { value: "Returning User", label: "returningUser" },
  { value: "Page Views", label: "pageviews" },
  { value: "Followers Count", label: "followers_count" },
  { value: "Post Impressions", label: "post_impressions" },

  { value: "Mention Count", label: "mention_count" },
  { value: "Positive Mention Count", label: "positive_mention_count" },
  { value: "Negative Mention Count", label: "negative_mention_count" },
  { value: "Sentiment", label: "sentiment" },
  { value: "Word", label: "word" },

  { value: "Bucket", label: "bucket" },
  { value: "End Date", label: "end_date" },
  { value: "Platform Campaign ID", label: "platform_campaign_id" },
  { value: "Platform Campaign Name", label: "platform_campaign_name" },
  { value: "Start Date", label: "start_date" },

  { value: "Campaign Count", label: "count" },
  { value: "Campaign Start Date", label: "campaign_start_date" },
  { value: "Campaign End Date", label: "campaign_end_date" },

  { value: "TV Spends", label: "tv_spends" },
  { value: "Digital Spends", label: "digital_spends" },
  { value: "Market Share", label: "market_share" },

  { value: "Monthly Sales Vol.", label: "monthly_sales_vol" },

  { value: "TOM", label: "tom" },
  { value: "Consideration T2B", label: "consideration_t2b" },
  { value: "SPONT Value", label: "spont" },
  { value: "TV GRPs", label: "tv_grp" },
  { value: "TV SOV", label: "tv_sov" },
  { value: "Reach 1+", label: "reach_1" },
  { value: "Digital Spends", label: "digital_spend" },
  { value: "Label 1", label: "label1" },

  { value: "TV Spends Change %", label: "tv_spends_change" },
  // { value: "TV GRP", label: "tv_grp" },
  { value: "TV GRP Change %", label: "tv_grp_change" },

  { value: "Total Spend %", label: "total_spend_percent" },
  { value: "Total Sales Vol. %", label: "total_monthly_sales_vol_percent" },

  { value: "Monthly Sales Vol. Change %", label: "monthy_sales_vol_change" },

  { value: "All Digital Impressions", label: "all_digital_impressions" },
  {
    value: "All Digital Impressions Change (%)",
    label: "all_digital_impressions_change",
  },
  { value: "All Digital Spends", label: "all_digital_spends" },
  {
    value: "All Digital Spends Change (%)",
    label: "all_digital_spends_change",
  },

  { value: "Digital Meta Impressions", label: "digital_meta_impressions" },
  {
    value: "Digital Meta Impressions Change (%)",
    label: "digital_meta_impressions_change",
  },
  { value: "Digital Meta Spends", label: "digital_meta_spends" },
  {
    value: "Digital Meta Spends Change (%)",
    label: "digital_meta_spends_change",
  },

  { value: "Digital DV360 Impressions", label: "digital_dv360_impressions" },
  {
    value: "Digital DV360 Impressions Change (%)",
    label: "digital_dv360_impressions_change",
  },
  { value: "Digital DV360 Spends", label: "digital_dv360_spends" },
  {
    value: "Digital DV360 Spends Change (%)",
    label: "digital_dv360_spends_change",
  },

  { value: "TV + Digital Impressions", label: "tv_digital_impressions" },
  {
    value: "TV + Digital Impressions Change (%)",
    label: "tv_digital_impressions_change",
  },
  { value: "TV + Digital Spends", label: "tv_digital_spends" },
  {
    value: "TV + Digital Spends Change (%)",
    label: "tv_digital_spends_change",
  },

  { value: "Monthly Sales Vol.", label: "monthly_sale_volume" },

  { value: "Names", label: "names" },

  {
    value: "Digital Meta Impressions %",
    label: "digital_meta_impressions_percent",
  },
  {
    value: "Digital DV360 Impressions %",
    label: "digital_dv360_impressions_percent",
  },
  { value: "TV Impressions %", label: "tv_impressions_percent" },
  { value: "Digital Meta Spends %", label: "digital_meta_spends_percent" },
  { value: "Digital DV360 Spends %", label: "digital_dv360_spends_percent" },
  { value: "TV Spends %", label: "tv_spends_percent" },

  { value: "Monthly Sales Vol. Change %", label: "monthly_sales_vol_change" },

  { value: "Video Length", label: "Video Length" },
  {
    value: "Campaign Name",
    label: "campaign_names",
  },
  {
    value: "Region",
    label: "region",
  },
  {
    value: "Start Date",
    label: "start_date",
  },
  {
    value: "End Date",
    label: "end_date",
  },
  {
    value: "Planned Impressions",
    label: "planned_impressions",
  },
  {
    value: "Delivered Impressions",
    label: "delivered_impressions",
  },
  {
    value: "% Achieved Impressions",
    label: "achieved_impressions",
  },
  {
    value: "Planned CTR %",
    label: "planned_ctr",
  },
  {
    value: "Delivered CTR %",
    label: "delivered_ctr",
  },
  {
    value: "% Achieved CTR",
    label: "achieved_ctr",
  },
  {
    value: "Planned VTR %",
    label: "planned_vtr",
  },
  {
    value: "Delivered VTR %",
    label: "delivered_vtr",
  },
  {
    value: "% Achieved VTR ",
    label: "achieved_vtr",
  },
  {
    value: "Planned Spends",
    label: "planned_spends",
  },
  {
    value: "Delivered Spends",
    label: "delivered_spends",
  },
  {
    value: "% Achieved Spend",
    label: "achieved_spend",
  },
  {
    value: "Channel",
    label: "channel",
  },
  {
    value: "Premium Share",
    label: "premium_share",
  },
  {
    value: "Delivered Reach",
    label: "delivered_reach",
  },
  {
    value: "Planned Reach",
    label: "planned_reach",
  },
  {
    value: "% Achieved Reach",
    label: "achieved_reach",
  },
  {
    value: "Delivered Frequency",
    label: "delivered_frequency",
  },
  {
    value: "Planned Frequency",
    label: "planned_frequency",
  },
  {
    value: "% Achieved Frequency",
    label: "achieved_frequency",
  },

  {
    value: "Landing Page",
    label: "landingPagePath",
  },

  {
    value: "(%) New Sessions",
    label: "percentage_new_sessions",
  },
  {
    value: "Page Views",
    label: "metrics/pageviews",
  },
  {
    value: "No of sessions from Organic Search Traffic",
    label: "organic_natural_search_data",
  },
  {
    value: "Landing Page",
    label: "landingPage",
  },
  {
    value: "Avg. Session Duration",
    label: "averageSessionDuration",
  },

  {
    value: "Pages/Session",
    label: "screenPageViewsPerSession",
  },
  {
    value: "Users",
    label: "totalUsers",
  },
  {
    value: "Channel Name",
    label: "firstUserDefaultChannelGrouping",
  },
  {
    value: "Device Category",
    label: "deviceCategory",
  },
  {
    value: "Source Type",
    label: "firstUserSource",
  },
  {
    value: "Event Action",
    label: "eventAction",
  },
  {
    value: "Event Category",
    label: "eventname",
  },
  {
    value: "Total Events",
    label: "eventCount",
  },
  {
    value: "Event Value",
    label: "eventValue",
  },
  {
    value: "Unique Events",
    label: "uniqueEvents",
  },
  {
    value: "TV Impressions",
    label: "tv_impression",
  },
  {
    value: "Grp Percent",
    label: "grp_percent",
  },
  {
    value: "Benchmark",
    label: "benchmark",
  },
  {
    value: "Total Views",
    label: "total_views",
  },
  {
    value: "Total Views Previous Period",
    label: "total_views_previous",
  },
  {
    value: "Platform",
    label: "platform",
  },
  {
    value: "Impressions",
    label: "impressions",
  },
  {
    value: "Medium",
    label: "medium",
  },
  {
    value: "Publishing Medium",
    label: "publishing_medium",
  },
  {
    value: "Total Engagements Previous Period",
    label: "total_engagement_previous",
  },

  {
    label: "brand",
    value: "Brand",
  },

  {
    label: "month",
    value: "Month",
  },

  {
    label: "geography",
    value: "Geography",
  },

  {
    value: "grps",
    label: "GRPS",
  },

  {
    label: "cume_rch_curve_1",
    value: "+1",
  },

  {
    label: "cume_rch_curve_2",
    value: "+2",
  },

  {
    label: "cume_rch_curve_3",
    value: "+3",
  },

  {
    label: "cume_rch_curve_4",
    value: "+4",
  },

  {
    label: "cume_rch_curve_5",
    value: "+5",
  },
  {
    value: "Geography",
    label: "geography"
  },
  {
    value: "No of TV Campaigns",
    label: "no_of_tv_campaigns"
  },


  {
    value: "No of Digital Campaigns",
    label: "digital_campaign"
  },

  {
    value: "Meta Paid Impressions",
    label: "meta_paid_impressions"
  },
  {
    value: "DV Impressions",
    label: "dv_impressions"
  },
  {
    value: "Meta Paid Reach",
    label: "meta_paid_reach"
  },
  {
    value: "PT GRPs %",
    label: "pt_grp"
  },
  {
    value: "Premium GRPs %",
    label: "premium_grp"
  },
  {
    value: "Meta Organic Reach",
    label: "meta_organic_reach"
  },
  {
    value: "Meta Organic Impressions",
    label: "meta_organic_impression"
  },
  {
    value: "VG Group",
    label: "vg_group"
  },
  {
    value: "Visits",
    label: "visits"
  },
  {
    value: "Visits with event",
    label: "visits_with_events"
  },

  {
    value: "TV Spends",
    label: "tv_Spends"
  },
  {
    value: "TV Impressions",
    label: "tv_impressions"
  },
  {
    value: "Digital Impressions",
    label: "digital_impressions"
  },
  {
    value: "Digital Conversion",
    label: "digital_conversions"
  },


  { value: "Visits % Change", label: "visits_percent_change" },
  { value: "% Total Visit", label: "percentage_of_total_visits" },
  { value: "Visit with event", label: "total_visits_with_events" },

  { value: "% of TV Spends", label: "spends_percentage" },
  { value: "% of TV Impressions", label: "impressions_percentage" },


  {
    value: "TV Spends",
    label: "tv_spends_pc"
  },
  {
    value: "Digital Meta Spends",
    label: "digital_meta_spends_pc"
  },
  {
    value: "Digital Google Spends",
    label: "digital_google_spends_pc"
  },
  {
    value: "TV Impressions",
    label: "tv_impressions_pc"
  },
  {
    value: "Digital Meta Impression",
    label: "digital_meta_impression_pc"
  },
  {
    value: "Digital Google Impressions",
    label: "digital_google_impression_pc"
  },

  {
    value: "Meta Followers",
    label: "meta_followers"
  },
  {
    value: "Meta Organic ER",
    label: "meta_organic_er"
  },
  {
    value: "Meta Paid Er",
    label: "meta_paid_er"
  },

  {
    value: "Cc Landing Page",
    label: "cc_landing_page"
  },
  {
    value: "Cc Enter Otp",
    label: "cc_enter_otp"
  },
  {
    value: "Cc Additional Details",
    label: "cc_additional_details"
  },
  {
    value: "Cc Occupational Details",
    label: "cc_occupational_details"
  },
  {
    value: "Cc Offer Page",
    label: "cc_offer_page"
  },
  {
    value: "Cc Document Upload",
    label: "cc_document_upload"
  },
  {
    value: "Cc Password Pop Up",
    label: "cc_password_pop_up"
  },
  {
    value: "Cc Personal Details",
    label: "cc_personal_details"
  },
  {
    value: "Cc Office Details",
    label: "cc_office_details"
  },
  {
    value: "Cc Card Preferances",
    label: "cc_card_preferances"
  },
  {
    value: "Cc Aadhar Details",
    label: "cc_aadhar_details"
  },
  {
    value: "Cc Aadhar Review",
    label: "cc_aadhar_review"
  },
  {
    value: "Cc T&C Page",
    label: "cc_t&c_page"
  },
  {
    value: "Cc Esign",
    label: "cc_esign"
  },
  {
    value: "Cc Video Kyc",
    label: "cc_video_kyc"
  },
  {
    value: "Cc Success / Configurations",
    label: "cc_success_configurations"
  },
  {
    value: "Company",
    label: "company"
  },
  {
    value: "First Quartile Views",
    label: "first_quartile_views"
  },
  {
    value: "Company",
    label: "company"
  },
  {
    value: "GRPs Percent",
    label: "grps_percent"
  },
  {
    value: "AD Set Name",
    label: "adset_name"
  },
  {
    value: "Min Viral Grade",
    label: "min_viral_grade"
  },
  {
    value: "Max Viral Grade",
    label: "max_viral_grade"
  },
  {
    value: "Instagram Post ID",
    label: "instagram_post_id"
  },
  {
    value: "Achieved Spends",
    label: "achieved_spends",
  },
  {
    value: "Average CPC",
    label: "avg_cpc",
  },
  {
    value: "Average CPM",
    label: "avg_cpm",
  },
  {
    value: "Ad ID",
    label: "ad_id",
  },
  {
    value: "Ad Group Name",
    label: "ad_group_name",
  },
  {
    value: "Average CPC",
    label: "average_cpc",
  },
  {
    value: "Average CPM",
    label: "average_cpm",
  },
  {
    value: "Top Impression Rate",
    label: "top_impression_rate",
  },

  {
    value: "View Through Rate",
    label: "view_through_rate",
  },
  {
    value: "Abs Top Impression Rate",
    label: "abs_top_impression_rate",
  },
  {
    value: "Landing Page",
    label: "landing_page",
  },
  {
    value: "CTR",
    label: "click_through_rate",
  },
  {
    value: "Avg. Position",
    label: "average_position",
  },
  {
    value: "Campaign Group Name",
    label: "campaign_group_name",
  },
  {
    value: "Keyword",
    label: "keyword",
  },
  {
    value: "Search Term",
    label: "search_term",
  },
  {
    value: "Bid Strategy Type",
    label: "bid_strategy_type",
  },
  {
    value: "Line Item",
    label: "line_item",
  },
  {
    value: "Creative ID",
    label: "creative_id",
  },
  {
    value: "Week",
    label: "week",
  },
  {
    value: "Bucket Name",
    label: "bucket_name",
  },
  {
    value: "Planned Clicks",
    label: "planned_clicks",
  },
  {
    value: "Delivered Clicks",
    label: "delivered_clicks",
  },
  {
    value: "Achieved Clicks",
    label: "achieved_clicks",
  },

  {
    value: "Planned Views",
    label: "planned_views",
  },
  {
    value: "Delivered Views",
    label: "delivered_views",
  },
  {
    value: "Achieved Views",
    label: "achieved_views",
  },
  {
    value: "Insertion Order ID",
    label: "io_id",
  },
  {
    value: "Campaign Status",
    label: "campaign_status",
  },
  {
    value: "Ad Type",
    label: "ad_type",
  },
  {
    value: "Video Status",
    label: "video_status",
  },
  {
    value: "Media Product Type",
    label: "media_product_type",
  },
];
