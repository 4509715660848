const IconDownload = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="#486f88" >
            <g id="Group_491" data-name="Group 491" transform="translate(-1024 -296)">
                <g id="Group_102" data-name="Group 102" transform="translate(477.519 -280.988)">
                    <path id="Path_131" data-name="Path 131" d="M253.166,192.633v6.25a3.126,3.126,0,0,1-3.125,3.126H228.164a3.126,3.126,0,0,1-3.125-3.126v-6.25" transform="translate(334.442 416.105)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <path id="Path_132" data-name="Path 132" d="M229.484,187.078l7.814,7.814,7.812-7.814" transform="translate(336.248 413.848)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <line id="Line_31" data-name="Line 31" y1="18.751" transform="translate(573.545 589.988)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </g>
            </g>
        </svg>
    )
}
export default IconDownload;