import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PerformanceIcon from "../../../assets/icons/PerformanceIcon";
import AuthContext from "../../../store/authContext";
import axios from "../../helpers/axios";
import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import TextFormatter from "../../../utils/TextFormatter";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import Loader from "../loaders/Loader";
import ServerErrorsLoader from "../loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import FilterIcon from "../../../assets/icons/FilterIcon";
import { setSelectedBrandsFromBrandsCategoryDropdown, setSelectedCategoriesFromBrandsCategoryDropdown } from "../../../store/riSalesBrandsSlice";


const SalesMarketDropdown = () => {
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // TO SHOW AND HIDE THE DROPDOWN ON CLICK
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const buttonClickHandler = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  // BRANDS CATEGORY DATA
  const [brandsCategoryData, setBrandsCategoryData] = useState([]);
  const [isBrandsCategoryDataIsLoading, setBrandsCategoryDataIsLoading] =
    useState(false);
  const [isBrandsCategoryDataIsLoaded, setBrandsCategoryDataIsLoaded] =
    useState(false);
  const [brandsCategoryDataError, setBrandsCategoryDataError] = useState(null);

  // TO SET THE FIRST BRAND CATEGORY SELECTED BY DEFAULT
  const [selectedBrandCategory, setSelectedBrandCategory] = useState([]);

  // SELECT BRANDS CATEGORY ONCLICK
  const brandCategoryOnClickHandler = (category) => {
    setSelectedBrandCategory(category);
  };

  // BRANDS DATA
  const [brandsData, setBrandsData] = useState([]);
  const [isBrandsDataLoading, setBrandsDataIsLoading] = useState(false);
  const [isBrandsDataLoaded, setBrandsDataIsLoaded] = useState(false);
  const [brandsDatasError, setBrandsDataError] = useState(null);

  // CAMPAIGN SEARCH STATE
  const [brandsSearchTerm, setBrandsSearchTerm] = useState("");

  // FILTER OBJECTIVES LIST AS PER USER SEARCH
  const filteredBrandsList = useMemo(() => {
    if (brandsSearchTerm === "") return brandsData;
    if (brandsData === "") return brandsData;

    const filteredList = brandsData.filter((objective) => {
      return (
        objective.category.toLowerCase() === selectedBrandCategory.toLowerCase() &&
        objective.brand_name
          .toLowerCase()
          .includes(brandsSearchTerm.toLowerCase())
      );
    });
    return filteredList;
  }, [brandsSearchTerm, brandsData]);

  // TO SET THE FIRST BRAND SELECTED BY DEFAULT
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);

  const brandNameOnClickHandler = (brand) => {
    if (selectedBrand.some((selectedBrand) => selectedBrand === brand)) {
      selectedBrand.splice(selectedBrand.findIndex((a) => a.brand_id === brand.brand_id), 1);
      setSelectedBrand([...selectedBrand]);
    } else {
      selectedBrand.push(brand);
      setSelectedBrand([...selectedBrand]);
    }
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TO FETCH BRANDS CATEGORY DATA FROM API
  const fetchBrandsCategoryDataHandler = async () => {
    setBrandsCategoryDataIsLoading(true);
    setBrandsCategoryDataIsLoaded(false);
    setBrandsCategoryDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/category/", config);

      setSelectedBrandCategory(res.data[0].category);
      setBrandsCategoryData(res.data);
      setBrandsCategoryDataIsLoading(false);
      setBrandsCategoryDataIsLoaded(true);
      setBrandsCategoryDataError(null);
    } catch (error) {
      setBrandsCategoryData([]);
      setBrandsCategoryDataIsLoading(false);
      setBrandsCategoryDataIsLoaded(false);
      setBrandsCategoryDataError(error.code);
    }
  };

  // BRANDS LISTS
  let brandsCategoryList = <Loader />;

  if (isBrandsCategoryDataIsLoaded && !isBrandsCategoryDataIsLoading) {
    if (
      brandsCategoryData === [] ||
      brandsCategoryData === undefined ||
      brandsCategoryData.length === 0
    ) {
      brandsCategoryList = <NoDataAvailableLoader />;
    } else {
      brandsCategoryList = brandsCategoryData.map((category, brandIndex) => {
        return (
          <div
            key={brandIndex}
            className={
              selectedBrandCategory === category.category ? "tab active" : "tab"
            }
            onClick={() => brandCategoryOnClickHandler(category.category)}
          >
            <ErrorBoundary>
              <TextFormatter string={category.category} />
            </ErrorBoundary>
          </div>
        );
      });
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandsCategoryDataError) {
    brandsCategoryList = <ServerErrorsLoader error={brandsCategoryDataError} />;
  }

  // IF DATA LOADING
  if (isBrandsCategoryDataIsLoading) {
    brandsCategoryList = <Loader />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TO FETCH BRANDS DATA FROM API
  const fetchBrandsDataHandler = async () => {
    setBrandsDataIsLoading(true);
    setBrandsDataIsLoaded(false);
    setBrandsDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/category-brand/", config);

      setBrandsData(res.data);
      setBrandsDataIsLoading(false);
      setBrandsDataIsLoaded(true);
      setBrandsDataError(null);
    } catch (error) {
      setBrandsData([]);
      setBrandsDataIsLoading(false);
      setBrandsDataIsLoaded(false);
      setBrandsDataError(error.code);
    }
  };

  // BRANDS LISTS
  let brandsList = <Loader />;

  if (isBrandsDataLoaded && !isBrandsDataLoading) {
    if (
      filteredBrandsList === [] ||
      filteredBrandsList === undefined ||
      filteredBrandsList.length === 0
    ) {
      brandsList = <NoDataAvailableLoader />;
    } else {
      brandsList = filteredBrandsList.map(
        (brand, brandIndex) =>
          brand.category === selectedBrandCategory && (
            <div
              key={brandIndex}
              className={
                selectedBrand.some(
                  (selectedBrand) =>
                    selectedBrand === brand
                ) === true
                  ? "tab active"
                  : "tab"
              }
              onClick={() => brandNameOnClickHandler(brand)}
            >
              <ErrorBoundary>
                <TextFormatter string={brand.brand_name} />
              </ErrorBoundary>
            </div>
          )
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandsDatasError) {
    brandsList = <ServerErrorsLoader error={brandsDatasError} />;
  }

  // IF DATA LOADING
  if (isBrandsDataLoading) {
    brandsList = <Loader />;
  }

  const cancelButtonOnclickHandler = () => {
    setDropdownVisible(false);
  };

  const clearButtonOnclickHandler = () => {
    setDropdownVisible(false);
    setSelectedBrand([])
    setSelectedBrandIds([]);

    dispatch(setSelectedCategoriesFromBrandsCategoryDropdown([]));
    dispatch(setSelectedBrandsFromBrandsCategoryDropdown([]));
  };

  const applyButtonOnclickHandler = () => {
    setDropdownVisible(false);

    setSelectedBrandIds([...new Set(selectedBrand.map(item => item.brand_id))]);

    dispatch(setSelectedCategoriesFromBrandsCategoryDropdown([...new Set(selectedBrand.map(item => item.category))]));
    dispatch(setSelectedBrandsFromBrandsCategoryDropdown([...new Set(selectedBrand.map(item => item.brand_id))]));
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchBrandsCategoryDataHandler();
    fetchBrandsDataHandler();
  }, [selectedDates]);

  return (
    <Wrapper>
      <div className="brands_dropdown_container">
        <div className="brands_dropdown">
          <Button
            icon={<FilterIcon className={"btn_icon"} />}
            className={
              isDropdownVisible === true
                ? "secondary_btn active"
                : "secondary_btn"
            }
            type="button"
            handleClick={() => buttonClickHandler()}
          ></Button>
        </div>
        {isDropdownVisible && (
          <div className="brands_dropdown_wrapper">
            <div className="grid grid_cols_2 brands_dropdown_section">
              <div className="grid col_span_1 brands_container">
                <div className="header">Brands Category</div>
                <div className="body">
                  {brandsCategoryList}
                </div>
              </div>
              <div className="grid col_span_1 brands_container">
                <div className="header">Brands</div>
                <div className="body">
                  <div className="filter p_10">
                    <input
                      className="form_control input"
                      type="text"
                      autoComplete="off"
                      placeholder="Search Campaign Objectives"
                      onChange={(event) => {
                        setBrandsSearchTerm(event.target.value);
                      }}
                    />
                  </div>
                  {brandsList[0] === false || brandsList[0] === true ?
                    brandsList.every(
                      (val, i, arr) => val === arr[0]
                    ) === true ? (
                      <NoDataAvailableLoader />
                    ) : (
                      brandsList
                    )
                    :
                    brandsList
                  }
                </div>
              </div>
            </div>
            <div className="brands_dropdown_footer">
              <div className="">
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => cancelButtonOnclickHandler()}
                    className="secondary_danger_btn"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="flex">
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => clearButtonOnclickHandler()}
                    className="secondary_btn"
                  >
                    Clear
                  </Button>
                </div>
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => applyButtonOnclickHandler()}
                    className="primary_btn"
                  // disabled={selectedAds.length === 0 ? true : false}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};
export default SalesMarketDropdown;
