import { useEffect, useState, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

import Wrapper from "../../components/helpers/Wrapper";
import Slider from "../../components/ui/Slider";

//import DefaultPostImg from "../../components/ui/tables/Datatable";
import {
  DefaultPostImage,
  DonutChartColorSet,
  DonutChartColorSet2,
  DonutChartColorSet3,
} from "../../constants/constants";

import DonutPieChart from "../../components/ui/charts/DonutPieChart";
import Button from "../../components/ui/Button";
import ComparePostCard from "../../components/ui/ComparePostCard";
import NumberFormatter from "../../utils/NumberFormatter";

import NoDataAvailableLoader from "./loaders/NoDataAvailable";
import ServerErrorsLoader from "./loaders/ServerErrosLoader";
import Loader from "./loaders/Loader";
import AuthContext from "../../store/authContext";
import axios from "../helpers/axios";
import NumberSeparator from "../../utils/NumberSeparator";
import ErrorBoundary from "../../utils/ErrorBoundary";
import LineChart from "./charts/LineChart";
import ExportDropdown from "./dropdown/ExportDropdown";
import SectionDefinationsBadge from "./SectionDefinationsBadge";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import Dropdown from "./dropdown/Dropdown";

const CompareMarketsSummary = ({
  products,
  Title,
  isPaid,
  isSales,
  social_platform,
  start_date,
  end_date,
  hasExportDropdown,
  exportHeaders,
  mapZone,
  mapStateCode,
  mapMarket
}) => {
  // PAGE CONSTANTS
  let pageModule = "Retail Intelligence"
  let pageSubModule = "Tv"

  const [productsList, setProductsList] = useState(products);
  // let top_5_products = productsList.slice(0, 5);

  const [isComapreBtnDisabled, setIsComapreBtnDisabled] = useState(true);

  // const [selectedProducts, setSelectedProducts] = useState([]);

  const [productsListTitle, setProductsListTitle] = useState("top_5");

  const [selectedProductsToCompare, setSelectedProductsToCompare] = useState(
    []
  );
  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // const [noOfElements, setNoOfElements] = useState(4);

  const [postIdentity, setPostIdentity] = useState([]);

  const [paidData, setPaidData] = useState([]);

  // const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  // const [viewMoreClicked, setViewMoreClicked] = useState("");

  // const [agePIEItemList, setAgePieItemList] = useState([]);
  // const [is_agegroup_loaded, isagegroupLoaded] = useState(false);

  // const [genderPIEItemList, setGenderPieItemList] = useState([]);
  // const [is_gender_loaded, isGenderLoaded] = useState(false);

  // const [devicePIEItemList, setDevicePieItemList] = useState([]);
  // const [is_device_loaded, isDeviceLoaded] = useState(false);

  const [gprsGenre, setGprsGenre] = useState([]);
  const [gprsGenreLabel, setGprsGenreLabel] = useState([]);
  const [gprsGenreTitle, setGprsGenreTitle] = useState([]);
  const [gprsGenreAllData, setGprsGenreAllData] = useState([]);

  const [gprsGenreLoading, setGprsGenreLoading] = useState(false);
  const [gprsGenreLoaded, setGprsGenreLoaded] = useState(false);
  const [gprsGenreError, setGprsGenreError] = useState(null);

  const [gprsTime, setGprsTime] = useState([]);
  const [gprsTimeLoading, setGprsTimeLoading] = useState(false);
  const [gprsTimeLoaded, setGprsTimeLoaded] = useState(false);
  const [gprsTimeError, setGprsTimeError] = useState(null);

  const [gprsPnp, setGprsPnp] = useState([]);
  const [gprsPnpLoading, setGprsPnpLoading] = useState(false);
  const [gprsPnpLoaded, setGprsPnpLoaded] = useState(false);
  const [gprsPnpError, setGprsPnpError] = useState(null);

  const [gprsPremiumNonPremium, setGprsPremiumNonPremium] = useState([]);
  const [gprsPremiumNonPremiumLoading, setGprsPremiumNonPremiumLoading] = useState(false);
  const [gprsPremiumNonPremiumLoaded, setGprsPremiumNonPremiumLoaded] = useState(false);
  const [gprsPremiumNonPremiumError, setGprsPremiumNonPremiumError] = useState(null);

  const [gprsAdDuration, setGprsAdDuration] = useState([]);
  const [gprsAdDurationLoading, setGprsAdDurationLoading] = useState(false);
  const [gprsAdDurationLoaded, setGprsAdDurationLoaded] = useState(false);
  const [gprsAdDurationError, setGprsAdDurationError] = useState(null);


  const [marketComparisonDropdownOptions, setMarketComparisonDropdownOptions] = useState([])
  const [marketComparisonDropdownOptionsLoading, setMarketComparisonDropdownOptionsLoading] = useState(false)
  const [marketAllIndiaDropdownValue, setMarketAllIndiaDropdownValue] = useState('');
  const [marketStateDropdownValue, setMarketStateDropdownValue] = useState("");

  // // TO GET THE TOP POST FROM POST IMPRESSIONS
  // const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  // let topPostStartDate;
  // let topPostEndDate;

  // if (topPostSelectedDate !== "") {
  //   topPostStartDate = topPostSelectedDate;
  //   topPostEndDate = topPostSelectedDate;
  // } else {
  //   topPostStartDate = selectedDates.startDate;
  //   topPostEndDate = selectedDates.endDate;
  // }

  // // TO GET THE DATE FROM LINECHART ONCLICK
  // const bulletClicksHandler = (data) => {
  //   setTopPostSelectedDate(data);
  // };



  // const [ageTableData, setAgeGroupTabledata] = useState([]);
  // const [isDataTableLoaded, setIsDataTableLoaded] = useState(true);
  // const [genderWiseSplitData, setGenderWiseTableSplitData] = useState([]);
  // const [isGenderDataTableLoaded, setIsGEnderDataTableLoaded] = useState(false);
  // const [deviceWiseSplitData, setdeviceWiseSplitDataTable] = useState([]);
  // const [isdeviceWiseTableLoaded, setdeviceWiseDataTableLoaded] =
  //   useState(false);
  // function formatMoney(n) {
  //   return (Math.round(n * 100) / 100).toLocaleString();
  // }

  // const [displayedGenderMetrics, setDisplayedGenderMetrics] = useState({
  //   value: "impressions",
  //   label: "Impressions",
  // });

  // const [selectCampaign, setSelectCampaign] = useState();

  const scollToRef = useRef();

  // const [checkedComparedItemList, setCheckedComparedItemList] = useState([]);

  let filtered = [];
  let newSelectedProductsToCompare = [];

  // TO HANDLE THE CHECKBOX SELECTION
  const checkboxEventHandler = (childData, event) => {
    filtered = productsList.filter((a) => a.isChecked === true);

    if (postIdentity.some(e => e.id === childData.brand_id)) {


      let index = postIdentity.indexOf(childData.brand_id);

      postIdentity.splice(index, 1);
    } else {
      // postIdentity.push(childData.brand_id);
      postIdentity.push({ id: childData.brand_id, name: childData.brand_name });
    }

    setPostIdentity([...postIdentity]);



    /*   for (let paidDataindex = 0; paidDataindex < paidData.length; paidDataindex++) {
      
      if (paidData.includes(childData.brand_id)) {
        let index = paidData.indexOf({"is_company": childData.is_company, "id": childData.brand_id});
        paidData.splice(index, 1);
       
      } else {
        paidData.push({"is_company": childData.is_company, "id": childData.brand_id});
      }
    } */

    if (
      paidData.some(
        (paidData) =>
          paidData.is_company === childData.is_company &&
          paidData.id === childData.brand_id &&
          paidData.name === childData.brand_name
      )
    ) {
      paidData.splice(
        paidData.findIndex((a) => a.id === childData.brand_id), 1
      );
      setPaidData([...paidData]);
    } else {
      paidData.push({
        is_company: childData.is_company,
        id: childData.brand_id,
        name: childData.brand_name
      });
      setPaidData([...paidData]);
    }

    if (filtered.length <= 2 || event.target.checked == false) {
      event.target.checked === true
        ? (childData.isChecked = true)
        : (childData.isChecked = false);

      if (filtered.length > 1 && event.target.checked == true) {
        productsList.map(function (product) {
          product.isChecked === true
            ? (product.isDisabled = false)
            : (product.isDisabled = true);
        });
      } else if (filtered.length >= 3 && event.target.checked == false) {
        productsList.map(function (product) {
          product.isChecked === true
            ? (product.isDisabled = false)
            : (product.isDisabled = false);
        });
      }
      setProductsList([...productsList]);
    }

    // TO MAKE COMPARE BUTTON ENABLED AND DISABLED
    if (filtered.length > 0 && event.target.checked == true) {
      setIsComapreBtnDisabled(false);
    } else if (filtered.length <= 2 && event.target.checked == false) {
      setIsComapreBtnDisabled(true);
    }
  };

  // // FOR SHOWING TOP 5 OR ALL POSTS
  // const productListHandler = () => {
  //   if (productsListTitle === "top_5") {
  //     setProductsListTitle("view_all");
  //     closeCompare();
  //   } else {
  //     setProductsListTitle("top_5");
  //     closeCompare();
  //   }
  // };

  // TO ADD PRODUCTS IN COMPARE SECTION
  const MainCompare = () => {
    newSelectedProductsToCompare = productsList.filter(
      (a) => a.isChecked === true
    );
    fetchGprsGenreHandler();
    // fetchGprsTimeHandler();
    // fetchGprsPnpHandler();
    // fetchGprsPremiumNonPremiumHandler();
    // fetchGprsAdDurationHandler();
    setSelectedProductsToCompare([...newSelectedProductsToCompare]);
    setTimeout(() => {
      scollToRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 500);
  };

  // // TO SHOW ALL PRODUCTS
  // const slice = productsList.slice(0, noOfElements);
  // const loadMore = () => {
  //   if (slice.length >= productsList.length) {
  //     setNoOfElements(4);
  //   } else {
  //     setNoOfElements(4 + noOfElements);
  //   }
  // };

  // // TO SHOW ONLY 4 PRODUCTS IN VIEW ALL PRODUCTS
  // const showLess = () => {
  //   setNoOfElements(4);
  // };

  // // TO SHOW AND HIDE VIEW MORE SECTION
  // const viewMoreOnclickHandler = (viewMoreSelected) => {
  //   if (viewMoreClicked === viewMoreSelected) {
  //     setIsViewMoreClicked(false);
  //     setViewMoreClicked("");
  //   } else {
  //     setIsViewMoreClicked(true);
  //     setViewMoreClicked(viewMoreSelected);
  //   }
  // };

  // // TO CLOSE THE VIEW MORE SECTION
  // const closeViewMore = () => {
  //   setIsViewMoreClicked(false);
  //   setViewMoreClicked("");
  // };

  // TO CLOSE THE COMPARE SECTION
  const closeCompare = () => {
    setSelectedProductsToCompare((selectedProductsToCompare.length = 0));
    productsList.map(function (product) {
      if (product.isChecked === true) {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      } else {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      }
    });

    setPaidData([]);
    setProductsList([...productsList]);
    setPostIdentity([])
  };

  // TO AUTO ADD AND AUTO REMOVE PRODUCTS FROM COMPARE
  useEffect(() => {
    if (selectedProductsToCompare.length > 0) {
      MainCompare();
    }
  }, [productsList]);

  // let tableData = [];

  // const headers = [
  //   { name: "Age", field: "age" },
  //   { name: "Spends", field: "spends" },
  //   // { name: "Clicks", field: "clicks"},
  //   { name: "Reach", field: "reach" },
  //   { name: "Impressions", field: "impressions" },
  //   { name: "Post Engagements", field: "enagagements" },
  //   { name: "CPE/CPC/CPV", field: "cpc" },
  // ];

  // const genderWiseSplitDataHeaders = [
  //   { name: "Gender", field: "gender" },
  //   { name: "Spends", field: "spends" },
  //   // { name: "Clicks", field: "clicks"},
  //   { name: "Reach", field: "reach" },
  //   { name: "Impressions", field: "impressions" },
  //   { name: "Post Engagements", field: "enagagements" },
  //   { name: "CPE/CPC/CPV", field: "cpc" },
  // ];

  // const deviceWiseSplitDataHeaders = [
  //   { name: "Device", field: "device" },
  //   { name: "Spends", field: "spends" },
  //   // { name: "Clicks", field: "clicks"},
  //   { name: "Reach", field: "reach" },
  //   { name: "Impressions", field: "impressions" },
  //   { name: "Post Engagements", field: "enagagements" },
  //   { name: "CPE/CPC/CPV", field: "cpc" },
  // ];

  // const [selectedGrowthOption, setSelectedGrowthOption] = useState({
  //   value: "likes",
  //   label: "Likes",
  // });

  // // PAGE GROWTH DROPDOWN OPTIONS
  // const pageGrowthOptions = [
  //   { value: "likes", label: "Likes" },
  //   { value: "followers", label: "Followers" },
  // ];

  // // TO SELECT PAGE GROWTH OPTIONS FROM DROPDOWN
  // const pageGrowthOptionsHandler = (event) => {
  //   setSelectedGrowthOption(event === null ? "" : event);
  // };

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS GENRE

  useEffect(() => {
    fetchGprsGenreHandler()
  }, [marketAllIndiaDropdownValue])

  const fetchGprsGenreHandler = async () => {
    setGprsGenreLoading(true);
    setGprsGenreLoaded(false);
    setGprsGenreError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        compare: JSON.stringify(postIdentity),
        performance_trend_parameter: `${marketAllIndiaDropdownValue === undefined || marketAllIndiaDropdownValue === "" ? "" : marketAllIndiaDropdownValue.value === undefined ? "" : marketAllIndiaDropdownValue.value}`

      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/haleon-compare-performance-trend/", config);

      // var dataTitle =
      //   res.data === undefined || res.data.length === 0 ? [] :
      //     res.data[0] === undefined ? [] :
      //       res.data[0].title === undefined || res.data[0].title.length === 0 ? [] :
      //         res.data[0].title;



      // var formattedData = productsList.sort((a, b) => {
      //   return dataTitle.indexOf(a.brand_name) - dataTitle.indexOf(b.brand_name)
      // })

      setGprsGenre(res.data[0].data);
      setGprsGenreLabel(res.data[0].dimension);
      setGprsGenreTitle(res.data[0].title);
      setGprsGenreAllData(res.data)

      setGprsGenreLoading(false);
      setGprsGenreLoaded(true);
      setGprsGenreError(null);
    } catch (error) {
      setGprsGenre([]);
      setGprsGenreLabel([]);
      setGprsGenreTitle([]);
      setGprsGenreLoading(false);
      setGprsGenreLoaded(false);
      setGprsGenreError(error.code);
    }
  };

  let gprsGenreChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="singleLineChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE

  if (gprsGenreLoaded && !gprsGenreLoading) {
    if (NoDataAvailableChecker(gprsGenre) || NoDataAvailableChecker(gprsGenreLabel) || NoDataAvailableChecker(gprsGenreTitle)) {
      gprsGenreChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader chartType="lineChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsGenreChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {gprsGenre.map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data.length === 0 || data === undefined ?

                      <NoDataAvailableLoader chartType="lineChartType" />
                      :
                      <LineChart
                        chartClass="section_card_chart"
                        chartData={Object.values(data)[0]}
                        chartLabels={Object.values(gprsGenreLabel[index])[0]}
                        chartId={"donutPie1" + Math.random()}

                      />
                    }
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{Object.values(gprsGenreTitle[index].name)}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsGenreError) {
    gprsGenreChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsGenreError} chartType={"lineChartType"} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsGenreLoading) {
    gprsGenreChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="singleLineChartLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS TIME

  const fetchGprsTimeHandler = async () => {
    setGprsTimeLoading(true);
    setGprsTimeLoaded(false);
    setGprsTimeError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-time/", config);

      setGprsTime(res.data);
      setGprsTimeLoading(false);
      setGprsTimeLoaded(true);
      setGprsTimeError(null);
    } catch (error) {
      setGprsTime([]);
      setGprsTimeLoading(false);
      setGprsTimeLoaded(false);
      setGprsTimeError(error.code);
    }
  };

  let gprsTimeChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsTimeLoaded && !gprsTimeLoading) {
    if (NoDataAvailableChecker(gprsTime)) {
      gprsTimeChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader chartType="donutChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsTimeChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsTime).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data.length === 0 || data === undefined ?

                      <NoDataAvailableLoader chartType="donutChartType" />
                      :
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie2" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet2}
                      />
                    }
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsTimeError) {
    gprsTimeChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader chartType="donutChartType" error={gprsTimeError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsTimeLoading) {
    gprsTimeChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }


  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS TIME
  const fetchGprsPnpHandler = async () => {
    setGprsPnpLoading(true);
    setGprsPnpLoaded(false);
    setGprsPnpError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-time/", config);

      setGprsPnp(res.data);
      setGprsPnpLoading(false);
      setGprsPnpLoaded(true);
      setGprsPnpError(null);
    } catch (error) {
      setGprsPnp([]);
      setGprsPnpLoading(false);
      setGprsPnpLoaded(false);
      setGprsPnpError(error.code);
    }
  };

  let gprsPnpChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsPnpLoaded && !gprsPnpLoading) {
    if (NoDataAvailableChecker(gprsPnp)) {
      gprsPnpChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsPnpChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsPnp).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data.length === 0 || data === undefined ? (
                      <NoDataAvailableLoader />
                    ) : (
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie31" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet3}
                      />
                    )}
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsPnpError) {
    gprsPnpChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsPnpError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsPnpLoading) {
    gprsPnpChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }


  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS PREMIUM NON PREMIUM
  const fetchGprsPremiumNonPremiumHandler = async () => {
    setGprsPremiumNonPremiumLoading(true);
    setGprsPremiumNonPremiumLoaded(false);
    setGprsPremiumNonPremiumError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-premium-non-premium/", config);

      setGprsPremiumNonPremium(res.data);
      setGprsPremiumNonPremiumLoading(false);
      setGprsPremiumNonPremiumLoaded(true);
      setGprsPremiumNonPremiumError(null);
    } catch (error) {
      setGprsPremiumNonPremium([]);
      setGprsPremiumNonPremiumLoading(false);
      setGprsPremiumNonPremiumLoaded(false);
      setGprsPremiumNonPremiumError(error.code);
    }
  };

  let gprsPremiumNonPremiumChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsPremiumNonPremiumLoaded && !gprsPremiumNonPremiumLoading) {
    if (NoDataAvailableChecker(gprsPremiumNonPremium)) {
      gprsPremiumNonPremiumChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsPremiumNonPremiumChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsPremiumNonPremium).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data.length === 0 || data === undefined ? (
                      <NoDataAvailableLoader />
                    ) : (
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie31" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet3}
                      />
                    )}
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsPremiumNonPremiumError) {
    gprsPremiumNonPremiumChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsPremiumNonPremiumError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsPremiumNonPremiumLoading) {
    gprsPremiumNonPremiumChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GPRS AD DURATION
  const fetchGprsAdDurationHandler = async () => {
    setGprsAdDurationLoading(true);
    setGprsAdDurationLoaded(false);
    setGprsAdDurationError(null);

    const config = {
      params: {
        /*  brand_id: BRAND_ID, */
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //post_id: postIdentity.join(','),
        compare: JSON.stringify(paidData),
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-ad-duration/", config);

      setGprsAdDuration(res.data);
      setGprsAdDurationLoading(false);
      setGprsAdDurationLoaded(true);
      setGprsAdDurationError(null);
    } catch (error) {
      setGprsAdDuration([]);
      setGprsAdDurationLoading(false);
      setGprsAdDurationLoaded(false);
      setGprsAdDurationError(error.code);
    }
  };

  let gprsAdDurationChart =
    <Wrapper>
      <div className="grid grid_cols_1">
        <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (gprsAdDurationLoaded && !gprsAdDurationLoading) {
    if (NoDataAvailableChecker(gprsAdDuration)) {
      gprsAdDurationChart =
        <Wrapper>
          <div className="grid grid_cols_1">
            <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
    } else {
      gprsAdDurationChart = (
        <Wrapper>
          <div className="grid grid_cols_3">
            {Object.values(gprsAdDuration).map((data, index) => (
              <div className="grid grid_cols_1 card_border_right" key={index}>
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    {data.length === 0 || data === undefined ? (
                      <NoDataAvailableLoader />
                    ) : (
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={"donutPie31" + Math.random()}
                        showLabels
                        showLegend
                        showVerticalLabels
                        chartColorSet={DonutChartColorSet}
                      />
                    )}
                  </div>
                  {data.length !== 0 && (
                    <div className="footer_title_wrapper">
                      <div className="footer_title">{data[0].brand_name}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gprsAdDurationError) {
    gprsAdDurationChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_3">
            <ServerErrorsLoader error={gprsAdDurationError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (gprsAdDurationLoading) {
    gprsAdDurationChart =
      <Wrapper>
        <div className="grid grid_cols_1">
          <div style={{ "height": "350px" }} className="flex justify_center grid grid_cols_1">
            <Loader loaderType="donutChartLoader" />
          </div>
        </div>
      </Wrapper>
  }

  const exportProductsData = products.length > 0 ? products.map(a => ({ ...a })) : [];
  const exportSelectedProductsData = selectedProductsToCompare.length > 0 ? selectedProductsToCompare.map(a => ({ ...a })) : [];



  const marketAllIndiaDropdownHandler = (e) => {
    if (e === null) {
      setMarketAllIndiaDropdownValue("")
    } else {
      setMarketAllIndiaDropdownValue(e)
    }
  };


  useEffect(() => {
    const fetchMarketAllIndiaDropdownValue = async () => {
      setMarketComparisonDropdownOptionsLoading(true);

      const config = {
        params: {
          // brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("ri/sales/haleon-compare-performance-trend-filter-dropdown/", config);
        setMarketComparisonDropdownOptions(
          res.data === undefined || res.data.length === 0 ? [] :
            res.data.data === undefined || res.data.data.length === 0 ? [] :
              res.data.data
        );

        setMarketAllIndiaDropdownValue(
          res.data === undefined || res.data.length === 0 ? "" :
            res.data.data === undefined || res.data.data.length === 0 ? [] :
              res.data.data[0] === undefined ? "" : res.data.data[0]
        )
        setMarketComparisonDropdownOptionsLoading(false);
      } catch {
        setMarketAllIndiaDropdownValue("")
        setMarketComparisonDropdownOptions([]);
        setMarketComparisonDropdownOptionsLoading(true);
      }
    };
    fetchMarketAllIndiaDropdownValue()
  }, [selectedDates, authCtx])


  var performanceTrendExport = (function () {
    if (NoDataAvailableChecker(gprsGenreAllData)) {
      return []
    } else {
      // var dims = gprsGenreAllData[0].dimension.map((item) => item);
      var data = gprsGenreAllData[0].data.map((item) => Object.values(item)[0]);
      var title = gprsGenreAllData[0].title.map((item) => item.name);
      var val = marketAllIndiaDropdownValue === undefined || marketAllIndiaDropdownValue === null ? "" : marketAllIndiaDropdownValue.value === undefined || marketAllIndiaDropdownValue.value === null ? "" : marketAllIndiaDropdownValue.value
      var valLabel = marketAllIndiaDropdownValue === undefined || marketAllIndiaDropdownValue === null ? "" : marketAllIndiaDropdownValue.label === undefined || marketAllIndiaDropdownValue.label === null ? "" : marketAllIndiaDropdownValue.label


      var fooArr = []
      if (NoDataAvailableChecker(data) || NoDataAvailableChecker(title)) {
        return [];
      } else {
        for (var i = 0; i < data[0].length; i++) {
          var obj = {};
          obj["date"] = data[0][i].label;
          for (var j = 0; j < data.length; j++) {
            obj[title[j] + ` : ${valLabel}`] = data[j][i][`${val}`]
          }
          fooArr.push(obj)
        }
      }

      return fooArr;
    }
  })()

  return (
    <Wrapper>
      <div id={Title} className="grid grid_cols_1 section_card_header">
        {!isSales &&
          <h2 className="grid col_span_2 section_card_title">
            {productsListTitle === "top_5" && "Top Brands "}
          </h2>
        }
        <div className="grid col_span_2 flex justify_end">
          <div
            className="btn_wrapper width_fit_content flex"
            style={{ width: "150px" }}
          >
            <Button
              handleClick={() => MainCompare()}
              disabled={isComapreBtnDisabled}
              className="primary_btn"
              type="button"
            >
              Compare
            </Button>
            {hasExportDropdown && (
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  sectionId={Title}
                  sectionName={"Compare_" + Title}
                  sectionData={exportProductsData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {productsList.length > 0 ? (
        <Wrapper>
          {productsListTitle === "top_5" && (
            <div className="card_wrapper grid_padding">
              <Slider
                sliderTitle={""}
                slidestoshow={3}
                breakpoints={{
                  0: {
                    slidesPerView: "auto",
                  },
                  426: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1300: {
                    slidesPerView: 3,
                  },
                  2100: {
                    slidesPerView: 3,
                  },
                }}
              >
                {productsList.map((post, index) => (
                  <SwiperSlide key={index}>
                    <ComparePostCard
                      postData={post}
                      checkboxEventHandler={checkboxEventHandler}
                      isMarketSummary={true}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </div>
          )}

          {/* FOR SELECTED POSTS TO COMPARE */}
          {selectedProductsToCompare.length > 1 && (
            <Wrapper>
              <div ref={scollToRef} className="grid grid_cols_1 grid_padding grid_margin_bottom compare_sec">
                <div className="section_card fullpage_section_card grid bgBorderTransparent">
                  <div className="section_card_header">
                    <div className="section_info">    <h2 className="section_card_title">Compare Results
                    </h2>
                    </div>
                    <div className="btn_wrapper flex" style={{ width: "150px" }}>
                      <Button
                        handleClick={() => closeCompare()}
                        className="secondary_btn"
                        type="button"
                      >
                        Close
                      </Button>

                      {hasExportDropdown && (
                        <div data-html2canvas-ignore={true}>
                          <ExportDropdown
                            moduleName={pageModule}
                            subModuleName={pageSubModule}
                            sectionId={Title}
                            sectionName={"Compare_" + Title}
                            sectionData={exportSelectedProductsData}

                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="section_card_body brand-comp-table">
                    <div className="section_card_table_wrapper">
                      <div className="grid grid_cols_3  grid_margin_bottom">
                        {selectedProductsToCompare.map((product, index) => (
                          <div key={index} className="grid grid_cols_1 card_border_right">
                            <div className="table_wrapper">
                              <table className="table table_striped table_bordered wrapped-table">
                                <tbody>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="tableCard_wrap">
                                        <img
                                          src={
                                            product.brand_logo === "" ||
                                              product.brand_logo === undefined ||
                                              product.brand_logo === null
                                              ? DefaultPostImage
                                              : product.brand_logo
                                          }
                                          alt="img"
                                          onError={(e) =>
                                            (e.target.src = DefaultPostImage)
                                          }
                                          className="tableCard_img"
                                        />

                                      </div>
                                    </td>
                                  </tr>
                                  {product.metrics_data.map((metric, index) => (
                                    <tr key={index}>
                                      <td className="text_adjust_wrap">
                                        <span className="bold text_adjust">{metric.label}</span>
                                      </td>
                                      <td className="align_right value_td">

                                        <ErrorBoundary>
                                          {/*   <NumberSeparator
                                          number={metric.value}
                                          title={metric.label}
                                        /> */}

                                          <NumberFormatter
                                            title={metric.label}
                                            number={metric.value}

                                          />
                                        </ErrorBoundary>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isPaid && (
                <div className="grid grid_cols_1 grid_padding grid_margin_bottom card_border_top">
                  <div className=" compareCard_barchart grid_margin_bottom ">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title grid_margin_bottom">
                          Performance Trend
                        </h2>
                      </div>
                      <div data-html2canvas-ignore={true}>
                        <Dropdown
                          ismulti={false}
                          isClearable={false}
                          placeholder={"Select Market"}
                          options={marketComparisonDropdownOptions}
                          className="form_dropdown section_dropdown small-dropdown"
                          value={marketAllIndiaDropdownValue}
                          selectedOptions={marketAllIndiaDropdownValue}
                          dropdownLoading={marketComparisonDropdownOptionsLoading}
                          setStatedropdown={marketAllIndiaDropdownHandler}
                        />
                        <ExportDropdown
                          sectionId={"time_slot_grp_section"}
                          sectionName={"Top Brands Performance Trend"}
                          sectionData={performanceTrendExport}
                        />
                      </div>
                    </div>

                    {gprsGenreChart}
                  </div>
                </div>
              )}
            </Wrapper>
          )}
        </Wrapper>
      ) : (
        <div className="grid-cols-1">
          <div className="grid grid-cols-1 table-content">
            <div className="flex justify_center">
              <h4>No Data Available</h4>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CompareMarketsSummary;
