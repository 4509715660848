import React, { useState, useEffect } from "react";
import Wrapper from "../helpers/Wrapper";
import Button from "../ui/Button";
import { IcogzBrandLogo } from "../../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import "./inputField.scss"

const AnimatedInput = ({
    placeholders = [],
    typingSpeed = 20000,
    delayBetweenPlaceholders = 1000,
    openModal,
    onClickHandler,
    ...passedProps
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
    const [placeholder, setPlaceholder] = useState("");
    const [charIndex, setCharIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    const getQuestionNames = (data) => {
        if (data === undefined || data === null || data.length === 0) {
            return null
        } else {
            return data.map(item => item.question).filter(name => name !== undefined);
        }
    };

    let placeholderFormatted = getQuestionNames(placeholders)

    useEffect(() => {
        if (placeholderFormatted !== undefined && placeholderFormatted !== null && placeholderFormatted.length !== 0) {

            const type = () => {
                const currentPlaceholder = placeholderFormatted[currentPlaceholderIndex];
                const isLastChar = charIndex === currentPlaceholder.length;

                if (!isDeleting && !isLastChar) {
                    // Typing forward
                    setPlaceholder(currentPlaceholder.slice(0, charIndex + 1));
                    setCharIndex(charIndex + 1);
                } else if (isLastChar && !isDeleting) {
                    // Pause at the end before deleting
                    setTimeout(() => setIsDeleting(true), delayBetweenPlaceholders);
                } else if (isDeleting && charIndex > 0) {
                    // Deleting backward
                    setPlaceholder(currentPlaceholder.slice(0, charIndex - 1));
                    setCharIndex(charIndex - 1);
                } else if (isDeleting && charIndex === 0) {
                    // Move to the next placeholder
                    setIsDeleting(false);
                    setCurrentPlaceholderIndex(
                        (currentPlaceholderIndex + 1) % placeholderFormatted.length
                    );
                }
            };

            const typingTimeout = setTimeout(type, isDeleting ? typingSpeed / 2 : typingSpeed);

            return () => clearTimeout(typingTimeout);
        }
    }, [charIndex, isDeleting, placeholderFormatted, currentPlaceholderIndex, typingSpeed, delayBetweenPlaceholders]);


    const onSearchBtnClickHandler = () => {
        if (onClickHandler) {
            if (inputValue !== undefined && inputValue !== null && inputValue !== "") {
                onClickHandler(inputValue)
                setInputValue("")
            } else {
                alert("Please add search input first")
            }
        } else {
            if (inputValue !== undefined && inputValue !== null && inputValue !== "") {
                navigate("ask-icogz", { state: { from: location.pathname, query: inputValue } });
                setInputValue("")
            } else {
                alert("Please add search input first")
            }
        }
    }

    // AUTOCOMPLETE
    const [inputValue, setInputValue] = useState("");
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

    const handleChange = (e) => {
        const userInput = e.target.value;
        setInputValue(userInput);

        if (userInput && (placeholderFormatted !== undefined && placeholderFormatted !== null && placeholderFormatted.length !== 0)) {
            const filtered = placeholderFormatted.filter((suggestion) =>
                suggestion.toLowerCase().includes(userInput.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setShowSuggestions(true);
            setActiveSuggestionIndex(0);
        } else {
            setFilteredSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            if (activeSuggestionIndex < filteredSuggestions.length - 1) {
                setActiveSuggestionIndex(activeSuggestionIndex + 1);
            }
        } else if (e.key === "ArrowUp") {
            if (activeSuggestionIndex > 0) {
                setActiveSuggestionIndex(activeSuggestionIndex - 1);
            }
        } else if (e.key === "Enter") {
            setInputValue(filteredSuggestions[activeSuggestionIndex]);
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            onSearchBtnClickHandler() // to run a function which will open a chat modal
        } else if (e.key === "Tab") {
            // Auto-complete with the active suggestion when Tab is pressed
            e.preventDefault();
            setInputValue(filteredSuggestions[activeSuggestionIndex]);
            setFilteredSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleClick = (suggestion) => {
        setInputValue(suggestion);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
    };

    const renderSuggestions = () => {
        if (showSuggestions && inputValue) {
            if (filteredSuggestions.length) {
                return (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            const className =
                                index === activeSuggestionIndex ? "suggestion-active" : "";

                            return (
                                <li
                                    key={suggestion}
                                    className={className}
                                    onClick={() => handleClick(suggestion)}
                                >
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        }
        return null;
    };

    return (
        <Wrapper>
            <div className="form_group aryabot-input-wrapper">
                <div className="aryabot-input">
                    <div className="input-title-wrapper">
                        <div className="title">Ask</div>
                        <img className="icon" src={IcogzBrandLogo} />
                    </div>
                    <div className="input-wrapper autocomplete">
                        <input
                            id="header_aryabot_input"
                            {...passedProps}
                            placeholder={`${placeholder}`}
                            value={inputValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                        />
                        {renderSuggestions()}
                    </div>
                    <div className="search-btn-wrapper">
                        <Button
                            className="primary_btn search-btn"
                            type="button"
                            handleClick={onSearchBtnClickHandler}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </div>
        </Wrapper >
    )
};

export default AnimatedInput;

export const AnimatedInputSkeltonLoader = () => {
    return (
        <Wrapper>
            <div className="skeleton-ask-icogz-input-wrapper aryabot-input-wrapper">
                <div className="skeleton-ask-icogz-input" />
            </div>
        </Wrapper>
    )
}