import Wrapper from "../../components/helpers/Wrapper";

const CommentsIcon = (props) => {
  return (
    <Wrapper>
      <svg className={props.className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.8313 16.886C22.8313 17.4115 22.6225 17.9154 22.251 18.2869C21.8795 18.6584 21.3756 18.8671 20.8502 18.8671H8.96222L5 22.83V6.98111C5 6.45569 5.20872 5.95178 5.58025 5.58025C5.95178 5.20872 6.45569 5 6.98111 5H20.8502C21.3756 5 21.8795 5.20872 22.251 5.58025C22.6225 5.95178 22.8313 6.45569 22.8313 6.98111V16.886Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Wrapper>
  );
};

export default CommentsIcon;
