const IconCart = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5 41C23.5 41.2967 23.412 41.5867 23.2472 41.8334C23.0824 42.08 22.8481 42.2723 22.574 42.3858C22.2999 42.4993 21.9983 42.5291 21.7074 42.4712C21.4164 42.4133 21.1491 42.2704 20.9393 42.0607C20.7296 41.8509 20.5867 41.5836 20.5288 41.2926C20.4709 41.0017 20.5006 40.7001 20.6142 40.426C20.7277 40.1519 20.92 39.9176 21.1666 39.7528C21.4133 39.588 21.7033 39.5 22 39.5C22.3978 39.5 22.7794 39.658 23.0607 39.9393C23.342 40.2206 23.5 40.6022 23.5 41Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40 41C40 41.2967 39.912 41.5867 39.7472 41.8334C39.5824 42.08 39.3481 42.2723 39.074 42.3858C38.7999 42.4993 38.4983 42.5291 38.2074 42.4712C37.9164 42.4133 37.6491 42.2704 37.4393 42.0607C37.2296 41.8509 37.0867 41.5836 37.0288 41.2926C36.9709 41.0017 37.0007 40.7001 37.1142 40.426C37.2277 40.1519 37.42 39.9176 37.6666 39.7528C37.9133 39.588 38.2033 39.5 38.5 39.5C38.8978 39.5 39.2794 39.658 39.5607 39.9393C39.842 40.2206 40 40.6022 40 41Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 11H16L20.02 31.085C20.1572 31.7756 20.5329 32.3959 21.0813 32.8374C21.6298 33.279 22.316 33.5135 23.02 33.5H37.6C38.304 33.5135 38.9902 33.279 39.5387 32.8374C40.0871 32.3959 40.4628 31.7756 40.6 31.085L43 18.5H17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconCart;