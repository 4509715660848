import React, { useState, useRef, useEffect } from "react";
import CommentsIcon from "../../assets/icons/CommentsIcon";
import Comments from "./comments";
import Button from "../ui/Button";
import Wrapper from "../helpers/Wrapper";
import { useSelector } from "react-redux";
import { DefaultProfileImage } from "../../constants/constants";
import useApi from "../../hooks/useApi";
import "./sectionComments.scss";

const formatData = (dataArray, userData) => {
    return dataArray.map(item => ({
        userName: item.user === userData.id ? `${userData?.first_name} ${userData?.last_name}` : null,
        userImg: item.user === userData.id ? userData?.avatar === null ? DefaultProfileImage : userData?.avatar : null,
        message: item.comment || null,
        timestamp: item.created_at || null
    }));
}

const SectionComments = ({
    sectionModule,
    sectionSubmodule,
    sectionPlatform,
    section,
    data
}) => {
    const ARYABOT_BASE_URL = process.env.REACT_APP_ARYABOT_API_URL;

    // USER DATA
    const userData = useSelector((state) => state.userData.user);

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date);

    // API CALLS
    const createCommentAPI = useApi();
    const deleteCommentAPI = useApi();

    // CREATE NEW ANNOTATION/COMMENT API
    const handleCreateRecord = async (comment) => {
        let formData = {
            module: sectionModule,
            sub_module: sectionSubmodule,
            platform: sectionPlatform,
            comment: comment,
            tag: "asd",
            section: section
        }

        await createCommentAPI.postData(ARYABOT_BASE_URL, `aryabot/annotation/`, formData);
    }

    let temp = {
        userName: null,
        userImg: null,
        message: null,
        timestamp: null
    }

    const [isPopupVisible, setPopupVisible] = useState(false);
    const [comments, setComments] = useState(data.length !== 0 ? formatData(data, userData) : []);
    const [newComment, setNewComment] = useState("");
    const iconRef = useRef(null);
    const popupRef = useRef(null);

    // OPEN OR CLOSE COMMENT SECTION ON CLICK
    const handleCommentIconClick = () => {
        setPopupVisible(!isPopupVisible);
    };

    // ADD THE CREATED COMMENT IN THE LIST OF ALL COMMENTS
    const handleCommentSubmit = () => {
        if (newComment.trim() !== "") {

            const userName = `${userData?.first_name} ${userData?.last_name}`;
            const userImg = userData?.avatar === null ? DefaultProfileImage : userData?.avatar;

            let updatedComment = {
                userName: userName,
                userImg: userImg,
                message: newComment,
                timestamp: new Date(Date.now())
            }
            setComments([...comments, updatedComment]);
            setNewComment("");
            handleCreateRecord(newComment)
        }
    };



    // TO CLOSE THE COMMENTS SECTION ON CLICK OUTSIDE
    const handleClickOutside = (event) => {
        if (
            popupRef.current &&
            !popupRef.current.contains(event.target) &&
            !iconRef.current.contains(event.target)
        ) {
            setPopupVisible(false);
        }
    };

    useEffect(() => {
        if (isPopupVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isPopupVisible]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleCommentSubmit()
        }
    }

    return (
        <div className="section-comments-wrapper">
            <div ref={iconRef} className={`icon-wrapper ${isPopupVisible && "active"}`} onClick={handleCommentIconClick}>
                <CommentsIcon className="icon" />
                {comments.length > 0 &&
                    <Wrapper>
                        <div className="badge">
                            {comments.length}
                        </div>
                    </Wrapper>
                }
            </div>

            {isPopupVisible && (
                <div ref={popupRef} className="comment-popup" >
                    <div className="header">
                        <div className="title">
                            Annotations
                        </div>
                    </div>
                    <Comments comments={comments} />
                    <input
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="add comment here..."
                        style={{ marginBottom: "10px" }}
                    />
                    <Button className="primary_btn btn-small" handleClick={handleCommentSubmit}>Annotate</Button>
                </div>
            )}
        </div>
    );
};

export default SectionComments;

export const SectionCommentsSkeltonLoader = () => {
    return (
        <Wrapper>
            <div className="section-comments-wrapper skeleton-section-comments-wrapper">
                <div className="icon-wrapper">
                    <CommentsIcon className="icon" />
                </div>
            </div>
        </Wrapper>
    )
}

export const SectionCommentsErrorLoader = () => {
    return (
        <Wrapper>
            <div className="section-comments-wrapper error-section-comments-wrapper">
                <div className="icon-wrapper">
                    <CommentsIcon className="icon" />
                </div>
            </div>
        </Wrapper>
    )
}
