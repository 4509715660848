import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import zoomarrows from "../../../assets/images/zoomarrows.jpg";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { USER_COUNTRY } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { convertAmchartsColorToHexColor } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const LineChart = ({
  chartId,
  chartClass,
  showLegend,
  showLabels,
  chartData,
  chartLabels,
  showBullets,
  onBulletsClick,
  tooltipWidth,
  removeMinTooltipLabelWidth,
  cardTitle,
  flatLegends,
  platform,
  toolTipTitle,
  showTarget,
  targetValue,
  impressionLabel,
  showImpression,
  toolTipLabelSize,
  disabledLegendArray,
  setDisabledValues,
  showScrollbar,
  minimumGridDistance,
  axisColor
}) => {

  let axisTextColor = axisColor || 0x757575; // Use axisColor if available, else fallback to 0x757575
  let axisTextColorHex = convertAmchartsColorToHexColor(axisTextColor); // Convert to hex string once

  // USER DATA
  const userData = useSelector((state) => state.userData.user);
  let userCountryCode = userData?.country_code ?? "IN"

  let country = {}

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data
    }
  })

  if (showScrollbar === undefined) {
    showScrollbar = true
  }

  let chartDataPlatform;
  if (platform === undefined) {
    chartDataPlatform = "All"
  } else {
    chartDataPlatform = platform
  }

  const addCurrencySymbols = (data) => {
    const currencyMetrics = ["Spends", "Spend", "Cost Per Video View"];
    if (currencyMetrics.includes(data)) {
      if (platform === "Twitter") {
        return country.alternateCurrencySymbol
      } else {
        return country.currencySymbol
      }
    } else {
      return ""
    }
  }

  const addPercentageSymbols = (data, parent) => {
    if (parent === undefined) {
      parent = ""
    }

    const tonnesMetrics = ["Monthly Sales Vol.", "Sales Vol."];
    const percentMetrics = ["VTR", "CVTR", "Benchmarking 1:Average", "Benchmarking 2:Campaign Start Date", 'GRP Percent', 'Benchmark'];
    const noPercentMetrics = ["CPCV", "CPV", "Cost Per Video View"]

    let specificArr = ["CPCV,Benchmarking 1: Average,Benchmarking 2: Campaign Start Date", "CPM,Benchmarking 1: Average,Benchmarking 2: Campaign Start Date", "Cost Per Video View,Benchmarking 1: Average,Benchmarking 2: Campaign Start Date"]
    let valueArr = [];

    chartLabels.map((data) => (
      valueArr.push(data.label)
    ))

    if (specificArr.includes(valueArr.toString())) {
      return ""
    } else if (noPercentMetrics.includes(parent)) {
      return ""
    } else {
      if (percentMetrics.includes(data)) {
        return "%"
      } else if (tonnesMetrics.includes(data)) {
        return " T"
      } else {
        return ""
      }
    }
  }
  useLayoutEffect(() => {
    var root = am5.Root.new(chartId);
    // root.autoResize = false;
    let colorSet = [];

    if (showTarget === true) {
      colorSet = [0x06a57d, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8, 0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8];
    }
    else {
      colorSet = [0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8, 0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8];

    }
    if (showLegend === undefined) {
      showLegend = true;
    }

    if (tooltipWidth === undefined) {
      tooltipWidth = "auto"
    }

    // TO SHOW BULLET IF THE DATA IS SINGLE
    if (chartData.length === 1) {
      showBullets = true
    }

    let tooltipLabelWidth = "[bold width: 100px]";
    if (removeMinTooltipLabelWidth === undefined) {
      tooltipLabelWidth = "[bold width: 100px]";
    } else if (removeMinTooltipLabelWidth === true) {
      tooltipLabelWidth = "[bold]";
    } else {
      tooltipLabelWidth = "[bold width: 100px]";
    }

    // CURRENCY METRICS ARRAY
    const liquidMetrics = ["Sales Volume"];
    const currencyMetrics = ["Spends"];

    let numberFormatterPrefixes;
    if (liquidMetrics.includes(cardTitle)) {
      numberFormatterPrefixes = [
        { number: 1e3, suffix: "K-L" },
        { number: 1e6, suffix: "M-L" },
        { number: 1e9, suffix: "B-L" },
      ]
    } else if (currencyMetrics.includes(cardTitle)) {
      numberFormatterPrefixes = [
        { number: 1e3, suffix: "K" },
        { number: 1e5, suffix: "L" },
        { number: 1e7, suffix: "Cr" },
      ]
    } else {
      numberFormatterPrefixes = [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ]
    }



    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    if (!disabledLegendArray) {
      root.setThemes([am5themes_Animated.new(root)])
    }

    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: numberFormatterPrefixes,

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false, // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
        panY: false, // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
        wheelX: "none", // zoomX / zoomY / zoomXY / panX / panY / panXY / none, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
        wheelY: "none", // zoomX / zoomY / zoomXY / panX / panY / panXY / none, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
        layout: root.verticalLayout, // to add chart and legends in vertical
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
      })
    );
    cursor.lineY.set("visible", true);
    cursor.lineX.set("visible", true);

    // The data
    var data = chartData;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.2,
      stroke: am5.color(axisTextColor),
      strokeWidth: 1,
      ...(minimumGridDistance !== undefined && { minimumGridDistance: minimumGridDistance })
    });


    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "label",

        // to make line curve
        maxDeviation: 0.1,

        // to start and end the line from the start and end
        startLocation: 0.2,
        endLocation: 0.8,

        renderer: xRenderer,
      })
    );

    // to show and hide x-axis labels
    if (showLabels === false) {
      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400",
      });
    }

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.03,
        maxDeviation: 0.1,
        extraTooltipPrecision: 1,
        strictMinMaxSelection: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.2,
          stroke: am5.color(axisTextColor),
          strokeWidth: 1,
        }),

      })
    );

    // TO HIDE AND SHOW Y-AXIS (HORIZONTAL GRID LINES) GRID LINES 
    yAxis.get("renderer").grid.template.setAll({
      forceHidden: true
    })

    // to show and hide y-axis labels
    if (showLabels === false) {
      yAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      yAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: axisTextColorHex,
        fontWeight: "400",
      });
    }


    if (showImpression === true) {
      var yAxis1 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 1,
            stroke: am5.color(0x5A75F9),
            strokeWidth: 1,
            opposite: true,
          }),
          maxDeviation: 0.1,
          extraTooltipPrecision: 1,
          syncWithAxis: yAxis,
        })
      );

      // to show and hide y-axis labels
      if (showLabels === false) {
        yAxis1.get("renderer").labels.template.setAll({
          visible: false,
        });
      } else {
        yAxis1.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
          maxWidth: 150,
          textAlign: "center",
          fontSize: 12,
          fontFamily: "Ubuntu",
          fill: "#5A75F9",
          fontWeight: "400",
        });
      }
    }



    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        // background: am5.Rectangle.new(root, {
        //     fill: am5.color(0xff5599),
        //     fillOpacity: 0.2
        // }), // to add the backgrounD to the legends
        position: "relative", // options: "absolute" / "relative"
        // height: am5.percent(20),
        x: am5.percent(50),
        // width: am5.percent(100),
        height: chartLabels.length >= 6 ? am5.percent(30) : null,
        centerX: am5.percent(50),
        marginTop: 15,
        // marginBottom: 15,
        useDefaultMarker: true,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          ...(disabledLegendArray === undefined || disabledLegendArray === null ? {
            maxColumns: 10,
            fixedWidthGrid: true,
          } : {
            maxColumns: 15,
          }),



        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          ...(disabledLegendArray === undefined || disabledLegendArray === null ? {
            orientation: "vertical",
          } : {}),
        }),
      })
    );

    if (disabledLegendArray === undefined || disabledLegendArray === null) {

    } else {
      legend.markers.template.setup = function (marker) {
        var check = am5.Graphics.new(root, {
          width: 10,
          height: 10,
          layer: 50,
          fill: am5.color(0xffffff),
          draw: function (display) {
            display.drawRect(4, 4, 6, 6)
          }
        });

        check.states.create("disabled", {
          fillOpacity: 1
        });

        marker.children.push(check);
      }

      legend.markers.template.setAll({
        width: 14,
        height: 14,

      });
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 0,
        cornerRadiusTR: 0,
        cornerRadiusBL: 0,
        cornerRadiusBR: 0,
      });
    }

    if (disabledLegendArray === undefined || disabledLegendArray === null) {
      if (flatLegends === true) {
        legend.markers.template.setAll({
          width: 13,
          height: 1,
        });
        legend.markerRectangles.template.setAll({
          cornerRadiusTL: 0,
          cornerRadiusTR: 0,
          cornerRadiusBL: 0,
          cornerRadiusBR: 0,
        });

      }
      else {
        legend.markers.template.setAll({
          width: 13,
          height: 13,
        });
        legend.markerRectangles.template.setAll({
          cornerRadiusTL: 10,
          cornerRadiusTR: 10,
          cornerRadiusBL: 10,
          cornerRadiusBR: 10,
        });
      }
    }



    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });

    if (disabledLegendArray) {
      legend.itemContainers.template.events.on('click', function (ev) {

        var temp = ev.target.dataItem.dataContext;



        chart.series.values.filter((i, index) => {

          if (temp.get('name') === i._settings.name) {

            if (i.get('visible')) {
              setDisabledValues(prev => {
                var tempArr = [...prev, temp.get('name')]
                var fooBar = [...new Set(tempArr)]
                return fooBar;
              })

            } else {
              setDisabledValues(prev => {
                var tempArrRmv = prev.filter((i) => i !== temp.get('name'))
                return tempArrRmv
              })
            }

          }
        })
        root.dateFormatter.setAll({
          dateFormat: "yyyy-MM-dd",
          dateFields: ["label"]
        });
        // chart.series.data.processor = am5.DataProcessor.new(root, {
        //     dateFields: ["label"],
        //     dateFormat: "yyyy-MM-dd",
        // });
      })
    }


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(name, field, childLabel, childValue, index) {
      if (showImpression === true) {
        var series = chart.series.push(
          am5xy.SmoothedXLineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: field === impressionLabel ? yAxis1 : yAxis,
            valueYField: field,
            categoryXField: "label",
            childLabel: childLabel,
            childValue: childValue,
            fill: am5.color(colorSet[index]),
            stroke: am5.color(colorSet[index]),
          })
        );
      }
      else {
        var series = chart.series.push(
          am5xy.SmoothedXLineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: "label",
            childLabel: childLabel,
            childValue: childValue,
            fill: am5.color(colorSet[index]),
            stroke: am5.color(colorSet[index]),
          })
        );
      }

      {
        showBullets &&
          series.bullets.push(function (root, series, dataItem) {

            var circle = am5.Circle.new(root, {
              radius: 5,
              stroke: series.get("fill"),
              strokeWidth: 2,
              fill: chartData.length === 1 ? series.get("fill") : dataItem.dataContext.outlier === "true" ? series.get("fill") : root.interfaceColors.get("background")
            });

            if (dataItem.dataContext.outlier === "true") {

              circle.animate({
                key: "radius",
                to: 10,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic),
                loops: Infinity
              });
              circle.animate({
                key: "opacity",
                to: 0,
                from: 1,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic),
                loops: Infinity
              });
            }

            {
              chartData.length > 1 &&
                circle.events.on("click", function (ev) {
                  onBulletsClick(ev.target.dataItem.dataContext.label);
                });
            }

            circle.states.create('hover', {
              scale: 2,
              cursorOverStyle: "pointer"
            });

            return am5.Bullet.new(root, {
              sprite: circle
            });
          });
      }

      // for adding gradient to the line series
      series.fills.template.set(
        "fillGradient",
        am5.LinearGradient.new(root, {
          stops: [
            {
              opacity: 0.3,
            },
            {
              opacity: 0,
            },
          ],
          rotation: 90,
        })
      );
      series.fills.template.setAll({
        visible: true,
        fillOpacity: 1,
      });
      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });

      var tooltip = series.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        maxWidth: tooltipWidth,
        oversizedBehavior: "wrap",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: toolTipTitle ? "[bold]" + toolTipTitle + "[/] : [bold]{categoryX}[/]" : "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: toolTipLabelSize === undefined || toolTipLabelSize === null ? 13 : toolTipLabelSize
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {

          let CurrencySymbol = addCurrencySymbols(series.get("name"));
          let PercentageSymbol = addPercentageSymbols(series.get("name"));
          let ChildPercentageSymbol = addPercentageSymbols(series.get("childLabel"), series.get("name"));

          text +=
            targetValue ? "\n[]●[/] " + tooltipLabelWidth + series.get("name") + ":[/]" + " " + tooltipLabelWidth + CurrencySymbol + "{" + series.get("valueYField") + "}" + PercentageSymbol + "[/]" : "\n[" + series.get("stroke").toString() + "]●[/] " + tooltipLabelWidth + series.get("name") + ":[/]" + " " + tooltipLabelWidth + CurrencySymbol + "{" + series.get("valueYField") + "}" + PercentageSymbol + "[/]";
          if (series.get("childLabel") != undefined) {
            text +=
              "\n[]●[/] " + tooltipLabelWidth +
              series.get("childLabel") +
              ":[/]" + tooltipLabelWidth + "{" +
              series.get("childValue") +
              "}" + ChildPercentageSymbol + "[/]";
          }
        });
        return text;
      });

      // tooltip.label.set(
      //     "html", "<span style='font-size: 13px; color: #fff; font-weight: 400'>{categoryX}</span>"
      // )

      // tooltip.label.adapters.add("html", function (html, target) {
      //     chart.series.each(function (series, index) {
      //         html += "<div className='flex justify row'><span style='font-size: 12px; color: #fff; font-weight: 300; margin-right: 10px'>" + series.get("name") + ":</span><span style='font-size: 12px; color: #7D9EB5; font-weight: 400'>" + series.get("valueY") + "</span></div>"
      //     })
      //     return html
      // })

      series.data.setAll(data);
      series.appear(1000);

      // Add legends
      if (showLegend === true) {
        legend.data.push(series);
      }
      if (showTarget === true) {
        // add series range
        var seriesRangeDataItem = yAxis.makeDataItem({ value: targetValue.max_value, endValue: targetValue.min_value });
        var seriesRange = series.createAxisRange(seriesRangeDataItem);
        seriesRange.fills.template.setAll({
          visible: true,
          opacity: 0.9
        });

        seriesRange.fills.template.set("fill", am5.color(0xb51c2a));
        seriesRange.strokes.template.set("stroke", am5.color(0xb51c2a));

        seriesRangeDataItem.get("grid").setAll({
          strokeOpacity: 1,
          visible: true,
          stroke: am5.color(0x000000),
          strokeDasharray: [4, 5]
        })

        seriesRangeDataItem.get("label").setAll({
          location: 0,
          visible: true,
          text: "Benchmark",
          inside: true,
          centerX: 0,
          centerY: am5.p100,
          fontWeight: "bold"
        })

      }

    }

    chartLabels.map((label, index) => {
      createSeries(
        label.label,
        label.value,
        label.childLabel,
        label.childValue,
        index
      );
    });





    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //     orientation: "horizontal"
    // }));


    // Add scrollbar	
    chart.zoomOutButton.set("forceHidden", true);


    if (showScrollbar) {
      const scrollbar = am5.Scrollbar.new(root, {
        marginTop: 20,
        orientation: "horizontal",
      });

      chart.set("scrollbarX", scrollbar);

      chart.bottomAxesContainer.children.push(scrollbar);

      const color = "#e1e1e1";

      // this is the filled bar	
      scrollbar.thumb.setAll({
        fill: "#dfdada",

        opacity: 1,
      });

      scrollbar.get("background").setAll({
        fill: "#dddddd",
        opacity: 0.5,
      });

      scrollbar.startGrip.setAll({
        crisp: true,
        strokeWidth: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        visible: true,
        stroke: 0,
        icon: am5.Picture.new(root, {
          interactive: true,
          src: zoomarrows,
          cursorOverStyle: "pointer",
          width: 20,
          height: 20,
        }),
      });

      scrollbar.endGrip.setAll({
        dx: -10,
        crisp: true,
        strokeWidth: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        visible: true,
        stroke: 0,
        icon: am5.Picture.new(root, {
          interactive: true,
          src: zoomarrows,
          cursorOverStyle: "pointer",
          width: 20,
          height: 20,
        }),
      });
    }





    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.zoomOutButton.set("forceHidden", true);
    chart.appear(1000, 100);


    if (disabledLegendArray !== undefined || disabledLegendArray !== null || disabledLegendArray.length !== 0) {

      function toggle(item) {
        if (item === undefined || item === null) return
        var storedItem = item;
        chart.series.values.filter((i, index) => {
          if (storedItem.includes(i._settings.name)) {
            var series = chart.series.getIndex(index);
            toggleSeries(series);
          }
        })
      }

      function toggleSeries(series) {
        if (series.get("visible")) {
          root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
          });
          series.data.processor = am5.DataProcessor.new(root, {
            dateFields: ["label"],
            dateFormat: "yyyy-MM-dd",
          });
          series.hide();
        }
        else {
          root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
          });
          series.data.processor = am5.DataProcessor.new(root, {
            dateFields: ["label"],
            dateFormat: "yyyy-MM-dd",
          });
          series.show();
        }
      }

      setTimeout(() => {
        toggle(disabledLegendArray);
      }, 1)
    }

    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels, disabledLegendArray]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

LineChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showBullets: PropTypes.bool,
  onBulletsClick: PropTypes.func,
  tooltipWidth: PropTypes.any,
  removeMinTooltipLabelWidth: PropTypes.bool,
  cardTitle: PropTypes.string,
  platform: PropTypes.string,
  flatLegends: PropTypes.bool,
  toolTipTitle: PropTypes.string,
  showTarget: PropTypes.bool,
  targetValue: PropTypes.any,
  impressionLabel: PropTypes.any,
  showImpression: PropTypes.bool,
  toolTipLabelSize: PropTypes.number,
  disabledLegendArray: PropTypes.array
};

export default LineChart;
