import { useLayoutEffect } from "react";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import Wrapper from "../../../helpers/Wrapper";
import { convertAmchartsColorToHexColor, tooltipNumberFormatting } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import { CountryCode } from "../../../../constants/countryCurrency";
import { BlueColorStart500 } from "../../../../constants/constants";
am5.addLicense("AM5C258896422");

const CategoryCombineBarLineChart = ({
    chartId,
    chartClass,
    chartData,
    chartLabels,
    columnWidth,
    axisColor
}) => {

    let axisTextColor = axisColor || 0x757575; // Use axisColor if available, else fallback to 0x757575
    let axisTextColorHex = convertAmchartsColorToHexColor(axisTextColor); // Convert to hex string once

    // USER DATA
    const userData = useSelector((state) => state.userData.user);

    let userCountryCode = userData?.country_code ?? "IN";

    let country = {};

    CountryCode.map((data) => {
        if (data.countryCode === userCountryCode) {
            country = data;
        }
    });

    useLayoutEffect(() => {
        // Create root element
        var root = am5.Root.new(chartId);

        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

        let colorSet = BlueColorStart500

        // Format numbers
        root.numberFormatter.setAll({
            numberFormat: "#.##a",
            bigNumberPrefixes: [
                { number: 1e3, suffix: "K" },
                { number: 1e6, suffix: "M" },
                { number: 1e9, suffix: "B" },
            ],
            smallNumberPrefixes: [],
        });

        // Create chart
        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false, // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
                panY: false, // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
                wheelX: "none", // zoomX / zoomY / zoomXY / panX / panY / panXY / none, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
                wheelY: "none", // zoomX / zoomY / zoomXY / panX / panY / panXY / none, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
                paddingLeft: 0,
                layout: root.verticalLayout
            })
        );

        chart.chartContainer.wheelable = false;

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);
        cursor.lineX.set("visible", false);

        var data = chartData;

        // Create axes
        var xRenderer = am5xy.AxisRendererX.new(root, {
            stroke: am5.color(axisTextColor),
            strokeWidth: 1,
            strokeOpacity: 0.2,
        });
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "label",
            maxDeviation: 0.1,
            startLocation: 0.2,
            endLocation: 0.8,
            renderer: xRenderer
        }));

        xAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate",
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: "#757575",
            fontWeight: "400"
        });

        xAxis.data.setAll(data);

        // Primary Y-Axis
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            // min: 0,
            extraMax: 0.03,
            maxDeviation: 0.1,
            extraTooltipPrecision: 1,
            strictMinMaxSelection: true,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.2,
                stroke: am5.color(axisTextColor),
                strokeWidth: 1,
            }),
            fill: am5.color(colorSet[0]),
        }));

        yAxis.get("renderer").grid.template.setAll({
            forceHidden: true
        });

        yAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate",
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: axisTextColorHex,
            fontWeight: "400",
        });

        // Secondary Y-Axis
        var yAxis1 = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            // min: 0,
            extraMax: 0.03,
            maxDeviation: 0.1,
            extraTooltipPrecision: 1,
            strictMinMaxSelection: true,
            syncWithAxis: yAxis,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.2,
                stroke: am5.color(0x707070),
                strokeWidth: 1,
                opposite: true, // Ensure it's placed on the right side
            }),
            fill: am5.color(colorSet[1]),
        }));

        yAxis1.get("renderer").grid.template.setAll({
            forceHidden: true
        });

        yAxis1.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate",
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: axisTextColorHex,
            fontWeight: "400",
        });

        // Add column series (Bar chart)
        var series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: chartLabels[0].barchartLabels[0].label, // "sales_volume"
                xAxis: xAxis,
                yAxis: yAxis, // Attach to secondary Y-Axis
                valueYField: chartLabels[0].barchartLabels[0].value, // "sales_volume"
                categoryXField: "label",
                // tooltip: am5.Tooltip.new(root, {
                //     pointerOrientation: "horizontal",
                //     labelText: "{name} in {categoryX}: {valueY}"
                // }),
                fill: am5.color(colorSet[0]),
                stroke: am5.color(colorSet[0])
            })
        );

        series1.columns.template.setAll({
            cornerRadiusTL: 7,
            cornerRadiusTR: 7,
            width: columnWidth ? columnWidth : 50,
        });

        series1.data.setAll(data);

        // Add line series (Line chart)
        var series2 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: chartLabels[0].linechartLabels[0].label, // "growth_rate"
                xAxis: xAxis,
                yAxis: yAxis1, // Attach to primary Y-Axis
                valueYField: chartLabels[0].linechartLabels[0].value, // "growth_rate_for_sales_volume"
                categoryXField: "label",
                // tooltip: am5.Tooltip.new(root, {
                //     pointerOrientation: "horizontal",
                //     labelText: "{name} in {categoryX}: {valueY} {info}"
                // }),
                fill: am5.color(colorSet[1]),
                stroke: am5.color(colorSet[1])
            })
        );

        series2.strokes.template.setAll({
            strokeWidth: 3,
            templateField: "strokeSettings"
        });

        series2.data.setAll(data);

        series2.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    strokeWidth: 3,
                    stroke: series2.get("stroke"),
                    radius: 5,
                    fill: root.interfaceColors.get("background")
                })
            });
        });

        var tooltip = series1.set(
            "tooltip",
            am5.Tooltip.new(root, {
                getFillFromSprite: false,
                getStrokeFromSprite: false,
                autoTextColor: false,
                pointerOrientation: "horizontal",
                position: "pointer",
            })
        );

        tooltip.get("background").setAll({
            fill: am5.color(0x213345),
            maxWidth: "auto",
            oversizedBehavior: "truncate",
            cornerRadius: 5,
        });

        tooltip.label.setAll({
            text: "[bold]{categoryX}[/]",
            fill: am5.color(0xffffff),
            fontSize: 13,
        });

        tooltip.label.adapters.add("text", function (text, target) {
            chart.series.each(function (series) {
                var tooltipDataItem = series?.get("tooltipDataItem");
                text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";
            });
            return text;
        });

        // Add legend
        var legend = chart.children.push(
            am5.Legend.new(root, {
                position: "relative",
                x: am5.percent(50),
                centerX: am5.percent(50),
                marginTop: 15,
                useDefaultMarker: true,
                layout: root.gridLayout,
            })
        );

        legend.markers.template.setAll({
            width: 13,
            height: 13,
        });

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
        });

        legend.labels.template.setAll({
            fontSize: 12,
            fontWeight: "400",
            fill: "#213345",
        });

        legend.labels.template.setAll({
            oversizedBehavior: "wrap",
        });

        legend.data.setAll(chart.series.values);

        // TO HIDE THE ZOOM BUTTON FROM THE
        chart.zoomOutButton.set("forceHidden", true);

        // Make stuff animate on load
        chart.appear(1000, 100);
        series1.appear();

        return () => {
            root.dispose();
        };

    }, [chartId, chartData, chartLabels]);

    return (
        <Wrapper>
            <div id={chartId} className={chartClass}></div>
        </Wrapper>
    )
};

export default CategoryCombineBarLineChart;
