import React from 'react';
import Wrapper from '../../helpers/Wrapper';
import LikeUnlikeButton from './likeUnlikeButton';

const FeedbackSection = () => {
  return (
    <Wrapper>
      <LikeUnlikeButton />
    </Wrapper>
  );
};

export default FeedbackSection;
