import { useLocation } from "react-router-dom";
import Wrapper from "../../components/helpers/Wrapper"
import InsightsChatWrapper from "../../components/insightsChat/insightsChatWrapper";

const AskIcogz = () => {

    const { state } = useLocation();
    return (
        <Wrapper>
            <div className="content">
                <div className="content_section">
                    <div className="page_content" style={{ "padding": 0 }}>
                        <div className="dashboard_home" style={{ display: "flex", justifyContent: "center", backgroundColor: "#fff" }}>
                            <InsightsChatWrapper state={state} />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default AskIcogz;