export default function SearchIcon({className}) {
  return (

        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.938 29.938" fill="none" stroke="#486f88">
            <g id="Group_111" data-name="Group 111" transform="translate(-772.644 -466.585)">
                <circle id="Ellipse_6" data-name="Ellipse 6" cx="12.501" cy="12.501" r="12.501" transform="translate(773.394 467.335)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Line_27" data-name="Line 27" x1="6.797" y1="6.797" transform="translate(794.724 488.665)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>

)
}
