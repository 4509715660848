import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Modal from "../../../../components/ui/Modal";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import Datatable from "../../../../components/ui/tables/Datatable";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { HALEON_COMPANY_ID } from "../../../../constants/constants";

import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import { DataFormatters } from "../../../../utils/DataFormatter";
import { getHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../../utils/Utils";

export default function CampaignRollworksOverall({ topCardData }) {

    // PAGE CONSTANTS
    let pageModule = "Marketing Intelligence"
    let pageSubModule = "Campaign"
    let pagePlatform = "Rollworks"
    let pagePrimaryTab = "Overall"

    const authCtx = useContext(AuthContext);
    const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

    // USER DATA
    const userData = useSelector((state) => state.userData.user[0]);
    let companyID = userData?.company?.id ?? "";

    const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
    const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

    // SELECTED BRANDS FROM DROPDOWN
    const globalSelectedBrandIds = useSelector(
        (state) => state.globalSelectedBrandIds.selectedBrandIds
    );

    let BRAND_ID = null;
    if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
        BRAND_ID = null;
    } else {
        BRAND_ID = globalSelectedBrandIds.join(",");
    }

    // GLOBAL CAMPAIGN DROPDOWN
    const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
    const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
    const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
    const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
    const selectedCampaignIdandPlatform = useSelector((state) => state.campaignModuleFilter.selectedCampaignIDandPlatform);

    // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
    let selectedPlatform = null;
    if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
        selectedPlatform = null;
    } else {
        selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
    }

    // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
    let selectedObjectives = null;
    if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
        selectedObjectives = null;
    } else {
        selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
    }

    // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
    let selectedCampaigns = null;
    if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
        selectedCampaigns = null;
    } else {
        selectedCampaigns = selectedCampaignsFromFilter.join(",");
    }

    let selectedIdandPlatform = null;
    if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
        selectedIdandPlatform = null;
    } else {
        selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
    }

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date);

    // METRIC CARDS
    const [metricCards, setMetricCards] = useState([]);
    const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
    const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
    const [metricCardserror, setMetricCardsError] = useState(null);

    // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
    const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
    const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
    const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
    const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
    const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);

    // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
    const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
    const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
    const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
    const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
    const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);

    // CAMPAIGN SUMMARY
    const [campaignSummary, setCampaignSummary] = useState([]);
    const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
    const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);

    const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
    const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
    const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

    const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})

    // CAMPAIGN SUMMARY DROPDOWN OPTIONS
    const campaignSummaryOptions = [
        { value: "campaign", label: "Campaign" },
        { value: "ad", label: "Ad" },
        { value: "ad_group", label: "Ad Group" },
    ];

    const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })

    // CAMPAIGN SUMMARY DROPDOWN DEFAULT STATE
    const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);

    // CAMPAIGN SUMMARY DROPDOWN OPTIONS SELECTION HANDLER
    const campaignSummaryDropdownSelectionHandler = (e) => {
        if (e === null) {
            setSelectedCampaignSummaryDropdownOption("");
        } else {
            setSelectedCampaignSummaryDropdownOption(e);
            setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
        }

    };


    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // METRIC CARDS

    useEffect(() => {

        // TO FETCH METRIC CARDS FROM API
        const fetchMetricCardsHandler = async () => {
            setMetricCardsIsLoading(true);
            setMetricCardsIsLoaded(false);
            setMetricCardsError(null);

            const config = {
                params: {
                    brand_id: BRAND_ID,
                    start_date: selectedDates.startDate,
                    end_date: selectedDates.endDate,
                    previous_start_date: selectedDates.prevStartDate,
                    previous_end_date: selectedDates.prevEndDate,
                    objective: selectedObjectives,
                    platform_campaign: selectedIdandPlatform,
                    platform: selectedPlatform,
                    campaign: selectedCampaigns,
                    campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authCtx.token,
                },
            };

            try {
                const res = await axios.get("performance/rollwork/metric-card/", config);

                const allData = res.data.data;

                let formattedData = DataFormatters("metricCards", allData)

                setMetricCards(formattedData?.data ?? []);
                setMetricCardsIsLoading(false);
                setMetricCardsIsLoaded(true);
                setMetricCardsError(null);
            } catch (error) {
                setMetricCards([]);
                setMetricCardsIsLoading(false);
                setMetricCardsIsLoaded(false);
                setMetricCardsError(error.code);
            }
        };
        fetchMetricCardsHandler()

    }, [BRAND_ID, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform])


    // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
    let metricCardsSection = (
        <Wrapper>
            <div className="grid grid_cols_4 grid_margin_bottom">
                <div className="grid col_span_4 section_header">
                    <div className="section_info">
                        <h2 className="section_card_title">Summary
                        </h2>
                    </div>
                </div>
                <div className="grid col_span_4 grid_margin_bottom">
                    <Loader loaderType="cardLoader" />
                </div>
            </div>
        </Wrapper>
    );

    // IF DATA IS LOADED
    if (isMetricCardsLoaded && !isMetricCardsLoading) {
        if (NoDataAvailableChecker(metricCards)) {
            metricCardsSection = (
                <Wrapper>
                    <div className="grid grid_cols_4 grid_margin_bottom">
                        <div className="grid col_span_4 section_header">
                            <div className="section_info">  <h2 className="section_card_title">Summary
                            </h2></div>
                        </div>
                        <div className="grid col_span_4 grid_margin_bottom">
                            <NoDataAvailableLoader chartType="cardChartType" />
                        </div>
                    </div>
                </Wrapper>
            );
        } else {
            metricCardsSection = (
                <Wrapper>
                    <ErrorBoundary chartType="cardChartType">
                        <Slider sliderTitle={"Summary"}>
                            {metricCards.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <Card
                                        module="campaign"
                                        platform="facebook"
                                        section="overall"
                                        cardTitle={data.label}
                                        currentValue={data.currentValue}
                                        previousValue={data.previousValue}
                                        rowOneTitle={"Previous"}
                                        numberVariant={"number"}
                                    />
                                </SwiperSlide>
                            ))}
                        </Slider>
                    </ErrorBoundary>
                </Wrapper>
            );
        }

    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (metricCardserror) {
        metricCardsSection = (
            <Wrapper>
                <div className="grid grid_cols_4 grid_margin_bottom">
                    <div className="grid col_span_4 section_header">
                        <div className="section_info">  <h2 className="section_card_title">Summary
                        </h2></div>
                    </div>
                    <div className="grid col_span_4 grid_margin_bottom">
                        <ServerErrorsLoader
                            chartType="cardChartType"
                            error={metricCardserror}
                        />
                    </div>
                </div>
            </Wrapper>
        );
    }

    // IF DATA LOADING
    if (isMetricCardsLoading) {
        metricCardsSection = (
            <Wrapper>
                <div className="grid grid_cols_4 grid_margin_bottom">
                    <div className="grid col_span_4 section_header">
                        <div className="section_info">  <h2 className="section_card_title">Summary
                        </h2></div>
                    </div>
                    <div className="grid col_span_4 grid_margin_bottom">
                        <Loader loaderType="cardLoader" />
                    </div>
                </div>
            </Wrapper>
        );
    }



    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

    useEffect(() => {
        const fetchChildDonutCampaignHandler = async () => {
            setChildDonutCampaignObjectiveLoading(true);
            setChildDonutCampaignObjectiveLoaded(false);
            setChildDonutCampaignObjectiveError(null);

            const config = {
                params: {
                    brand_id: BRAND_ID,
                    start_date: selectedDates.startDate,
                    end_date: selectedDates.endDate,
                    previous_start_date: selectedDates.prevStartDate,
                    previous_end_date: selectedDates.prevEndDate,
                    objective: selectedObjectives,
                    platform_campaign: selectedIdandPlatform,
                    platform: selectedPlatform,
                    campaign: selectedCampaigns,
                    campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authCtx.token,
                },
            };

            try {

                const res = await axios.get("performance/rollwork/campaign-distributions/", config);
                const allData = res.data.data.map(({ campaign_type, campaign_id }) => ({
                    label: campaign_type,
                    value: campaign_id
                }));

                let formattedData = allData;
                setChildDonutCampaignObjective(formattedData ?? []);

                setChildDonutCampaignObjectiveLoading(false);
                setChildDonutCampaignObjectiveLoaded(true);
                setChildDonutCampaignObjectiveError(null);
            } catch (error) {
                setChildDonutCampaignObjective([]);

                setChildDonutCampaignObjectiveLoading(false);
                setChildDonutCampaignObjectiveLoaded(false);
                setChildDonutCampaignObjectiveError(error.code);
            }
        };


        fetchChildDonutCampaignHandler();

    }, [BRAND_ID, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform]);



    let childDonutCampaignChart = (
        <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
    );

    // IF DATA IS LOADED
    if (childDonutCampaignObjectiveLoaded && !childDonutCampaignObjectiveLoading) {
        if (NoDataAvailableChecker(childDonutCampaignObjective)) {

            childDonutCampaignChart = <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>

        } else {
            childDonutCampaignChart = (
                <Wrapper>
                    <ErrorBoundary chartType="donutChartType">
                        <DonutPieChart
                            chartClass="section_card_chart"
                            chartData={childDonutCampaignObjective}
                            chartId="CampaignDonutChild"
                            onSliceClick={(data) => setChildDonutSliceToggle(data.label)}
                        />
                    </ErrorBoundary>
                </Wrapper>
            );
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (childDonutCampaignObjectiveError) {
        childDonutCampaignChart = (
            <Wrapper>
                <ServerErrorsLoader
                    chartType="donutChartType"
                    error={childDonutCampaignObjectiveError}
                />
            </Wrapper>
        );
    }

    // IF DATA LOADING
    if (childDonutCampaignObjectiveLoading) {
        childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
    }


    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
    const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

    let defaultLineChartCampaignDisabledvalues = [];

    if (showAllLegendsinMultiLineCluster) {
        defaultLineChartCampaignDisabledvalues = []
    } else {
        defaultLineChartCampaignDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
    }

    const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)


    useEffect(() => {

        const fetchLineChartCampaignHandler = async () => {
            setLineChartCampaignObjectiveLoading(true);
            setLineChartCampaignObjectiveLoaded(false);
            setLineChartCampaignObjectiveError(null);

            var objectiveConfig = (function () {
                if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
                    return { "objective": null }
                } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
                    return { "objective": selectedObjectives }
                } else {
                    return { "objective": childDonutSliceToggle }
                }
            })()

            const config = {
                params: {
                    brand_id: BRAND_ID,
                    start_date: selectedDates.startDate,
                    end_date: selectedDates.endDate,
                    previous_start_date: selectedDates.prevStartDate,
                    previous_end_date: selectedDates.prevEndDate,
                    ...objectiveConfig,
                    platform_campaign: selectedIdandPlatform,
                    platform: selectedPlatform,
                    campaign: selectedCampaigns,
                    campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authCtx.token,
                },
            };

            try {

                const res = await axios.get("performance/rollwork/campaign-type-daily-data/", config);

                const allData = res.data.data;
                const dataLabel = "data_date";
                const unwantedValues = ['data_date', 'objective']
                const dropdownArray = []

                let formattedData = DataFormatters("linechart", allData, dataLabel, dropdownArray, unwantedValues)

                setLineChartCampaignObjective(formattedData?.data ?? [])
                setLineChartCampaignObjectiveLabel(formattedData?.dimension ?? [])


                setLineChartCampaignObjectiveLoading(false);
                setLineChartCampaignObjectiveLoaded(true);
                setLineChartCampaignObjectiveError(null);
            } catch (error) {
                setLineChartCampaignObjective([]);
                setLineChartCampaignObjectiveLabel([]);
                setLineChartCampaignObjectiveLoading(false);
                setLineChartCampaignObjectiveLoaded(false);
                setLineChartCampaignObjectiveError(error.code);
            }
        };
        fetchLineChartCampaignHandler();

    }, [BRAND_ID, childDonutSliceToggle, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform])

    let lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    let lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    // IF DATA IS LOADED
    if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
        if (NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)) {

            lineChartCampaignSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
            lineChartCampaignSection2 = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>

        } else {

            if (isMultiLineCluster) {
                lineChartCampaignSection = (
                    <Wrapper>
                        <ErrorBoundary chartType="lineChartType">
                            <LineChart
                                chartClass="section_card_chart"
                                chartData={lineChartCampaignObjective}
                                chartId="multiLineChart"
                                chartLabels={lineChartCampaignObjectiveLabel}
                                onBulletsClick={function noRefCheck() { }}
                                showLabels
                                showLegend
                                impressionLabel="impression"
                                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
                                disabledLegendArray={lineChartCampaignDisabledvalues}
                                setDisabledValues={setLineChartCampaignDisabledvalues}
                            />

                        </ErrorBoundary>
                    </Wrapper>
                );

                lineChartCampaignSection2 = (
                    <Wrapper>
                        <ErrorBoundary chartType="multiAxisMultiLineChartType">
                            <PartitionedLineChart
                                chartClass="section_card_chart"
                                chartData={lineChartCampaignObjective}
                                chartId="multiLineChart_2"
                                chartLabels={lineChartCampaignObjectiveLabel}
                                showLegend={true}
                                disabledLegendArray={lineChartCampaignDisabledvalues}
                                setDisabledValues={setLineChartCampaignDisabledvalues}
                                showLabels={true}
                            />
                        </ErrorBoundary>
                    </Wrapper>
                );
            } else {
                lineChartCampaignSection = (
                    <Wrapper>
                        <ErrorBoundary chartType="lineChartType">
                            <LineChart
                                chartClass="section_card_chart"
                                chartData={lineChartCampaignObjective}
                                chartId="multiLineChart"
                                chartLabels={lineChartCampaignObjectiveLabel}
                                onBulletsClick={function noRefCheck() { }}
                                showLabels
                                showLegend
                                impressionLabel="impression"
                                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
                            />
                        </ErrorBoundary>
                    </Wrapper>
                );
            }
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (lineChartCampaignObjectiveError) {
        lineChartCampaignSection = (
            <Wrapper>
                <ServerErrorsLoader
                    chartType="lineChartType"
                    error={lineChartCampaignObjectiveError}
                />
            </Wrapper>
        );

        lineChartCampaignSection2 = (
            <Wrapper>
                <ServerErrorsLoader
                    chartType="lineChartType"
                    error={lineChartCampaignObjectiveError}
                />
            </Wrapper>
        );
    }

    // IF DATA LOADING
    if (lineChartCampaignObjectiveLoading) {
        lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
        lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    }


    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // CAMPAIGN SUMMARY

    // TO FETCH METRIC CARDS FROM API

    useEffect(() => {

        const fetchCampaignSummaryHandler = async () => {


            const config = {
                params: {
                    brand_id: BRAND_ID,
                    start_date: selectedDates.startDate,
                    end_date: selectedDates.endDate,
                    previous_start_date: selectedDates.prevStartDate,
                    previous_end_date: selectedDates.prevEndDate,
                    objective: selectedObjectives,
                    platform_campaign: selectedIdandPlatform,
                    platform: selectedPlatform,
                    campaign: selectedCampaigns,
                    dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
                    campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
                    ...campaignSummaryConfig,
                    page_size: -1,
                    page_num: 1,

                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authCtx.token,
                },
            };

            try {
                const res = await axios.get("performance/rollwork/summary-table/", config);

                setCampaignSummaryExportData(res?.data?.data?.results ?? []);

            } catch (error) {
                setCampaignSummaryExportData([]);

            }
        };
        fetchCampaignSummaryHandler();

    }, [BRAND_ID, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])

    useEffect(() => {

        const fetchCampaignSummaryHandler = async () => {
            setCampaignSummaryIsLoading(true);
            setCampaignSummaryIsLoaded(false);
            setCampaignSummaryError(null);

            const config = {
                params: {
                    brand_id: BRAND_ID,
                    start_date: selectedDates.startDate,
                    end_date: selectedDates.endDate,
                    previous_start_date: selectedDates.prevStartDate,
                    previous_end_date: selectedDates.prevEndDate,
                    objective: selectedObjectives,
                    platform_campaign: selectedIdandPlatform,
                    platform: selectedPlatform,
                    campaign: selectedCampaigns,
                    dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
                    campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
                    ...campaignSummaryConfig

                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authCtx.token,
                },
            };

            try {
                const res = await axios.get(
                    "performance/rollwork/summary-table/",
                    config
                );

                let allData = res.data.data.results;

                let uniqueHeaders = getUniqueValuesFromArrofObj(allData)

                let formattedHeaders = []

                uniqueHeaders.map((header) => {
                    formattedHeaders.push({
                        name: getHeaderKeysWithLabels(header),
                        field: header,
                    })
                })

                setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})

                setCampaignSummary(allData ?? []);
                setCampaignSummaryTableHeaders(formattedHeaders ?? [])
                setCampaignSummaryIsLoading(false);
                setCampaignSummaryIsLoaded(true);
                setCampaignSummaryError(null);
            } catch (error) {
                setCampaignSummary([]);
                setCampaignSummaryTableHeaders([])
                setCampaignSummaryIsLoading(false);
                setCampaignSummaryIsLoaded(false);
                setCampaignSummaryError(error.code);
            }
        };
        fetchCampaignSummaryHandler();

    }, [BRAND_ID, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig]);



    let campaignSummaryTable = (
        <Wrapper>
            <div className="grid grid_cols_4 fullpage_section_card section_card">
                <div className="grid col_span_4">
                    <div className="section_card_header">
                        <h2 className="section_card_title">Summary</h2>
                    </div>
                </div>
                <div className="grid col_span_4">
                    <div className="section_card_body">
                        <Loader loaderType="smallTableLoader" />
                    </div>
                </div>
            </div>
        </Wrapper>
    );


    // IF DATA IS LOADED
    if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {

        campaignSummaryTable = (
            <Wrapper>
                <ErrorBoundary chartType="tableChartType">
                    <Datatable
                        tableTitle="Summary"
                        tableHeader={campaignSummaryTableHeaders}
                        tableData={campaignSummary}
                        tableLength={campaignSummaryConfig?.page_size ?? 10}
                        isFooterShow={true}
                        searchPlaceHolder={"Search Campaign"}
                        tableConfigs={campaignSummaryConfig}
                        setTableConfigs={setCampaignSummaryConfig}
                        tablePaginationData={campaignSummaryTablePageData}
                        removeImgExtensions={true}
                        tableDropdown={
                            <Dropdown
                                ismulti={false}
                                placeholder={"Select"}
                                options={campaignSummaryOptions}
                                className="form_dropdown section_dropdown"
                                value={selectedCampaignSummaryDropdownOption}
                                selectedOptions={selectedCampaignSummaryDropdownOption}
                                setStatedropdown={campaignSummaryDropdownSelectionHandler}
                            />
                        }
                        tableID={"search_campaign_section"}
                        tableExportDropdown={
                            <ExportDropdown
                                sectionId={"search_campaign_section"}
                                moduleName={pageModule}
                                subModuleName={pageSubModule}
                                platform={pagePlatform}
                                tab={pagePrimaryTab}
                                sectionName={`TableDynaLabels_Summary Table _${": " + selectedCampaignSummaryDropdownOption.label}`}
                                sectionData={campaignSummaryExportData}
                            />
                        }
                    />
                </ErrorBoundary>
            </Wrapper>
        );

    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (campaignSummaryerror) {
        campaignSummaryTable = (
            <Wrapper>
                <div className="grid grid_cols_4 fullpage_section_card section_card">
                    <div className="grid col_span_4">
                        <div className="section_card_header">
                            <h2 className="section_card_title">Summary</h2>
                        </div>
                    </div>
                    <div className="grid col_span_4">
                        <div className="section_card_body">
                            <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }

    // IF DATA LOADING
    if (isCampaignSummaryLoading) {
        campaignSummaryTable =
            (
                <Wrapper>
                    <div className="grid grid_cols_4 fullpage_section_card section_card">
                        <div className="grid col_span_4">
                            <div className="section_card_header">
                                <h2 className="section_card_title">Summary
                                </h2>
                            </div>
                        </div>
                        <div className="grid col_span_4">
                            <div className="section_card_body">
                                <Loader loaderType="smallTableLoader" />
                            </div>
                        </div>
                    </div>
                </Wrapper>
            )
    }

    useEffect(() => {
        setChildDonutSliceToggle(null)
    }, [selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

    var campaignObjectCSV = {
        [`CampaignTypeDonut_Campaign Objective Donut`]: childDonutCampaignObjective,
        [`CampObjectiveDynaLabels_Campaign Objective Line_${childDonutSliceToggle ?? ''}`]: lineChartCampaignObjective,
    };



    useEffect(() => {
        var allData = {
            ...topCardData,
            "SummaryCard_Summary Card": metricCards,
            ...campaignObjectCSV,
            [`TableDynaLabels_Summary Table_${": " + selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
        };

        setFullPageExport(allData);
    }, [
        metricCards,
        childDonutCampaignObjective,
        lineChartCampaignObjective,
        campaignSummaryExportData,
    ]);



    useEffect(() => {
        if (
            (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
            (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
            (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
            (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
        ) {
            setIsExportDisabled(false)
        } else {
            setIsExportDisabled(true)
        }
    }, [
        metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
        childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
        lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
        campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
    ])



    return (
        <Wrapper>
            {metricCardsSection}
            <div
                id="campaign_objective_section"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
            >
                <div className="grid col_span_4">
                    <div className="section_card_header">
                        <div className="section_info">
                            <h2 className="section_card_title">Campaign Type Wise Distribution</h2>
                        </div>
                        <Modal
                            open={isLineChartCampaignOpen}
                            setOpen={setIsLineChartCampaignOpen}
                            exportDropdown={
                                <ExportDropdown
                                    sectionId={"multiLineChart_2"}
                                    sectionName={"Campaign Type Wise"}
                                    sectionData={campaignObjectCSV}
                                />}
                            parentId={"campaign_objective_section"}
                            title={`Campaign Type Wise Distribution`}
                            chart={lineChartCampaignSection2}
                        />
                        <div data-html2canvas-ignore={true}>
                            {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                                <ExpandIcon />
                            </button>}
                            <ExportDropdown
                                moduleName={pageModule}
                                subModuleName={pageSubModule}
                                platform={pagePlatform}
                                tab={pagePrimaryTab}
                                sectionId={"campaign_objective_section"}
                                sectionName={"Campaign Type Wise"}
                                sectionData={campaignObjectCSV}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid_cols_4">
                    <div className="grid col_span_1 ">
                        <div className="section_card_body">
                            <div className="section_card_chart_wrapper">
                                {childDonutCampaignChart}
                            </div>
                        </div>
                    </div>
                    <div className="grid col_span_3 ">
                        <div className="section_card_body">
                            <div className="section_card_chart_wrapper">
                                {lineChartCampaignSection}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {campaignSummaryTable}
        </Wrapper>
    )
}
