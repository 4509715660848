import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { convertAmchartsColorToHexColor } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const ScatterChart = ({
  chartId,
  chartClass,
  chartData,
  chartLabels,
  chartLinePlotValues,
  axisColor
}) => {

  let axisTextColor = axisColor || 0x757575; // Use axisColor if available, else fallback to 0x757575
  let axisTextColorHex = convertAmchartsColorToHexColor(axisTextColor); // Convert to hex string once

  useLayoutEffect(() => {

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let colorSet = [0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8, 0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8]

    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false, // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
      panY: false, // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
      wheelX: "none", // zoomX / zoomY / zoomXY / panX / panY / panXY / none, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
      wheelY: "none", // zoomX / zoomY / zoomXY / panX / panY / panXY / none, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
      pinchZoomX: false, // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
      pinchZoomY: false // true, for more info: https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/
    }));

    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 50,
      stroke: am5.color(axisTextColor),
      strokeWidth: 0.3,
      strokeOpacity: 1,
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
      autoZoom: false
    }));

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400",
    });

    xAxis.get("renderer").grid.template.setAll({
      forceHidden: true
    })

    xAxis.ghostLabel.set("forceHidden", true);

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      extraMax: 0.03,
      maxDeviation: 0.1,
      extraTooltipPrecision: 1,
      strictMinMaxSelection: true,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.2,
        stroke: am5.color(axisTextColor),
        strokeWidth: 1,
      }),
      autoZoom: false
    }));

    yAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: axisTextColorHex,
      fontWeight: "400",
    });

    yAxis.ghostLabel.set("forceHidden", true);

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series0 = chart.series.push(am5xy.LineSeries.new(root, {
      calculateAggregates: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: chartLabels[1].value,
      valueXField: chartLabels[0].value,
      fill: am5.color(colorSet[0]),
      stroke: am5.color(colorSet[0]),
      // tooltip: am5.Tooltip.new(root, {
      //   labelText: `${chartLabels[0].label}: {valueX}\n${chartLabels[1].label}:{valueY}`
      // })
    }));

    var tooltip = series0.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      oversizedBehavior: "wrap",
      cornerRadius: 5,
    });

    tooltip.label.setAll({
      text: `[bold]${chartLabels[0].label}[/] : [bold] {valueX}[/]\n[bold]${chartLabels[1].label}[/] : [bold] {valueY}[/]`,
      fill: am5.color(0xffffff),
      fontSize: 13
    });


    // Add bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    series0.bullets.push(function () {
      var graphics = am5.Triangle.new(root, {
        fill: series0.get("fill"),
        width: 15,
        height: 13
      });
      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });

    series0.strokes.template.set("strokeOpacity", 0);

    // trend series
    var trendSeries0 = chart.series.push(am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "y",
      valueXField: "x",
      stroke: am5.color(0xff9931)
    }));

    trendSeries0.data.setAll(chartLinePlotValues)

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      snapToSeries: [series0]
    }));

    let data = chartData;

    series0.data.setAll(data);


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series0.appear(1000);

    trendSeries0.appear(1000);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };

  }, [])

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

ScatterChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array
};

export default ScatterChart;
